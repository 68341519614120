import React from "react";
import {
  colors,
  ImageList,
  ImageListItem,
  ListItem,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

import { colors as farmColor } from "../../common/colors";
import styled from "styled-components";
import { T } from "react-translator-component";
import CommentIcon from "@mui/icons-material/Comment";
import { styles } from "../../pages/styles";
import Thumbnail from "../Phase2/Thumbnail";
import { faThumbsUp as thumbup } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CommentsModel from "../Models/CommentsModel";
import Separator from "../Separator";
import { styles as oldstyles } from "../../pages/styles";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import appServices from "../../services/app-services/appServices";
import VotesModel from "../Models/VotesModel";
import dayjs from "dayjs";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  height: 85%;
  overflow-y: hidden;
  z-index: 9999;
`;
interface Props {
  items: any[];
  buttonTitle?: string;
  onClickItem?(): void;
  page: string;
  order: string;
  status?: boolean;
  handleReport(post_id: number, status: any): void;
  onLike(post_id: number): void;
  onUnLike(post_id: number): void;
  likeLoading: boolean;
  showRanking?: boolean;
  showStatus?: boolean;
  Next?: any;
}
export default function EventDetailsList({
  items,
  onClickItem,
  showRanking = false,
  handleReport,
  likeLoading,
  onLike,
  onUnLike,
  showStatus = false,
  Next,
}: Props) {
  const [showCommentsModal, setShowCommentsModal] = React.useState(0);
  const [showVotesModal, setShowVotesModal] = React.useState(0);

  const navigate = useNavigate();

  const [reportConfirmation, setReportConfirmation] = React.useState(0);
  // const groupInfo = useSelector((state: any) => state.gcGroup);
  const { user } = useSelector((state: any) => state.userAuth);
  // console.log(user);

  const [thumbsUp, setThumbsUp] = React.useState(false);

  const like = async (item: any) => {
    // setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, "like");
      // Optimistically update the UI here
      item.liked_by_me = true;
    } catch (error) {
      console.error("Error while liking:", error);

      // setLikeLoading(false);
    }
    // console.log('like')
  };

  const unlike = async (item: any) => {
    // setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, "delete_like");
      // Optimistically update the UI here
      item.liked_by_me = false;
    } catch (error) {
      // setLikeLoading(false);
    }
    // console.log("unlike");
  };

  const handleThumbUp = async (item: any) => {
    // setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        unlike(item);
      } else {
        like(item);
        // setLikeCount(likeCount + 1); // Increase the like count
      }
      setThumbsUp(!thumbsUp);
      // Optimistic UI update for liked_by_me
      item.liked_by_me = !item.liked_by_me;
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      // setLikeLoading(false);
    }
  };

  const getPaymentDetails = async (item: any, matchedUser: any) => {
    try {
      const res = await appServices.getEventPaymentDetails(
        item?.post_events[0]?.id,
        user?.id
      );
      // Function to find object by ID
      const matched = res.find((item: any) => item?.id === matchedUser?.id);
      navigate("/usereventpaymentdetailscreen", {
        state: {
          role: "member",
          userPaymentDetail: matched,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const formatEventDate = (start_date: any, end_date: any) => {
    const startDate = dayjs(start_date);
    const endDate = dayjs(end_date);

    if (startDate.isSame(endDate, "day")) {
      // If start and end dates are the same
      return startDate.format("YYYY年M月D日(ddd)");
    } else {
      // If start and end dates are different
      return `${startDate.format("YYYY年M月D日(ddd)")}~${endDate.format(
        "M月D日(ddd)"
      )}`;
    }
  };
  const renderTextWithLinks = (text: string) => {
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    const replacedText = text?.replace(urlRegex, (url) => {
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return url;
      }
    });

    return replacedText?.replace(/\n/g, "<br>");
  };
  const getTruncatedDescription = (text: string, limit: number) => {
    let truncated = text?.slice(0, limit);
    let lastOpen = truncated?.lastIndexOf("<a ");
    let lastClose = truncated?.lastIndexOf("</a>");

    if (lastOpen > lastClose) {
      truncated = truncated?.slice(0, lastOpen) + "...";
    } else if (truncated?.length < text?.length) {
      truncated += "...";
    }

    return renderTextWithLinks(truncated);
  };

  return (
    <div
      style={{
        borderBottomWidth: 4,
        overflow: "hidden",
        display: "block",
      }}
    >
      <ImageList style={{ display: "block" }} cols={1}>
        {items?.map((item, index) => {
          // console.log(item);
          // const matchedUser = item?.post_events?.[0]?.users?.find(
          //   (userItem: any) =>
          //     userItem?.user_id === user?.id && userItem?.status === "applied"
          // );
          const matchedUser = item?.post_events?.[0]?.users?.find(
            (userItem: any) =>
              userItem?.user_id === user?.id &&
              (userItem?.status === "applied" ||
                userItem?.status === "Cancel-pending")
          );
          return (
            <ImageListItem
              sx={{
                backgroundColor: farmColor.farm_gray10,
                borderRadius: 2,
              }}
              key={index}
            >
              <ListItem
                className=""
                alignItems="flex-start"
                style={{
                  ...styles.detailListItem,
                  borderBottomStyle: "solid",
                  borderTopStyle: "solid",
                  paddingLeft: 0,
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Box style={style.eventPostingBox}>
                  <Typography style={{ fontWeight: "700" }}>
                    <img
                      src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${item?.member_group?.group_icon}`}
                      alt={"user"}
                      style={{
                        ...oldstyles.farmProfileIcon,
                        height: 50,
                        width: 50,
                        margin: 0,
                      }}
                    />
                    &nbsp;{item?.member_group?.name}
                  </Typography>
                </Box>

                <Dialog
                  open={reportConfirmation === item.id}
                  onClose={() => setReportConfirmation(0)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {T("Report")} {/* //are you sure? */}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      この投稿を本当に報告しますか?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color={"secondary"}
                      onClick={() => setReportConfirmation(0)}
                    >
                      {T("No")}
                    </Button>
                    <Button
                      sx={{ fontFamily: "mplus-1c-bold" }}
                      color={"secondary"}
                      onClick={() => {
                        handleReport(item?.id, "report");
                      }}
                      autoFocus
                    >
                      {T("Yes")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </ListItem>
              <div>
                <Thumbnail deleteItem={() => {}} item={item} />
              </div>
              <div style={{ justifyContent: "center" }}>
                {likeLoading ? (
                  <CircularProgress
                    style={{
                      marginTop: 10,
                      marginBottom: -8,
                      marginLeft: 8,
                      marginRight: 8,
                    }}
                    size={24}
                  />
                ) : (
                  <IconButton
                    onClick={() => handleThumbUp(item)}
                    aria-label="thumbs"
                  >
                    <FontAwesomeIcon
                      size={"lg"}
                      icon={item?.liked_by_me ? thumbup : faThumbsUp}
                      color={
                        item?.liked_by_me
                          ? farmColor.farm_green500
                          : farmColor.farm_gray200
                      }
                    />
                  </IconButton>
                )}
                <span style={{ paddingTop: 6, fontSize: 16 }}>
                  いいね！{item?.likes_count}件
                </span>
              </div>
              <Typography
                style={{
                  marginLeft: 6,
                  marginRight: 6,
                  fontSize: 20,
                  fontWeight: "600",
                }}
              >
                {item.title}
              </Typography>
              {item.is_private && (
                <Box
                  onClick={() =>
                    navigate("/viewpostuser", { state: { item: item } })
                  }
                  sx={{ display: "flex", margin: "0px 5px" }}
                >
                  <Typography
                    sx={{ fontSize: 12, marginRight: "10px" }}
                  >{`この投稿を見られるユーザー：${
                    item.post_private_users.length - 1
                  }名`}</Typography>
                  <Typography
                    sx={{ fontSize: 12, color: farmColor.farm_gray200 }}
                  >
                    ユーザーを見る
                  </Typography>
                </Box>
              )}
              <Typography
                sx={{ color: "#A2A2A2", fontSize: 12, marginLeft: 1 }}
              >
                イベント開催日：
                {formatEventDate(
                  item?.post_events[0]?.start_date,
                  item?.post_events[0]?.end_date
                )}
              </Typography>

              {/* <Typography>{item?.post_events[0]?.description}</Typography> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: getTruncatedDescription(
                    item?.post_events[0]?.description,
                    80
                  ),
                }}
                onClick={(event) => {
                  const target = event.target as HTMLElement;
                  if (target.tagName === "A") {
                    event.preventDefault();
                    const url = (target as HTMLAnchorElement).href;
                    // console.log("Link clicked:", url);
                    // You can add additional logic here, such as opening the URL in a new tab/window
                    //window.open(url, "_system");
                    try {
                      const messageData = {
                        url: url,
                        condition: true,
                      };
                      window.parent.postMessage(
                        { type: "url", data: messageData },
                        "*"
                      );
                    } catch (error) {
                      console.error(error);
                    }
                  }
                }}
              />
              <span
                style={{
                  fontSize: 12,
                  color: farmColor.farm_gray200,
                  cursor: "pointer",
                  display: "block",
                  marginTop: 8,
                }}
                onClick={() =>
                  navigate("/eventpostdetails", {
                    state: {
                      item: item,
                    },
                  })
                }
              >
                続きを読む
              </span>
              <Separator color={colors.grey[400]} sx={{ marginTop: 2 }} />
              {matchedUser && (
                <>
                  <Box sx={{ padding: "15px 20px" }}>
                    <Box sx={style?.detailsBox}>
                      {matchedUser?.status === "applied" ? (
                        <Typography>申し込み済みです</Typography>
                      ) : (
                        <Typography>
                          キャンセル・参加費の返金をリクエスト済みです。参加費の返金をもって、キャンセルが完了になります。
                        </Typography>
                      )}
                      <Button
                        color="secondary"
                        sx={style.detailButton}
                        size="large"
                        variant="outlined"
                        onClick={() => getPaymentDetails(item, matchedUser)}
                        endIcon={
                          <ArrowForwardIosIcon
                            sx={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              margin: "10px",
                            }}
                          />
                        }
                      >
                        詳細を見る
                      </Button>
                    </Box>
                  </Box>
                  <Separator color={colors.grey[400]} sx={{ marginTop: 2 }} />
                </>
              )}

              <Stack direction="row" spacing={2}>
                {item?.enable_comments && (
                  <Box>
                    <IconButton
                      onClick={() => setShowCommentsModal(item.id)}
                      aria-label="thumbs"
                    >
                      <CommentIcon />
                      <Typography>
                        コメント{item.comments_count} 件を読む
                      </Typography>
                    </IconButton>
                  </Box>
                )}

                {item?.enable_participants && (
                  <Box>
                    <IconButton
                      onClick={() => setShowVotesModal(item.id)}
                      aria-label="thumbs"
                    >
                      <HowToVoteIcon />
                      <Typography>投票する</Typography>
                    </IconButton>
                  </Box>
                )}
              </Stack>

              {showCommentsModal === item.id && (
                <ModalBackground onClick={() => setShowCommentsModal(0)}>
                  <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                    <CommentsModel
                      comments={item.comments}
                      post_id={item.id}
                      closeModal={() => setShowCommentsModal(0)}
                    />
                  </ModalContainer>
                </ModalBackground>
              )}
              {showVotesModal === item.id && (
                <ModalBackground onClick={() => setShowVotesModal(0)}>
                  <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                    <VotesModel
                      post={item}
                      closeModal={() => setShowVotesModal(0)}
                    />
                  </ModalContainer>
                </ModalBackground>
              )}
            </ImageListItem>
          );
        })}
      </ImageList>
    </div>
  );
}

const style = {
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "left",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  detailButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  detailsBox: {
    border: "1px solid rgb(175, 201, 153)",
    background: "rgb(231, 240, 232)",
    display: "flex",
    borderRadius: 3,
    alignItems: "center",
    gap: "15px",
    flexDirection: "column",
    padding: "20px 10px",
  },
};
