import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Badge, useMediaQuery, useTheme } from "@mui/material";
import { T } from "react-translator-component";
import appServices from "../services/app-services/appServices";
import LoadingModal from "./LoadingModal";
import { useSelector } from "react-redux";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";

const SelectedListItem = ({ handleListItemClick }: any) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const { admin } = useSelector((state: any) => state.adminAuth);
  const handleClick = (itemName: any, index: number) => {
    setSelectedIndex(index);
    handleListItemClick(index);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const g = async () => {
    try {
      setLoader(true);
      const posts = await appServices.getPendingPosts(1);
      setLoader(false);
      setData(posts.posts);
    } catch (error) {
      setLoader(false);
      setAuthorizationToken(admin?.auth_token);
    }
  };

  React.useEffect(() => {
    g();
    // eslint-disable-next-line
  }, []);

  return (
    <List component="nav">
      {admin?.is_facilitator !== 1 && (
        <>
          <ListItemButton
            className="list-item"
            selected={selectedIndex === 0}
            onClick={(event) => handleClick(event, 0)}
          >
            <ListItemIcon>
              <DashboardIcon
                sx={{ color: "white", marginLeft: isMobile ? 0 : 1 }}
              />
            </ListItemIcon>
            <ListItemText primary={T("Dashboard")} sx={{ color: "white" }} />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleClick(event, 1)}
          >
            <ListItemIcon>
              <PersonAddIcon
                sx={{ color: "white", marginLeft: isMobile ? 0 : 1 }}
              />
            </ListItemIcon>
            <ListItemText primary={T("Users")} sx={{ color: "white" }} />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleClick(event, 2)}
          >
            <ListItemIcon>
              <WorkspacesIcon
                sx={{ color: "white", marginLeft: isMobile ? 0 : 1 }}
              />
            </ListItemIcon>
            <ListItemText primary={T("Groups")} sx={{ color: "white" }} />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => handleClick(event, 3)}
          >
            <ListItemIcon>
              <LoadingModal
                visible={loader}
                handleClose={() => {}}
              />
              <Badge badgeContent={data.length} color="error">
                <PendingActionsIcon
                  sx={{ color: "white", marginLeft: isMobile ? 0 : 1 }}
                />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={T("PendingPosts")} sx={{ color: "white" }} />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 5}
            onClick={(event) => handleClick(event, 5)}
          >
            <ListItemIcon>
              <MarkUnreadChatAltIcon
                sx={{ color: "white", marginLeft: isMobile ? 0 : 1 }}
              />
            </ListItemIcon>
            <ListItemText primary={T("Comments")} sx={{ color: "white" }} />
          </ListItemButton>
        </>
      )}
      <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event) => handleClick(event, 4)}
      >
        <ListItemIcon>
          <b
            style={{
              color: "white",
              fontSize: 12,
              borderRadius: 15,
              borderStyle: "solid",
              height: 28,
              paddingTop: 4,
              padding: 2,
              justifyContent: "center",
              alignContent: "center",
              marginLeft: isMobile ? 0 : 1,
            }}
          >
            {T("Pool")}
          </b>
        </ListItemIcon>
        <ListItemText primary={T("Pool")} sx={{ color: "white" }} />
      </ListItemButton>
    </List>
  );
};

export default SelectedListItem;
