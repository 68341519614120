import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { colors } from "../../common/colors";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import LoadingModal from "../LoadingModal";
import IconButtonArrow from "./IconButtonArrow";
import { getRole } from "../../util/utils";

interface Props {
  onNext: (group: any) => void;
}

function SeclectGroup({ onNext }: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state: any) => state.userAuth);

  const [groups, setGroups] = useState<any>([]);
  const [selectedGroup, setSelectedGroup] = useState<any>();

  useEffect(() => {
    apiCalls();
    // eslint-disable-next-line
  }, []);
  const apiCalls = async () => {
    setIsLoading(true);
    try {
      const mygroup = `?q[member_group_users_status_in][]=approved&q[member_group_users_user_id_eq]=${user.id}&per_page=400`;
      const mygroups = await gcServices.getMembersGroups(mygroup);
      const leader = `?per_page=5&q[user_id_eq]=${user.id}`;
      const leadersGroup = await gcServices.getMembersGroups(leader);
      const combinedGroups = [...(mygroups?.member_groups || []), ...(leadersGroup?.member_groups || [])];

      setGroups(combinedGroups);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    onNext(selectedGroup);
  };

  const handleGroup = (group: any) => {
    // console.log(group);
    setSelectedGroup(group);
  };

  return (
    <div style={{ height: 300, width: "100%" }}>
      <Box display="flex" flexDirection="column" sx={{ height: 270, width: "100%", overflow: "scroll" }}>
        {groups?.map((group: any, index: number) => (
          <IconButtonArrow
            borderColor={group.id === selectedGroup?.id ? colors.farm_green150 : colors.farm_gray100}
            key={index} // Use a unique key in a real app
            icon={<img alt="" height={26} width={26} style={{ borderRadius: 13 }} src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${group?.group_icon}`} />}
            title={group?.name || ""} // Title from the group
            role={group?.owner?.id === user?.id ? getRole("leader") : group?.role === null ? getRole("member") : getRole(group?.role)} // Title from the group
            onClick={() => {
              handleGroup(group);
            }}
          />
        ))}
      </Box>

      <Button
        // disabled={!agree}
        color="secondary"
        sx={styles.submitButton}
        size={"large"}
        variant="outlined"
        onClick={handleSubmit}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
      >
        つぎへ
      </Button>
      <LoadingModal visible={isLoading} />
    </div>
  );
}
const styles = {
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
    marginBottom: 8,
  },
};

export default SeclectGroup;
