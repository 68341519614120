import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import ButtonPrimary from "../../ButtonPrimary";
import TextButton from "../../../components/GreenCity/TextButton";

// Define the props interface
interface MessageModalProps {
  open: boolean;
  yesButton?:string;
  noButton?:string;
  showButton?: boolean;
  message: any;
  onClose: () => void;
  onContinue: () => void;
}

const ConfirmationModal: React.FC<MessageModalProps> = ({ open, onClose, onContinue,yesButton,noButton, showButton, message }) => {
  // useEffect(() => {
  //   let timer: NodeJS.Timeout;

  //   if (open && !showButton) {
  //     // Close the modal after 3 seconds if showButton is false
  //     timer = setTimeout(() => {
  //       onClose();
  //     }, 3000);
  //   }

  //   return () => clearTimeout(timer); // Clear the timer if the component unmounts
  // }, [open, showButton, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message}
        </Typography>
        <br />
        <ButtonPrimary title={yesButton?yesButton:"同意して進む"} onClick={onContinue} />
        <TextButton title={noButton?noButton:"キャンセル"} onClick={onClose} Icon={null} />
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
