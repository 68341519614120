import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../common/colors";
import Separator from "../../components/Separator";
import DropDown from "../../components/DropDown";
import ButtonOutlinedCentered from "../../components/ButtonOutlined";
import { T } from "react-translator-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import appServices from "../../services/app-services/appServices";
import { defaultPostImages, isIOS, makeArrayUnique, responsive } from "../../util/utils";
import Carousel from "react-multi-carousel";
import { AsyncImage } from "loadable-image";
import { ImageItem } from "../CreatePost";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import uploadFiles from "../../util/upload-multiple-file";
import { TagsInput } from "react-tag-input-component";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoadingModal from "../../components/LoadingModal";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
// import SelectedUser from "../../components/items/SelectedUser";

const MAX_LENGTH = 2500;

export default function CreateGroupPost(): ReactElement {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const editPost = useLocation().state?.post;
  const post_type = useLocation().state?.post_type;
  const preUsers = useLocation().state?.preUsers;
  const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [acceptVotes, setAcceptVotes] = useState("No");
  const [isPrivate, setIsPrivate] = useState<any>(editPost ? editPost.is_private : post_type === "public" ? 0 : 1);
  const [voteOptions, setVoteOptions] = useState<{ inputText: string; id?: number; post_id?: number }[]>([]);
  // eslint-disable-next-line
  const [votesCount, setVoteCounts] = useState(0);
  const [postEndDate, setPostEndDate] = useState<Dayjs>(dayjs(moment().add(20, "days").calendar()));
  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({
    value: "",
    name: "Category",
  });
  const [groups, setGroups] = useState<any>([]);
  const [selectedGroup, setSelectedGroup] = useState<any>({
    value: "",
    name: "Group",
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editTags, setEditTags] = useState<any[]>([]);
  const [tagArray, setTagArray] = useState<string[]>([]);
  const [removeTags, setRemoveTags] = useState<any[]>([]);
  const [finalEditTags, setFinalEditTags] = useState<any>([]);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<ImageItem[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<ImageItem[]>([]);
  const [removedImages, setRemovedImage] = useState<ImageItem[]>([]);
  const [acceptComments, setAcceptComments] = useState("No");
  const [agree, setAgree] = useState(false);
  const [members, setMembers] = useState<any>([]);
  const [selectedMembrs, setSelectedMembers] = useState<{ user_id: number; id?: any; nickname: string }[]>(preUsers || []);
  const [showMembers, setShowMembers] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [voteQuestion, setVoteQuestion] = useState("");

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    category: "",
    group: "",
    tags: "",
    images: "",
    acceptVotes: "",
    acceptComments: "",
    agree: "",
    date: "",
    voteQuestion: "",
  });

  const validateForm2 = () => {
    let valid = true;
    let newErrors = {
      title: "",
      description: "",
      category: "",
      group: "",
      tags: "",
      images: "",
      acceptVotes: "",
      acceptComments: "",
      agree: "",
      date: "",
      voteQuestion: "",
    };
    if (title === "") {
      newErrors.title = "タイトルを入力してください";
      valid = false;
    }
    if (description === "") {
      newErrors.description = "内容を入力してください";
      valid = false;
    }
    if (selectedCategory === undefined) {
      newErrors.category = "カテゴリーを選択してください";
      valid = false;
    }
    if (selectedGroup === undefined) {
      newErrors.group = "グループを選択してください";
      valid = false;
    }

    if (acceptVotes === "") {
      newErrors.acceptVotes = "投票を選択してください";
      valid = false;
    }
    if (acceptVotes === "Yes" && voteOptions.some((option) => !option.inputText || option.inputText.trim() === "")) {
      newErrors.acceptVotes = "投票内容を入力してください";
      valid = false;
    }
    if (acceptVotes === "Yes" && postEndDate === undefined) {
      newErrors.date = "終了日を入力してください";
      valid = false;
    }
    if (acceptVotes === "Yes" && voteQuestion === "") {
      newErrors.voteQuestion = "投票質問を入力してください";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const handleAcceptVotes = (value: any) => {
    setAcceptVotes(value.target.value);
    setVoteOptions([
      {
        inputText: "",
      },
      {
        inputText: "",
      },
    ]);
  };

  // Handle vote input values
  const handleVoteValue = (value: string, index: number) => {
    setVoteOptions((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], inputText: value };
      return updated;
    });
    setErrors((prev) => ({ ...prev, acceptVotes: "" }));
  };

  const handleDate = (newValue: any) => {
    setPostEndDate(newValue || undefined);
    setTimeout(() => {
      if (postEndDate !== undefined) {
        setErrors((prev) => ({ ...prev, date: "" }));
      }
    }, 100);
  };

  const removeVoteOption = (index: number) => {
    setVoteOptions((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
  };

  const handleAcceptComments = (value: any) => {
    setAcceptComments(value.target.value);
  };

  const handleImageUploadAndroid = () => {
    try {
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };

  const handleImageUploadIOS = (event: any) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const totalFiles = images.length + selectedFiles.length;

      if (totalFiles > 5) {
        // setError("画像は5枚まで許可されます");
        // Only take the required number of files to stay within the limit
        selectedFiles.splice(5 - images.length);
      }

      const newImages = selectedFiles.map((file: any) => {
        let reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e: any) => {
            resolve({
              name: file.name,
              file: file,
              imageSrc: e.target.result,
              path: undefined,
              _destroy: "0",
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((loadedImages) => {
        setImages((prevImages: any) => [...prevImages, ...loadedImages]);
        // setError(null); // Clear any previous error
      });
    }
  };

  const removeImage = (index: number) => {
    setImages((prev: ImageItem[]) => prev.filter((_: ImageItem, i: number) => i !== index));
  };

  const saveRemoveImg = (img: ImageItem) => {
    if (img.path !== undefined && img._destroy !== "1") {
      const item = img;
      const addDestroy = [...removedImages, { ...item, _destroy: "1" }];
      setRemovedImage(addDestroy);
    }
  };

  const handleDeleteTag = (tag: string) => {
    if (editPost) {
      try {
        const post = editPost;
        const removedTg = post.post_tags.filter((item: any) => item.tag === tag);
        const removed = [...removeTags, { tag: tag, _destroy: "1", id: removedTg[0].id }];
        setRemoveTags(removed);
      } catch (error) {}
    }
  };

  useEffect(() => {
    const select = tagArray.map((tag) => {
      return { tag: tag };
    });
    const all = select.concat(editTags);
    // var ages = array.map(function(obj) { return obj.age; });
    const uniqueArray = makeArrayUnique(all);

    const f = uniqueArray.concat(removeTags);
    const fin = makeArrayUnique(f);
    setFinalEditTags(fin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagArray, removeTags]);

  useEffect(() => {
    if (editPost) {
      // eslint-disable-next-lin
      const { title, details, enable_comments, enable_participants, group, category, expiry_date, post_tags, vote_options, images, is_private, post_private_users, vote_question } =
        editPost;
      setTitle(title);
      setDescription(details);
      setAcceptComments(enable_comments ? "Yes" : "No");
      setAcceptVotes(enable_participants ? "Yes" : "No"); //in this field i have set enable votes
      setSelectedGroup({ value: group?.id, name: group?.name });
      setSelectedCategory({ value: category.id, name: category.name });
      setPostEndDate(dayjs(expiry_date));
      setVoteQuestion(vote_question || "");
      if (is_private) {
        setIsPrivate(1);
        setSelectedMembers(post_private_users);
      }
      const tags = post_tags.map((tag: any) => tag.tag);
      setTagArray(tags);
      setEditTags(post_tags);

      const votes = vote_options.map((vote: any) => ({
        id: vote.id,
        post_id: vote.post_id,
        inputText: vote.option_text,
      }));

      const imgs = images.map((img: any) => ({
        path: img.path,
        id: img.id,
        name: img.path,
        file: undefined,
        imageSrc: undefined,
      }));
      setImages(imgs);

      if (votes.length > 0) {
        setVoteOptions(votes);
      }
      const totalVotes = vote_options.reduce((total: number, vote: any) => total + vote.votes_count, 0);
      setVoteCounts(totalVotes);
    }
    // eslint-disable-next-line
  }, [editPost]);

  const getPostMembers = () => {
    if (post_type === "public") {
      return null;
    } else if (post_type === "private") {
      let users = members.map((member: any) => ({
        user_id: member.user.id,
      }));
      // if (groupInfo?.owner?.id === user.id) {
      users.push({ user_id: user.id });
      // }
      return [...users];
    } else if (post_type === "private_users") {
      return [...selectedMembrs, { user_id: user.id }];
    } else if (editPost?.is_private) {
      return [...selectedMembrs];
    }
  };

  function isMemberAdded(id: number) {
    const member = selectedMembrs.find((member: any) => member.user_id === id);
    if (member) {
      return true;
    }
    return false;
  }

  function handleAddMember(user: any) {
    const newMembers = [...selectedMembrs, { user_id: user.id, name: user.name, nickname: user.nickname, image: user.image }];
    setSelectedMembers(newMembers);
  }

  function handleRemoveMember(id: number) {
    const newMembers = selectedMembrs.filter((member: any) => member.user_id !== id);
    setSelectedMembers(newMembers);
  }

  const handleSubmit = async () => {
    if (!validateForm2()) {
      return;
    } else {
      setIsLoading(true);
      const allImages = filesToUpload ? await uploadFiles.uploadMultipleImages(filesToUpload) : [];

      const votesWithTextOnly = voteOptions.filter((item: any) => item.value?.trim() !== "");
      const votes = votesWithTextOnly.map((item: any) => ({
        option_text: item.inputText,
        ...item,
      }));
      let body = {
        post: {
          id: editPost?.id,
          is_private: isPrivate,
          is_private_group_user: editPost?.is_private_group_user || post_type === "private_users",
          post_private_users_attributes: getPostMembers(),
          member_group_id: groupInfo.id,
          title,
          vote_question: voteQuestion,
          details: description,
          group_id: selectedGroup?.value,
          category_id: selectedCategory?.value,
          moderation_count: 0,
          expiry_date: moment(postEndDate.toISOString()).add(1, "days"),
          enable_comments: acceptComments === "Yes",
          enable_participants: acceptVotes === "Yes", //in this field i have set votes yes no.
          number_of_participants: null,
          images_attributes: [...allImages, ...removedImages, ...filesUploaded],
          tags_attributes: finalEditTags,
          vote_options_attributes: votes,
          post_type: user?.source === "admin" ? "1" : "0",
        },
      };
      if (editPost) {
        appServices.editPost(editPost.id, body).then((res) => {
          setLoading(false);
          if (res.data?.success) {
            navigate(-1);
          }
        });
      } else {
        appServices
          .savePost(body)
          .then((res) => {
            if (res.data?.success) {
              navigate(-1);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const getTitle = () => {
    let title = "";
    if (post_type === "public") {
      title = "みんなの投稿";
    } else if (post_type === "private") {
      title = "グループ内の投稿";
    } else if (post_type === "private_users") {
      title = "グループ内のプライベート投稿";
    }

    if (editPost) {
      title = "編集 " + title;
    }
    return title;
  };

  const isImageAdded = (image: { id: undefined; path: string }) => {
    const imageAlreadyExists = images.find((img) => img.path === image.path);
    if (imageAlreadyExists) {
      return true;
    }
    return false;
  };

  const handleDefaultImage = (image: { id: undefined; path: string }) => {
    if (images.length >= 5) return;
    let newImage = { id: image.id, imageSrc: "", name: image.path.split("/").pop(), file: undefined, path: image.path, _destroy: "0" };
    // let newImages = images.filter((img) => img.id !== newImage.id);
    let newImages = [...images];
    const imageAlreadyExists = newImages.find((img) => img.path === newImage.path);
    if (!imageAlreadyExists) {
      newImages = [...newImages, newImage];
    }
    setImages(newImages);
  };

  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (event?.data === "" || event?.data === null || event?.data === undefined) return;
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });

          setImages((prevImages: any) => [
            ...prevImages,
            {
              name: fileName,
              file: file,
              imageSrc: event?.data,
              path: undefined,
              _destroy: "0",
            },
          ]);
        } catch (error) {
          // alert("error" + error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    appServices.getCategory().then((res) => {
      setCategories(res);
      // setSelectedCategory(res[0]);
    });
    appServices.getGroups(1).then((res) => {
      setGroups(res);
      // setSelectedGroup(res[0]);
    });

    if (post_type !== "public") {
      gcServices.getMemberList(groupInfo.id).then((res) => {
        let mem = res?.member_group_users.filter((member: any) => member.user.id !== user.id);
        // console.log("post members: ", res?.member_group_users, mem);
        if (groupInfo?.owner?.id !== user.id) {
          mem.unshift({ user: groupInfo.owner, role: "leader" });
        }
        setMembers(mem);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filesToUpload = images.filter((img) => img.file !== undefined);
    setFilesToUpload(filesToUpload);

    const filesUploaded = images.filter((img) => img.path !== undefined);
    setFilesUploaded(filesUploaded);
  }, [images]);

  return (
    <div style={styles.container}>
      {/* Back Button */}
      <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} handleClose={() => {}} />
      {/* Title and Header Section */}
      <div style={styles.rowWithHorizontalPadding}>
        <img alt="" height={26} width={26} style={{ borderRadius: 13, marginRight: 10 }} src={groupInfo?.groupImage} />
        <Typography style={styles.everyonePostStyle}>{getTitle()}</Typography>
      </div>

      {/* Separator */}
      <Separator sx={{ marginTop: "10px" }} color="#D3D3D3" />

      <div style={{ backgroundColor: colors.farm_gray15, padding: "15px 10px" }}>
        {(editPost?.is_private_group_user || post_type === "private_users") && (
          <Box>
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 1024 },
                  items: 5, // Number of items to show on large screens
                },
                desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
                tablet: { breakpoint: { max: 768, min: 464 }, items: 2 },

                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 2, // Ensure at least 2 items on mobile screens
                },
              }}
              swipeable={true} // Allows swipe gestures on mobile devices
              draggable={true} // Enables dragging on desktop
              showDots={false} // Optionally show/hide dots
              arrows={true} // Optionally show/hide arrows
              infinite={false} // Optionally make the carousel infinite
              keyBoardControl={true} // Allows keyboard control
              itemClass="carousel-item-padding" // Item class
            >
              {/* <Carousel responsive={responsive}> */}
              {[...selectedMembrs]
                ?.slice()
                .reverse()
                .map((user: any) => (
                  <Box sx={{ display: "flex", alignItems: "center", backgroundColor: colors.farm_white, padding: "5px 10px", borderRadius: "5px" }}>
                    <Avatar sx={{ width: "24px", height: "24px" }} alt={user?.nickname?.slice(0, 1)} src={"data:image/jpeg;base64," + user?.image} />
                    <Typography sx={{ margin: "0 5px", flex: 1 }}>{user?.nickname}</Typography>
                    <RemoveCircleOutlineIcon onClick={() => handleRemoveMember(user.user_id)} sx={{ ...styles.icons, color: colors.farm_red600 }} />
                  </Box>
                ))}
              {/* </Carousel> */}
            </Carousel>
            <ButtonOutlinedCentered sx={{ margin: "10px" }} onClick={() => setShowMembers(true)} title="ユーザーの選択" />
          </Box>
        )}

        {/* Category Selection (Required) */}
        <Typography style={styles.titleSeprator}>カテゴリー（必須）</Typography>
        <DropDown
          style={{ marginTop: -10 }}
          title={T("Category")}
          star
          options={categories}
          value={selectedCategory?.value}
          error={false}
          onChange={(e) => {
            setSelectedCategory(e.target);
            setErrors({ ...errors, category: "" });
          }}
        />

        {/* Genre Selection (Required) */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>ジャンル（必須）</Typography>
        <DropDown style={{ marginTop: -10 }} title={'ジャンル'} star options={groups} value={selectedGroup?.value} error={false} onChange={(e) => setSelectedGroup(e.target)} />

        {/* Image Upload Section (Optional) */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>画像のアップロード （最大5枚）（任意）</Typography>
        <Carousel responsive={responsive}>
          {defaultPostImages.map((item: { id: undefined; path: string }, index: number) => (
            <div key={item.path} style={{ ...styles.carouselImage, marginRight: "10px" }}>
              <AsyncImage style={styles.carouselImage} src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${item.path}`} alt="" />
              {isImageAdded(item) ? (
                <CheckCircleIcon style={{ top: -30, position: "relative" }} onClick={() => {}} color="secondary" />
              ) : (
                <RadioButtonUncheckedIcon style={{ top: -30, position: "relative" }} onClick={() => handleDefaultImage(item)} color="secondary" />
              )}
            </div>
          ))}
        </Carousel>

        <Box sx={{ marginTop: "10px" }}>
          <Carousel responsive={responsive}>
            {images.map((item: ImageItem, index: number) => (
              <div key={item.name} style={{ ...styles.carouselImage, marginRight: "10px" }}>
                <AsyncImage
                  style={styles.carouselImage}
                  src={item?.path ? `${process.env.REACT_APP_AWS_PATH_BUCKET}${true ? "/images" : ""}${item.path}` : item?.imageSrc || ""}
                  alt=""
                />
                <DeleteIcon
                  style={{ top: -30, position: "relative" }}
                  onClick={() => {
                    removeImage(index);
                    saveRemoveImg(item);
                  }}
                  color={"error"}
                />
              </div>
            ))}
          </Carousel>

          {/* Conditional rendering based on iOS or Android */}
          {isIOS(user?.client_id) ? (
            <Button disabled={images.length >= 5} color={"secondary"} sx={styles.uploadImageButton} component="label" variant="outlined">
              {T("upload_images_txt")}
              <input accept="image/*" type="file" hidden multiple onChange={handleImageUploadIOS} />
            </Button>
          ) : (
            <Button disabled={images.length >= 5} color={"secondary"} sx={styles.uploadImageButton} component="label" variant="outlined" onClick={handleImageUploadAndroid}>
              {T("upload_images_txt")}
            </Button>
          )}
        </Box>

        {/* Title Input (Required) */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>タイトル（必須）</Typography>
        <TextField
          onChange={(e) => {
            setTitle(e.target.value);
            setErrors({ ...errors, title: "" });
          }}
          inputProps={{ maxLength: MAX_LENGTH }}
          sx={{ backgroundColor: colors.farm_white, width: "100%" }}
          hiddenLabel
          multiline
          value={title}
          rows={4}
          FormHelperTextProps={{ style: styles.errorMessage }}
          error={!!errors.title}
          helperText={errors.title}
        />

        {/* Description Input (Required) */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>本文（必須）</Typography>
        <TextField
          onChange={(e) => {
            setDescription(e.target.value);
            setErrors({ ...errors, description: "" });
          }}
          inputProps={{ maxLength: MAX_LENGTH }}
          sx={{ backgroundColor: colors.farm_white, width: "100%" }}
          hiddenLabel
          multiline
          value={description}
          rows={7}
          FormHelperTextProps={{ style: styles.errorMessage }}
          error={!!errors.description}
          helperText={errors.description}
        />

        {/* Tags Input */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>タグ</Typography>
        <TagsInput value={tagArray} onChange={setTagArray} onRemoved={handleDeleteTag} name="GroupTags" separators={[" ", ",", "Enter", "return"]} />

        {/* Voting Option (Required) */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>投票を設定しますか？（必須）</Typography>
        <RadioGroup value={acceptVotes} onChange={handleAcceptVotes} row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group">
          <FormControlLabel value="Yes" control={<Radio />} label={T("Yes")} />
          <FormControlLabel value="No" control={<Radio />} label={T("No")} />
        </RadioGroup>
        <br />
        {/* Voting Options Section */}
        {acceptVotes === "Yes" && (
          <div>
            <Typography>投票したい質問</Typography>
            <TextField
              sx={{ width: "100%" }}
              style={styles.textField2}
              placeholder="犬と猫どちらが好きですか？"
              multiline
              rows={2} // Set the number of rows
              helperText={errors.voteQuestion}
              error={!!errors.voteQuestion}
              hiddenLabel
              inputProps={{ maxLength: 500 }}
              value={voteQuestion}
              onChange={(val) => {
                setVoteQuestion(val.target.value);
                setErrors({ ...errors, voteQuestion: "" });
              }}
            />
            {voteOptions.map((item, index) => (
              <div style={{ marginTop: 20 }} key={index}>
                <Typography>回答0{index + 1}</Typography>
                <div style={styles.voteInputContainer}>
                  <TextField
                    sx={{ width: "100%", background: "white" }}
                    hiddenLabel
                    placeholder={index === 0 ? "犬派" : index === 1 ? "猫派" : index === 3 ? "両方好き" : ""}
                    inputProps={{ maxLength: 500 }}
                    value={item.inputText}
                    onChange={(val) => handleVoteValue(val.target.value, index)}
                  />
                  {index > 1 && !editPost ? <DeleteIcon sx={styles.deleteIcon} onClick={() => removeVoteOption(index)} color={"error"} /> : <div style={styles.deleteIcon} />}
                </div>
              </div>
            ))}
            {/* <ButtonOutlinedCentered
              disabled={voteOptions.length >= 5}
              sx={{ marginTop: "10px" }}
              onClick={() => setVoteOptions([...voteOptions, { inputText: "" }])}
              title="投票項目を追加"
            /> */}
            <br />
            <Button
              disabled={voteOptions.length >= 5}
              color="secondary"
              sx={styles.submitButton}
              size={"large"}
              variant="outlined"
              onClick={() => setVoteOptions([...voteOptions, { inputText: "" }])}
            >
              回答を追加する
            </Button>
            <span style={styles.dateTitle}>
              投票終了日 &nbsp;
              <span style={{ color: colors.farm_gray200 }}>最大３ヶ月先まで。</span>
            </span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={errors.date ? styles.dateError : {}}
                minDate={dayjs(moment().format())}
                maxDate={dayjs(moment().add(90, "days").calendar())}
                value={postEndDate}
                format="YYYY/MM/DD"
                onAccept={(newValue) => {
                  handleDate(newValue || undefined);
                }}
              />
              {errors.date && <FormHelperText sx={{ color: colors.farm_red600 }}>{T("fields_are_required")}</FormHelperText>}
            </LocalizationProvider>
          </div>
        )}

        {/* Error Message for Voting Option */}
        <Typography sx={{ fontSize: 12, color: colors.farm_red600 }}>{errors.acceptVotes}</Typography>

        {/* Comments Option (Optional) */}
        <Typography style={{ ...styles.titleSeprator, marginTop: 40 }}>コメントを受け付けますか？（任意）</Typography>
        <RadioGroup value={acceptComments} onChange={handleAcceptComments} row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group">
          <FormControlLabel value="Yes" control={<Radio />} label={T("Yes")} />
          <FormControlLabel value="No" control={<Radio />} label={T("No")} />
        </RadioGroup>
      </div>

      {/* Terms and Conditions Section */}
      <div style={{ padding: "15px 10px" }}>
        <Typography style={{ ...styles.titleSeprator, marginTop: 20 }}>注意事項（必須）</Typography>
        <Typography style={{ ...styles.contentSeprator, marginTop: 20 }}>ターゲットを絞った誹謗中傷の内容ではありません。</Typography>
        <Typography style={{ ...styles.contentSeprator, marginTop: 10 }}>わいせつ行為や犯罪を促進する内容及び募集ではありません。</Typography>
        <Typography style={{ ...styles.contentSeprator, marginTop: 10 }}>投稿内容に責任を持ちます。</Typography>

        <FormControlLabel
          control={<Checkbox checked={agree} onChange={(e) => setAgree(e.target.checked)} />}
          color="error"
          sx={{
            color: false ? colors.farm_red600 : "black",
            marginTop: "30px",
          }}
          label={T("I_have_reviewed_the")}
        />
      </div>

      {/* Submit Button */}
      <Button
        disabled={!agree}
        color="secondary"
        sx={styles.submitButton}
        size={"large"}
        variant="outlined"
        onClick={handleSubmit}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
      >
        投稿する
      </Button>
      <LoadingModal visible={isLoading} />
      <Modal open={showMembers} onClose={() => setShowMembers(false)}>
        <Slide direction="up" in={showMembers} mountOnEnter unmountOnExit>
          <div style={styles.modalContainer}>
            <Box sx={styles.headerStyle}>
              <Box onClick={() => setShowMembers(false)} sx={styles.backButtonStyle}>
                <Typography>戻る</Typography>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
            <Box sx={styles.searchBoxStyle}>
              <Typography sx={styles.instruction}>記号を入力してユーザーを検索できます</Typography>
              <TextField
                sx={styles.textField}
                id="outlined-basic"
                label="検索"
                placeholder="検索"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{loading && <CircularProgress size={20} />}</InputAdornment>,
                }}
              />
            </Box>
            {members.map((member: any) => (
              <Box sx={styles.memberContainer}>
                <Avatar src={"data:image/jpeg;base64," + member.user.image} sx={{ height: "26px", width: "26px" }} />
                <Box sx={{ margin: "0px 10px", flex: 1 }}>
                  <Typography>{member.user.nickname}</Typography>
                  {/* <Typography sx={{ fontSize: "12px" }}>{getRole(member.role)}</Typography> */}
                </Box>
                {!isMemberAdded(member.user.id) ? (
                  <AddCircleOutlineIcon onClick={() => handleAddMember(member.user)} sx={{ ...styles.icons, color: colors.farm_green500 }} />
                ) : (
                  <RemoveCircleOutlineIcon onClick={() => handleRemoveMember(member.user.id)} sx={{ ...styles.icons, color: colors.farm_red600 }} />
                )}
              </Box>
            ))}
          </div>
        </Slide>
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    paddingTop: 90,
    overflow: "scroll",
    width: "100%",
    height: "100%",
  },
  errorMessage: {
    backgroundColor: colors.farm_gray15,
    margin: "0",
    padding: "5px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
    marginBottom: 8,
  },
  rowWithHorizontalPadding: {
    padding: "12px 10px 0 10px",
    display: "flex",
    alignItems: "center",
  },

  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: "0 10px",
  },
  everyoneTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    backgroundColor: "#FF3B00",
    borderRadius: 20,
    marginRight: 10,
  },
  everyonePostStyle: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "mplus-1c-bold",
    color: colors.farm_black,
  },
  logoText: {
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: "mplus-1c-bold",
    color: colors.farm_white,
  },

  titleSeprator: {
    borderBottomColor: colors.farm_green500,
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as const,
    fontSize: 14,
    fontFamily: "mplus-1c-bold",
    marginBottom: 6,
  },
  contentSeprator: {
    borderBottomColor: colors.farm_gray100,
    borderBottomWidth: 1,
    borderBottomStyle: "solid" as const,
    fontSize: 14,
    marginBottom: 6,
  },

  voteInputContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row" as const,
  },
  deleteIcon: {
    height: 30,
    width: 30,
  },
  uploadImageButton: {
    alignSelf: "center",
    margin: "10px auto",
    maxWidth: 200,
    display: "flex",
    borderRadius: 20,
    fontFamily: "mplus-1c-bold",
  },
  carouselImage: {
    height: 100,
    width: "auto",
    borderRadius: 2,
  },
  dateError: {
    borderColor: colors.farm_red600,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 2,
  },
  dateTitle: {
    marginBottom: 10,
    fontFamily: "mplus-1c-bold",
    marginTop: 20,
  },
  searchBoxStyle: { padding: "10px 0", width: "100%", marginTop: "10px" },
  textField: { width: "100%" },
  instruction: { mb: 1 },
  headerStyle: { textAlign: "center", marginBottom: "10px" },
  backButtonStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" },
  modalContainer: {
    width: "100%",
    height: "100%",
    overflow: "scroll",
    backgroundColor: colors.farm_white,
    padding: 20,
  },
  memberContainer: {
    flex: 1,
    display: "flex",
    alingItems: "center",
    padding: "10px 0",
    borderBottom: `1px solid ${colors.farm_gray100}`,
  },
  icons: {
    fontSize: "30px",
    cursor: "pointer",
  },
  textField2: {
    marginTop: "15px",
    background: "white",
  },
};
