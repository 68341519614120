import { Box, Button, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { styles } from "../../styles";
import "./pay.css";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import LoadingModal from "../../../components/LoadingModal";



function ScanStoreQR(): ReactElement {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);

  // console.log("groupInfo", groupInfo);
  // Result
  // const [scannedResult, setScannedResult] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // handleQRCode("8f57cdc5-55b0-41b1-9e47-babb84a876e6");
    window.parent.postMessage("camera_request", "*");
    window.addEventListener("message", (event) => {
      if (event.data.type === "qr_code_data") {
        window.parent.postMessage("camera_close", "*");
        // console.log("....", event?.data?.qrCodeData);
        const qrCodeData = event?.data?.qrCodeData;
        // console.log("Scanned QR Code:", qrCodeData);

        if (qrCodeData) {
          // eslint-disable-next-line no-useless-escape
          const pattern = /^https:\/\/www(-sandbox)?\.pokepay\.jp\/bills\/([a-f0-9\-]+)$/;
          const match = qrCodeData.match(pattern);

          if (match) {
            const qr_id = match[2];
            // setScannedResult(qr_id); // Extracted ID
            handleQRCode(qr_id); //8f57cdc5-55b0-41b1-9e47-babb84a876e6
          } else {
            // setScannedResult("");
            alert("無効な QR コードです。有効な Pokepay 請求書の QR をスキャンしてください。");
          }
        }
      }
    });
    return () => {
      window.parent.postMessage("camera_close", "*");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQRCode = (qrcode: string) => {
    setLoading(true);
    try {
      gcServices.getShopInfo(qrcode).then((res) => {
        // console.log(res);
        setLoading(false);
        navigate("/paymentInfo", { state: { groupInfo: groupInfo, shopInfo: res.shop_information } });
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${groupInfo?.groupImage}`}
            alt={"img"}
            style={{
              ...styles.farmProfileIcon,
              height: 40,
              width: 40,
              margin: 0,
            }}
          />
          &nbsp;{groupInfo?.groupName}
        </Typography>
      </Box>

      {/* QR Scanner */}
      <div className="qr-reader">
        <Button
          onClick={() => {
            window.parent.postMessage("camera_request", "*");
          }}
        >
          Scan QR
        </Button>

        {/* <video ref={videoEl} style={{ width: "100%", height: "auto" }}></video>
        <div ref={qrBoxEl} className="qr-box">
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={256}
            height={256}
            className="qr-frame"
          />
        </div> */}

        {/* Show scanned result */}

        {/* Show errors if any */}
      </div>
      {/* <Typography > Scanned Result: {scannedResult}</Typography> */}
    </div>
  );
}

export default ScanStoreQR;
