import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import {
  loginWithToken,
  registerAppUser,
} from "../redux/Reducers/userAuthReducer";
import { useAppDispatch } from "../redux/store";
import LoadingModal from "../components/LoadingModal";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
import userAuth from "../util/auth-util";
import setAuthToken from "../services/axios-util/set-auth-token";
// import userAuth from "../util/auth-util";
// import { useParams,use} from "react-router-dom";
export default function IntroPage() {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

  const { isLoading, user, isError } = useSelector(
    (state: any) => state.userAuth
  );

  const [newUser, setNewUser] = useState<any>("0");
  const dispatch = useAppDispatch();
  useEffect(() => {
    setAuthToken(user.auth_token);

    const id_kind = queryParameters.get("id_kind");
    const session_id = queryParameters.get("session_id");
    const id = queryParameters.get("id");
    const nickname = queryParameters.get("nickname");
    const version = queryParameters.get("version");
    const client_id = queryParameters.get("client_id");
    const mail = queryParameters.get("mail");
    const name = queryParameters.get("name");
    const access_token = queryParameters.get("access_token");
    // const auth_token = queryParameters.get("token");
    // if (id_kind) {
    //   navigate(
    //     `/userinfo?id_kind=${id_kind}&id=${id}&nickname=${nickname}&version=${version}`
    //   );
    // }

    if (id_kind && id && nickname && session_id === null) {
      let user = {
        name: name,
        nickname: nickname,
        mail: mail,
        id: id,
        id_kind: id_kind,
        version: version,
        client_id: client_id,
        access_token: access_token,
      };
      setTimeout(() => {
        dispatch(registerAppUser(user));
      }, 100);
    } else if (session_id) {
      // console.log("session", session_id);
      setAuthorizationToken(session_id);
      setTimeout(() => {
        dispatch(loginWithToken(session_id));
      }, 100);

      // alert("missing user info id_kind & id && nickname & version etc");
    }

    // eslint-disable-next-line
  }, [queryParameters, navigate]);

  useEffect(() => {
    const new_user = queryParameters.get("new_user");
    setNewUser(new_user !== null ? new_user : "1");
    // console.log(new_user);
    const session_id = queryParameters.get("session_id");
    if (user?.id) {
      userAuth.loginUser(user);
      if (user.auth_token) {
        if (session_id === null) {
          setAuthorizationToken(user.auth_token);
        }

        if (new_user !== null && new_user === "0") {
          navigate("/home");
        }
      }
      // setAuthToken(user.auth_token);
    }
    // eslint-disable-next-line
  }, [user, navigate, queryParameters]);

  // useEffect(() => {
  //   console.log("alert", user);
  //   if (user.id === "1")
  //     if (user.auth_token) {
  //       setAuthorizationToken(user.auth_token);
  //       setTimeout(() => {
  //         dispatch(loginWithToken(user.auth_token));
  //       }, 100);
  //     }
  // }, [user, dispatch]);

  useEffect(() => {
    if (isError) {
      alert(" user error");
    }
  }, [isError]);

  const getPersonalBasicInfo = (
    id_kind: string,
    mebuku_id: string,
    nickname: string,
    version: string,
    client_id: string,
    authorization: string
  ) => {
    setAuthorizationToken(authorization);
    if (
      id_kind &&
      mebuku_id &&
      nickname &&
      version &&
      client_id &&
      authorization
    ) {
      // userAuth.loginUser(user);
      setTimeout(() => {
        dispatch(loginWithToken(authorization));
      }, 100);
    } else {
      alert(" missing USER info id_kind & id & nickname & version etc");
    }
    // Handle the data received from the WebView parent app.
    // setUserInfo({
    //   name: name,
    //   birthday: birthday,
    //   address: address,
    // });
  };

  // Expose the function globally to make it accessible in the WebView
  window.getPersonalBasicInfo = getPersonalBasicInfo;
  var PortalApp: any;
  try {
    PortalApp.GetAccessToken();
  } catch (error) {}

  try {
    window.GetAccessToken();
  } catch (error) {}

  function portalAppGetAccessTokenCallBack(result: any) {
    // WebView app processing }
    try {
      // alert(JSON.stringify(result));
    } catch (error) {}
  }
  window.portalAppGetAccessTokenCallBack = portalAppGetAccessTokenCallBack;

  const SLIDER = [
    {
      title: "めぶくコミュニティに ようこそ",
      description:
        "めぶくコミュニティとはみんなが気軽に意見を交換したり、議論したり物事を発信できる場です。",
      img: require("../assets/sliderimages/new1.png"),
    },
    {
      title: "どんどん参加して まちを良くしていこう",
      description:
        "気になること、知りたいこと、みんなの意見を聞きたい時は意見箱に内容を書いて書いてみよう。",
      img: require("../assets/sliderimages/new2.png"),
    },
    {
      title: "Joinを貯めて まちをめぶかせよう",
      description:
        "投稿したり、コメントしたり、意見を言ったりして参加をするとJoinが貯まります！",
      img: require("../assets/sliderimages/new3.png"),
    },
    {
      title: "Joinを貯めて まちをめぶかせよう",
      description:
        "投稿したり、コメントしたり、意見を言ったりして参加をするとJoinが貯まります！",
      img: require("../assets/sliderimages/new4.png"),
    },
    {
      title: "Joinを貯めて まちをめぶかせよう",
      description:
        "投稿したり、コメントしたり、意見を言ったりして参加をするとJoinが貯まります！",
      img: require("../assets/sliderimages/new5.png"),
    },
  ];

  const [slide, setSlide] = useState(0);

  const onNext = () => {
    if (slide < SLIDER.length) {
      // console.log(slide);
      // console.log(SLIDER.length);
      setSlide(slide + 1);
    }
    if (slide === 4) {
      navigate("/home");
    }
  };

  return (
    <div style={styles.introContainer}>
      <LoadingModal visible={isLoading} handleClose={() =>{}} />

      {newUser !== "0" ? (
        <>
          <Carousel
            showStatus={false}
            swipeable
            showThumbs={false}
            selectedItem={slide}
            onChange={(index) => setSlide(index)}
          >
            {SLIDER.map((item) => (
              <div style={styles.introSliderSec}>
                {/* <p style={{ ...styles.introSliderTxt, textAlign: "center" }}>
              {item.title}
            </p>
            <br />
            <p
              style={{
                ...styles.introSliderDsc,
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {item.description}
            </p> */}
                <img style={styles.introSliderImg} src={item.img} alt="" />
              </div>
            ))}
          </Carousel>
          <div style={styles.introSliderBtnSec}>
            {user?.auth_token && user?.id && (
              <Button
                onClick={onNext}
                style={styles.introSliderBtn}
                size={"large"}
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faChevronRight} />}
              >
                {slide === 0 ? (
                  <span style={styles.introSliderNextbtn}>使い方を見る</span>
                ) : (
                  <span style={styles.introSliderNextbtn}>つぎへ</span>
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
