import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ja";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./EventCalendar.css"; // Import custom CSS
import { colors } from "../../common/colors";

// Set moment locale to Japanese
moment.locale("ja");

const localizer = momentLocalizer(moment);

// Sample events
// const events = [
//   {
//     title: "イベント1",
//     start: new Date(2024, 8, 24),
//     end: new Date(2024, 8, 24),
//   },
//   {
//     title: "イベント2",
//     start: new Date(2024, 8, 25),
//     end: new Date(2024, 8, 25),
//   },
//   {
//     title: "イベント3",
//     start: new Date(2024, 8, 25),
//     end: new Date(2024, 8, 25),
//   },
//   {
//     title: "イベント4",
//     start: new Date(2024, 8, 25),
//     end: new Date(2024, 8, 25),
//   },
//   {
//     title: "イベント5",
//     start: new Date(2024, 8, 25),
//     end: new Date(2024, 8, 25),
//   },
//   {
//     title: "Special Event",
//     start: new Date(2024, 10, 25, 10, 0), // November 25, 2024, 10:00 AM
//     end: new Date(2024, 10, 25, 12, 0), // November 25, 2024, 12:00 PM
//   },
//   {
//     title: "Event",
//     start: new Date(2024, 10, 20, 10, 0), // November 25, 2024, 10:00 AM
//     end: new Date(2024, 10, 20, 12, 0), // November 25, 2024, 12:00 PM
//   },
// ];

// Custom Toolbar component (same as before)
const CustomToolbar: React.FC<any> = ({ date, onNavigate }) => {
  const currentMonth = moment(date).format("MMMM");
  const currentYear = moment(date).format("YYYY");
  const lastMonth = moment(date).subtract(1, "month").format("MMMM");
  const nextMonth = moment(date).add(1, "month").format("MMMM");

  return (
    <div className="custom-toolbar">
      <button onClick={() => onNavigate("PREV")}>&lt; {lastMonth}</button>
      {/* <span>{currentMonth}</span> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
        }}
      >
        <span>{currentYear}</span>
        <span>{currentMonth}</span>
      </div>
      <button onClick={() => onNavigate("NEXT")}>{nextMonth} &gt;</button>
    </div>
  );
};

// EventCalendar component
const EventCalendar: React.FC<any> = ({
  getSelectedEvent = () => {},
  selectedDate = "",
  dates,
}) => {
  // const [selectedDate, setSelectedDate] = useState(null);
  // Use eventPropGetter to customize event date styles
  const eventStyleGetter = (
    event: any,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    const style = {
      backgroundColor: event?.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style,
    };
  };

  const dayPropGetter = (date: moment.MomentInput) => {
    const style = {
      backgroundColor:
        selectedDate && moment(date).isSame(selectedDate, "day")
          ? "#86BB57"
          : "white",
      border: "1px solid #ddd",
    };
    return { style };
  };
  const handleEventClick = (event: any) => {
    getSelectedEvent(event);
  };

  return (
    <div
      style={{
        margin: 8,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: colors.farm_green500,
        borderRadius: 10,
      }}
    >
     <Calendar
  localizer={localizer}
  events={dates}
  culture="ja"
  popup
  startAccessor="start"
  endAccessor="end"
  style={{
    height: 400,
    position: "relative", // Ensures proper stacking
    overflow: "hidden", // Prevent unwanted overflow
  }}
  views={["month"]}
  components={{
    toolbar: CustomToolbar,
  }}
  eventPropGetter={eventStyleGetter} // Apply custom styles to events
  onSelectEvent={handleEventClick} // Handle event click
  dayPropGetter={dayPropGetter}
/>

    </div>
  );
};

export default EventCalendar;
