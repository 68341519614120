import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../common/colors";
import { ReactElement } from "react";
import { CSSProperties } from "react";

interface Props {
  onClick(): void;
  title: string;
  role?: string;
  icon?: ReactElement; // Optional icon prop for the left side
  borderColor?: string;
  disabled?: boolean;
}

const styles: Record<string, CSSProperties> = {
  container: {
    height: 60,
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 8,
    marginBottom: 6,
    padding: 10,
    flexDirection: "row" as const, // Explicitly set to string literal type
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    borderWidth: 2,
    borderColor: colors.farm_gray100,
    borderStyle: "solid",
    minWidth: 130,
  },
  iconWrapper: {
    marginRight: 8,
  },
  title: {
    fontSize: 12,
    color: "black",
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Show ellipsis when text overflows
    flexGrow: 1, // Allow the title to take available space
  },
  role: {
    fontSize: 12,
    color: "red",
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Show ellipsis when text overflows
    maxWidth: 80, // Set a maximum width for the role
    // marginLeft: 8, // Margin between title and role
    //flexGrow: 1,
  },
  arrowIcon: {
    marginRight: 2,
    marginLeft: 4,
    fontSize: 14,
  },
};

export default function IconButtonArrow({ title, role, onClick, icon, borderColor = colors.farm_gray100, disabled }: Props) {
  return (
    <Box style={{ ...styles.container, borderColor: borderColor, opacity: disabled ? 0.5 : 1 }} onClick={disabled ? () => {} : onClick}>
      <Box display="flex" alignItems="center" style={{ flex: 1, overflow: "hidden" }}>
        {icon && <Box style={styles.iconWrapper}>{icon}</Box>}
        <Box>
          <Typography style={styles.title}>{title}</Typography>
          {role && <Typography style={styles.role}>{role}</Typography>}
        </Box>
      </Box>

      <ArrowForwardIosIcon style={styles.arrowIcon} />
    </Box>
  );
}
