import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../components/GreenCity/GCBackButton";
import LoadingModal from "../components/LoadingModal";
import { styles as oldstyles } from "./styles";
import { useSelector } from "react-redux";
import profile from "../assets/images/profile_img.png";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gcServices from "../services/app-services/greenCity/gcServicesR6";

const CreateLeaderEventPost = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { personalPlan } = location.state || {};
  const { user } = useSelector((state: any) => state.userAuth);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [eventOverview, setEventOverview] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [eventStartDate, setEventStartDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [eventEndDate, setEventEndDate] = React.useState<Dayjs | null>(
    eventStartDate
  );
  const [errors, setErrors] = useState({
    title: "",
    venueName: "",
    postalCode: "",
    address: "",
    voteOption: "",
  });

  const shouldDisableDate = (date: any) => {
    // Disable dates before today
    return date.isBefore(dayjs(), "day");
  };
  const shouldDisableEndDate = (date: any) => {
    // Disable dates before today
    return date.isBefore(eventStartDate, "day");
  };
  const validateForm = () => {
    let valid = true;

    let newErrors = {
      title: "",
      venueName: "",
      postalCode: "",
      address: "",
      voteOption: "",
    };
    if (title === "") {
      newErrors.title = "タイトルを入力してください";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const submit = async () => {
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      window.scrollTo(0, 0);
      return;
    }

    try {
      const body = {
        id: personalPlan?.id ? personalPlan?.id : "",
        user_id: user?.id,
        title: title,
        start_date: eventStartDate?.format("YYYY-M-D"),
        end_date: eventEndDate?.format("YYYY-M-D"),
        description: eventOverview,
        web_url: websiteURL,
      };

      if (personalPlan) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res = await gcServices?.editPersonalPlan(personalPlan?.id, body);
        // console.log(res);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res = await gcServices?.createPersonalPlan(body);
        // console.log(res);
      }

      setLoading(false);
      navigate("/myfarm");
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log(personalPlan, "Plan");

    if (personalPlan) {
      setTitle(personalPlan?.title);
      setEventStartDate(dayjs(personalPlan?.start_date));
      setEventEndDate(dayjs(personalPlan?.end_date));
      setEventOverview(personalPlan?.description);
      setWebsiteURL(personalPlan?.web_url);
    }
  }, [personalPlan]);

  return (
    <div>
      <div
        style={{
          paddingTop: 90,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />

        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={{ marginTop: 1, display: "flex", gap: 1 }}>
          <img
            src={
              user.image !== null
                ? "data:image/jpeg;base64," + user.image
                : profile
            }
            alt={"userimg"}
            style={styles.userIcon}
          />

          <span style={{ ...oldstyles.farmInfoTitle, color: "black" }}>
            {user.nickname}
          </span>
        </Box>
      </div>
      <div style={{ padding: "0px 15px 20px 15px" }}>
        <Typography sx={styles.typographyText}>予定名(必須)</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => {
            if (event.target.value.length > 30) return;
            setTitle(event.target.value);
            setErrors({ ...errors, title: "" });
          }}
          value={title}
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
          error={!!errors.title}
          helperText={errors.title}
        />

        <Typography sx={styles.typographyText}>予定日</Typography>

        <Typography
          sx={{
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          開始日(必須)
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              value={eventStartDate}
              onChange={(newDate) => setEventStartDate(newDate)}
              shouldDisableDate={shouldDisableDate}
            />
          </DemoContainer>
        </LocalizationProvider>

        <Typography
          sx={{
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          終了日(開催日と異なる場合は記入)
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              value={eventEndDate}
              onChange={(newValue) => setEventEndDate(newValue)}
              shouldDisableDate={shouldDisableEndDate}
            />
          </DemoContainer>
        </LocalizationProvider>

        <Typography sx={styles.typographyText}>メモ・詳細(任意)</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setEventOverview(event.target.value)}
          value={eventOverview}
          multiline
          rows={3} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />

        <Typography sx={styles.typographyText}>WEBサイトのURL(任意)</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setWebsiteURL(event.target.value)}
          value={websiteURL}
          placeholder="[公式サイト] https://www.abc.com [イベント会場] https://www.event.com"
          multiline
          rows={2} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />
        <Button
          color="secondary"
          onClick={submit}
          sx={styles.submitButton}
          size={"large"}
          variant="contained"
          endIcon={
            <ArrowForwardIosIcon
              sx={{ position: "absolute", right: 0, top: 0, margin: "10px" }}
            />
          }
        >
          完了
        </Button>
      </div>
    </div>
  );
};

const styles = {
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  textField: {
    marginTop: "15px",
    background: "white",
  },
  userIcon: {
    width: "30px",
    height: "30px",
    borderRadius: "35px",
  },
  typographyText: {
    borderBottom: "2px solid #86BB57",
    paddingTop: "50px",
    textAlign: "left",
    fontSize: "15px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};
export default CreateLeaderEventPost;
