import React from "react";
import { Box, Typography, IconButton, Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface UserCardProps {
  userName: string;
  role: string;
  onApprove: () => void;
  onDelete: () => void;
  onMoreOptions: () => void;
  onChatPress?: () => void;
  showApproveIcon?: boolean;
  showDeleteIcon?: boolean;
  showMoreOptionsIcon?: boolean;
}

const UserCard: React.FC<UserCardProps> = ({
  userName,
  role,
  onApprove,
  onDelete,
  onMoreOptions,
  showApproveIcon = true,
  showDeleteIcon = true,
  showMoreOptionsIcon = true,
  onChatPress,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        height: "30px",
        // padding: '0 16px',
      }}
    >
      {/* Left Section: User Icon, Name, Role */}
      <Box sx={{ display: "flex", alignItems: "center" }} onClick={onChatPress}>
        <Avatar sx={{ width: 30, height: 30, mr: 1}} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{userName}</Typography>
          <Typography style={{fontSize:12}} variant="body2" color="text.secondary">
            &nbsp; {role}
          </Typography>
        </Box>
      </Box>

      {/* Right Section: Conditionally Rendered Icons */}
      <Box>
        {showApproveIcon && (
          <IconButton onClick={onApprove} color="primary">
            <CheckCircleIcon />
          </IconButton>
        )}
        {showDeleteIcon && (
          <IconButton onClick={onDelete} color="error">
            <RemoveCircleIcon />
          </IconButton>
        )}
        {showMoreOptionsIcon && (
          <IconButton onClick={onMoreOptions}>
            <MoreHorizIcon sx={{ color: "#4792E0" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default UserCard;
