import React, { useEffect, useState } from "react";
import { List } from "@mui/material";
import SearchBar from "../../components/Phase2/Searchbar";
import VerticalListP2 from "../../components/lists/VerticalListP2";
import appServices from "../../services/app-services/appServices";
import LoadingModal from "../../components/LoadingModal";
import MessageModal from "../../components/Models/GreenCity/MessageModal";
interface Props {}

const SearchScreen = (props: Props) => {
  const [posts, setPosts] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [reSearch, setReSearch] = useState(false);

  const handleSearch = (results: any) => {
    setPosts(results);
    setReSearch(false);
    setLikeLoading(false);
  };

  useEffect(() => {
    // console.log(posts);
  }, [posts]);
  const [message,setMessage]=useState('');
  const handleReport = async (post_id: number, status: any) => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await appServices.acceptRejectPost(post_id, status);
      // console.log(response, "responseeeeeee");
      setLoading(false);
      setMessage('報告しました。')
      // setMessage('報告の問題');
      setTimeout(() => {
        setReSearch(true);
      }, 1000);
    } catch (error) {
      // setMessage('報告しました。')
      setMessage('報告の問題');
      setLoading(false);
    }
  };

  const [likeLoading, setLikeLoading] = useState(false);

  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      //   setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        // let data = { categoryId: "", page: page, order: order };
        // await dispatch(getCategoryPosts(data));
        // await getSinglePost();
        // setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      //   setLikeLoading(false);
    } finally {
      setReSearch(true);
    }

    // console.log('like')
  };
  return (
    <div style={{ paddingTop: 100 }}>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <SearchBar focus reSearch={reSearch} onResults={handleSearch} />
      </div>
      <LoadingModal visible={loading} handleClose={() => {}} />
      <List>
        <VerticalListP2
          handleReport={handleReport}
          page={"1"}
          order={"order"}
          items={posts}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      </List>
      <MessageModal open={message!==""} message={message} onClose={()=>setMessage("")}/>
    </div>
  );
};

export default SearchScreen;
