import { Box } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import EventDetailsList from "../../components/lists/EventDetailsList";
const CalenderEvent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state?.event;
  // console.log(event, "event");

  return (
    <Box>
      <Box
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      </Box>
      {event?.map((event: any) => (
        <Box key={event.id}>
          <EventDetailsList
            items={[event]}
            page={""}
            order={""}
            handleReport={function (post_id: number, status: any): void {
              throw new Error("Function not implemented.");
            }}
            onLike={function (post_id: number): void {
              throw new Error("Function not implemented.");
            }}
            onUnLike={function (post_id: number): void {
              throw new Error("Function not implemented.");
            }}
            likeLoading={false}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CalenderEvent;
