import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { colors } from "../../../common/colors";

// Define the props interface
interface PIIConfirmationProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const PIIConfirmation: React.FC<PIIConfirmationProps> = ({ open, onClose, onContinue }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          // height: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {/* <Typography variant="h6" gutterBottom>
        開示
        </Typography> */}
        <br />
        <Typography variant="body1" sx={{ mb: 2 }}>
          このグループは氏名の提供を求めています。同意しますか？
        </Typography>
        <br />
        <br />

        {/* <Typography variant="body1" sx={{ mb: 2 ,textAlign:'center'}}>
        ・氏名
        </Typography> */}
        {/* <Typography variant="body1" sx={{ mb: 2 ,textAlign:'center'}}>
        ・生年月日
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 ,textAlign:'center'}}>
        ・住所
        </Typography> */}

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              cursor: "pointer",
              color: colors.farm_white,
              textAlign: "center",
              backgroundColor: colors.farm_blue200,
              border: "2px solid " + colors.farm_blue200,
              margin: "0px 30px",
              borderRadius: "10px",
              borderLeft: "none",
              padding: "10px",
            }}
            onClick={onContinue}
          >
            {"はい"}
          </Typography>
          <br></br>
          <Typography
            sx={{
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              color: colors.farm_blue200,
              margin: "5px 30px 30px 30px",
              borderRadius: "10px",
              border: "2px solid " + colors.farm_gray100,
            }}
            onClick={onClose}
          >
            {"いいえ"}
          </Typography>
        </Box>

        {/* <ButtonPrimary title='はい'  onClick={onContinue}/>
        <ButtonOutlinedCentered title='同意して進む'  onClick={onClose}/> */}
      </Box>
    </Modal>
  );
};

export default PIIConfirmation;
