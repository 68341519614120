import React, { useEffect } from "react";
import { Modal, Box } from "@mui/material";
import ButtonPrimary from "../../ButtonPrimary";

// Define the props interface
interface MessageModalProps {
  open: boolean;
  buttonText?: string;
  showButton?: boolean;
  message: string;
  onClose: () => void;
  onContinue?: () => void;
}

const MessageModal: React.FC<MessageModalProps> = ({ open, onClose, onContinue, buttonText, showButton, message }) => {
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (open && !showButton) {
      // Close the modal after 3 seconds if showButton is false
      timer = setTimeout(() => {
        onClose();
      }, 2500);
    }

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, [open, showButton, onClose]);

  return (
    <Modal open={open}  onClose={(event, reason) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: message }} />
        {showButton && <br />}
        {showButton && onContinue && <ButtonPrimary title={buttonText ? buttonText : "同意して進む"} onClick={onContinue} />}
      </Box>
    </Modal>
  );
};

export default MessageModal;
