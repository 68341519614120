import React from "react";

function Inmaintenance() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f3f4f6",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <h1 style={{ fontSize: "24px", fontWeight: "bold", color: "#374151" }}>
      メンテナンス中です・・・
      </h1>
      {/* <p style={{ fontSize: "16px", color: "#6b7280", marginTop: "10px" }}>
        現在、サイトはメンテナンス中です。しばらくお待ちください。
      </p>
      <p style={{ fontSize: "14px", color: "#9ca3af", marginTop: "5px" }}>
        ご不便をおかけして申し訳ございません。
      </p> */}
    </div>
  );
}

export default Inmaintenance;
