import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EventApplicationDetails from "./EventApplicationDetails";
import { Box, Button, SelectChangeEvent } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GCBackButton from "../GreenCity/GCBackButton";

const UserEventPaymentDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  const detail = item?.post_events[0];
  const [selectedValues, setSelectedValues] = useState(
    detail?.participant_settings?.map(() => 0)
  ); // Initialize with default values
  const [checkIfValueIsSelected, setCheckIfValueIsSelected] = useState(true);
  // console.log("selectedValues", detail);

  // Handle change for dropdowns
  const handleChange = (index: number) => (event: SelectChangeEvent) => {
    const newValues = [...selectedValues];
    newValues[index] = parseInt(event.target.value, 10);
    // Check if all elements are 0
    const isAllZero = newValues.every((num) => num === 0);
    setCheckIfValueIsSelected(isAllZero);
    setSelectedValues(newValues);
  };

  return (
    <Box sx={{ marginTop: 12, padding: "0 20px" }}>
      <Box sx={{ marginBottom: "-20px", marginLeft: "-12px" }}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      </Box>
      {detail?.participant_settings?.length > 0 && (
        <Box>
          <EventApplicationDetails
            item={item}
            selectedValues={selectedValues}
            handleChange={handleChange}
          />
          <Button
            color="secondary"
            sx={style.submitButton}
            size={"large"}
            disabled={checkIfValueIsSelected}
            variant="outlined"
            onClick={() =>
              navigate("/eventapplicationpayment", {
                state: {
                  item: item,
                  selectedValues: selectedValues,
                },
              })
            }
            endIcon={
              <ArrowForwardIosIcon
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  margin: "10px",
                }}
              />
            }
          >
            申込内容を確認する
          </Button>
        </Box>
      )}
    </Box>
  );
};
const style = {
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};
export default UserEventPaymentDetail;
