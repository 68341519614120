import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GcSearchState {
  searchText: string;
  filters:any
}

const initialState: GcSearchState = {
  searchText: "",
  filters:{categrory:{},group:{},sort:{}}
};

const gcSearchReducer = createSlice({
  name: "gcSearch",
  initialState,
  reducers: {
    setGcSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setSearchFilters: (state, action: PayloadAction<{ category?: any; group?: any; sort?: any }>) => {
      if (action.payload.category) state.filters.category = action.payload.category;
      if (action.payload.group) state.filters.group = action.payload.group;
      if (action.payload.sort) state.filters.sort = action.payload.sort;
    },
    
    clearGcSearchText(state) {
      state.searchText = "";
    },
    clearSearchFilters(state) {
      state.filters =  {categrory:{},group:{},sort:{}}
    },
  },
});

export const { setGcSearchText, clearGcSearchText,setSearchFilters,clearSearchFilters } = gcSearchReducer.actions;
export default gcSearchReducer.reducer;
