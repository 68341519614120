import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../common/colors";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import ImageViewer from "../../../components/GreenCity/ImageViewer";
import LoadingModal from "../../../components/LoadingModal";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import style from "./styles";
import dayjs from "dayjs";
dayjs.locale("ja");
function RejectReimbursement(): ReactElement {
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const navigate = useNavigate();
  const item = useLocation().state.item;
  // const { user } = useSelector((state: any) => state.userAuth);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [reason, setReason] = useState("");
  const [isFoucs, setIsFoucs] = useState(false);
  const handleImage = (image: any, index: any) => {
    // console.log(index, image);
    if (index === 4) setImages([{ path: image }]);
  };
  // Table component
  const ReimbursementTable = ({ data }: { data: any[] }) => (
    <>
      <div style={{ marginBottom: 10, marginTop: 10, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <>
          <table style={{ width: "98%", tableLayout: "fixed", marginBottom: 4, marginTop: 4 }}>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: colors.farm_gray50,
                      border: `1px solid ${colors.farm_gray15}`,
                      width: "33%",
                    }}
                  >
                    {row.col1}
                  </td>
                  {/* Apply background color to the first column */}
                  {index === 0 ? (
                    <td
                      style={{
                        border: `1px solid ${colors.farm_gray15}`,
                        width: "67%",
                        overflow: "hidden", // Prevent content overflow
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 12,
                      }}
                    >
                      <Box sx={style.rowMargin}>
                        <Avatar src={"data:image/jpeg;base64," + JSON.parse(row?.col2).image} sx={{ ...style.avatar, width: "30px" }} />
                        <Typography>{JSON.parse(row.col2).nickname}</Typography>
                      </Box>
                    </td>
                  ) : (
                    <td
                      onClick={() => {
                        handleImage(row.col2, index);
                      }}
                      style={{
                        border: `1px solid ${colors.farm_gray15}`,
                        width: "67%",
                        fontSize: 12, // Keep font size
                        whiteSpace: "normal", // Allow text to wrap to the next line
                        wordBreak: "break-word", // Break long words if needed

                        color: index === 4 ? "#3939a6" : "black",
                      }}
                    >
                      <>{index === 4 ? <span>{decodeURIComponent(row.col2?.split(/-(.+)/)[1])}</span> : <span>{row.col2}</span>}</>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
      <hr />
    </>
  );

  const handleSubmit = async () => {
    try {
      let body = {
        status: "Rejected",
        rejection_reason: reason,
      };
      setLoading(true);
      let msg = "立て替え申請を却下しました。";
      const res = await gcServices.rejectReimbursements(item?.id, body);
      if (res.success) {
        setLoading(false);
        setMessage(msg);
        // setTimeout(() => {
        //   setMessage("");
        //   navigate(-1);
        // }, 3099);
      } else {
        setLoading(false);

        setMessage(res?.message);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage("Error " + error?.message);
    }
  };

  return (
    <div style={{ paddingTop: 100, margin: 4 }}>
      <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} />
      <MessageModal
        showButton={message === "立て替え申請を却下しました。"}
        buttonText="閉じる"
        onContinue={() => {
          navigate(-1);
        }}
        message={message}
        open={message.length > 0}
        onClose={() => {
          setMessage("");
        }}
      />

      <div style={{ margin: 14 }}>
        <Box sx={style.rowMargin}>
          <Avatar src={groupInfo.groupImage} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>{groupInfo?.groupName}</Typography>
        </Box>

        <ReimbursementTable
          data={[
            { col1: "請求先", col2: JSON.stringify(item?.user) },
            { col1: "お支払日", col2: dayjs(item?.payment_date).format("YYYY年M月D日(ddd)") },
            { col1: "お支払先", col2: item?.payment_address },
            { col1: "お支払い金額", col2: item?.amount },
            { col1: "お支払い方法", col2: item?.image },
            { col1: "メモ", col2: item?.details },
            { col1: "申請日", col2: dayjs(item.created_at.split("T")[0]).format("YYYY年M月D日(ddd)") },
            { col1: "申請ID", col2: item.id },
            // { col1: "立て替え完了日", col2: item.status === "Approved" ? dayjs(item.updated_at.split("T")[0]).format("YYYY年M月D日(ddd)") : "" },
            { col1: "ステータス", col2: item?.status === "Approved" ? "精算済み" : item?.status === "pending" ? "精算待ち" : "却下" },
          ]}
        />
      </div>
      <ImageViewer images={images} isModalOpen={images.length > 0} onClose={() => setImages([])} />
      <Typography style={{ ...styles.titleSeprator, marginTop: "20px" }}>却下理由</Typography>
      <TextField
        onFocus={() => {
          setIsFoucs(true);
          setTimeout(
            () =>
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              }),
            100
          );
        }}
        onBlur={() => setIsFoucs(false)}
        sx={{ ...styles.textField }}
        id="outlined-basic"
        variant="outlined"
        multiline
        rows={2}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />

      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: isFoucs ? "300px" : "0px" }}>
        <Button
          color="secondary"
          disabled={reason.trim().length === 0}
          onClick={() => {
            handleSubmit();
          }}
          sx={styles.button}
          size={"large"}
          variant="contained"
        >
          送る
        </Button>
      </Box>
    </div>
  );
}

const styles = {
  titleSeprator: {
    borderBottomColor: colors.farm_green500,
    borderBottomWidth: 2,
    borderBottomStyle: "solid" as const,
    fontSize: 14,
    marginBottom: 6,
  },
  textField: { width: "100%" },
  button: {
    borderRadius: 20,
    // backgroundColor: colors.farm_blue200,
    marginTop: "20px",
    width: 220,
  },
};

export default RejectReimbursement;
