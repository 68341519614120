import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useSearchParams } from "react-router-dom";
// import { getGroups } from "../redux/Reducers/groupReducer";
// import { loginUser } from "../redux/Reducers/adminAuthReducer";
// import { useAppDispatch } from "../redux/store";
// import setAuthToken from "../services/axios-util/set-auth-token";
// import { useAppDispatch } from "../redux/store";
// import AES from "../util/AES";
// import loginUser from "./loginUser";
import { styles } from "./styles";
// import useMessage from "@rottitime/react-hook-message-event";
export default function UserInfo() {
  // const [queryParameters] = useSearchParams();
  // const [user, setUser] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  // const { groups } = useSelector((state: any) => state.groups);
  // const dispatch = useAppDispatch();

  // useMessage("authenticate", (send, payload) => {
  //   //use sendMessage to post back to the sender
  //   console.log("I just received : ", payload);
  //   alert("payload" + payload);
  //   // alert("payload" + JSON.stringify(payload));
  //   send({ type: "ACCESS_TOKEN", payload: { success: true } });
  // });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccessToken = (token: any) => {
    // Handle the data received from the WebView
    // console.log("Web App Function called with data:", token);
    // alert(token);

    // setAuthToken(token);

    // userApi();

    // Optionally, perform actions in your ReactJS app based on the data
  };

  // Expose the function globally to make it accessible in the WebView
  window.getAccessToken = getAccessToken;

  const getPersonalBasicInfo = (
    name: string,
    address: string,
    birthday: string
  ) => {
    // Handle the data received from the WebView parent app.
    setUserInfo({
      name: name,
      birthday: birthday,
      address: address,
    });
  };

  // Expose the function globally to make it accessible in the WebView
  window.getPersonalBasicInfo = getPersonalBasicInfo;

  // const userApi = () => {
  //   // dispatch(loginUser());
  //   dispatch(getGroups());
  // };

  // useEffect(() => {
  //   const key = queryParameters.get("user");
  //   console.log(key);

  //   if (key) {
  //     try {
  //       // eslint-disable-next-line no-useless-escape

  //       const _slash = key.replace(/\*/g, "/");
  //       const plus = _slash.replace(/\plus/g, "+");
  //       // alert("key" + plus);
  //       const userinfo = AES.decrypt(plus);

  //       console.log(userinfo);
  //       setUser(JSON.parse(userinfo));
  //       // alert(userinfo);
  //     } catch (error) {
  //       // alert(JSON.stringify(error));
  //     }
  //   }
  // }, [queryParameters]);

  return (
    <React.Fragment>
      <div style={{ ...styles.farmProfileInfo, flexDirection: "column" }}>
        <img
          src={require("../assets/images/profile.png")}
          alt="BigCo Inc. logo"
          style={{ ...styles.farmProfileIcon }}
        />
        <span style={styles.farmInfoTitle}>Name: {userInfo?.name}</span>
        <span style={styles.farmInfoTitle}>Address: {userInfo?.address}</span>
        <span style={styles.farmInfoTitle}>
          Birth Date: {userInfo?.birthday}
        </span>
      </div>
    </React.Fragment>
  );
}
