import { Avatar, Box, Typography } from "@mui/material";
import TableComponent from "../GreenCity/TableComponent";
import Separator from "../Separator";
import { colors } from "../../common/colors";
import DropDown from "../DropDown";
import dayjs from "dayjs";

const EventApplicationDetails = ({
  item,
  selectedValues,
  handleChange,
  dropdownDisabled = false,
}: any) => {
  const createDropDown = (
    maxNumber: any,
    selectedValue: any,
    handleChange: any
  ) => {
    const limitedMaxNumber = Math.min(maxNumber, 5);
    const options = Array.from({ length: limitedMaxNumber }, (_, i) => ({
      value: i + 1,
      name: `${i + 1}人`,
    }));
    options.unshift({ value: 0, name: "0人" });

    return (
      <DropDown
        title={``}
        disabled={maxNumber === 0 || dropdownDisabled}
        value={selectedValue}
        options={options}
        onChange={handleChange}
      />
    );
  };

  const detail = item?.post_events[0];

  const getTotalParticipantsPerEvent = () => {
    const eventTotals: any = [];

    if (detail?.users.length === 0) return 0;

    detail?.users?.forEach((user: any) => {
      if (user.status === "Cancelled") return; // Skip this user if status is "Cancelled"
      const applicationDetails = user.application_details;

      applicationDetails.forEach((event: any) => {
        if (!eventTotals[event.name]) {
          eventTotals[event.name] = 0; // Initialize event total if not present
        }
        eventTotals[event.name] += event.number_of_participants; // Add participants
      });
    });

    return Object.values(eventTotals);
  };
  const formatEventDate = (start_date: any, end_date: any) => {
    const startDate = dayjs(start_date);
    const endDate = dayjs(end_date);

    if (startDate.isSame(endDate, "day")) {
      // If start and end dates are the same
      return startDate.format("YYYY年M月D日(ddd)");
    } else {
      // If start and end dates are different
      return `${startDate.format("YYYY年M月D日(ddd)")}~${endDate.format(
        "M月D日(ddd)"
      )}`;
    }
  };
  return (
    <Box>
      <Box sx={{ marginTop: 4 }}>
        <Typography sx={{ marginBottom: 1, fontWeight: "bold" }}>
          イベント情報
        </Typography>
        <TableComponent
          data={[
            {
              col1: "主催者（送金先)",
              col2: (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Avatar
                    sx={{ width: 25, height: 25, objectFit: "cover" }}
                    alt="Remy Sharp"
                    src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${item?.member_group?.group_icon}`}
                  />
                  <Typography>{item?.member_group?.name}</Typography>
                </Box>
              ),
            },
            {
              col1: "イベント名",
              col2: detail?.title,
            },

            {
              col1: "募集期間",
              col2: (
                <Box>
                  <Typography
                    sx={{ color: "#A2A2A2", fontSize: 12, marginLeft: 1 }}
                  >
                    {formatEventDate(
                      detail?.application_start_date,
                      detail?.application_end_date
                    )}
                  </Typography>
                </Box>
              ),
            },
          ]}
        />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <Typography sx={{ marginBottom: 1, fontWeight: "bold" }}>
          参加項目
        </Typography>

        {detail.participant_settings &&
          detail.participant_settings?.map((item: any, index: number) => {
            const totalAllowed: any = getTotalParticipantsPerEvent() || [];

            const allowedParticipants = totalAllowed[index] || 0;

            const totalParticipants =
              parseInt(item.number_of_participants) - allowedParticipants;

            return (
              <Box
                sx={{
                  padding: "10px 10px",
                  backgroundColor: colors.farm_gray15,
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography>
                      {item.name} :
                      {item.free ? "無料" : parseInt(item.fee_amount)}
                    </Typography>
                    {!item.group && (
                      <Typography sx={{ color: colors.farm_gray200 }}>
                        (募集人数: {parseInt(item.number_of_participants)}人)
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ marginTop: "-5px" }}>
                    {createDropDown(
                      item.group ? 1 : totalParticipants,
                      selectedValues[index],
                      handleChange(index)
                    )}
                  </Box>
                </Box>
                <Separator
                  color={colors.farm_green100}
                  sx={{ height: "2px" }}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default EventApplicationDetails;
