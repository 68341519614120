import React from "react";
import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

interface InfoMessageProps {
  message?: string;
  show?: boolean;
  onClick?: () => void;
}

const InfoMessage: React.FC<InfoMessageProps> = ({ message, show, onClick }) => {
  const { user } = useSelector((state: any) => state.userAuth);

  const navigateToMypage = () => {
    // window.location.href = "mebukug102016://mebukuapp-host/mypage";
    try{
    const messageData = {
      url: "mebukug102016://mebukuapp-host/mypage",
      condition: true,
    };
    window.parent.postMessage(
      { type: "mypage", data: messageData },
      "*"
    );
  } catch (error) {
    console.error(error);
  }
  };
  
  return (user?.id_kind!=='1') ? (
    <Box onClick={()=>navigateToMypage()} sx={{ display: "flex", alignItems: "center", position: "fixed", bottom: "50px", right: "12px", justifyContent: "flex-end", width: "100%", backgroundColor:'white' ,borderRadius:1,zIndex:9}}>
     
      <Tooltip
        placement="left"
        arrow
       
        open={true}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<Typography sx={{ color: "#000" }}>{'めぶくIDへアップグレード！'}</Typography>}
        slotProps={{
          popper: {
            disablePortal: true,
          },
        }}
        componentsProps={{
          tooltip: {
            sx: {
              border: "1px solid #70AD47",
              bgcolor: "#C4E0B2",
              //   border: "1px solid #70AD47",
              "& .MuiTooltip-arrow": {
                color: "#C4E0B2",
                "&::before": {
                  border: "1px solid #70AD47", // Adds border to arrow
                },
              },
            },
          },
        }}
      >
        <img alt={''} style={{ height: "40px", width: "40px", }} src={require("../assets/images/touch.png")} />
      </Tooltip>
    </Box>
  ) : null;
};

export default InfoMessage;
