import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { colors } from "../../common/colors";
// import { T } from "react-translator-component";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";

function Disclosure(): ReactElement {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  // const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        backgroundColor: colors.farm_gray15,
        paddingTop: 110,
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      <Box
  style={{
    height: 300,
    maxWidth: "100vw", // Ensures no overflow
    borderColor: colors.farm_gray100,
    borderWidth: 2,
    borderRadius: 8,
    borderStyle: "solid",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    padding: 10,
    boxSizing: "border-box",
  }}
>
  <iframe
    src="https://www.mebukupay.com/terms2"
    width="100%"
    height="100%"
    style={{
      border: "none",
      marginTop: -100,
      overflowY: "auto",
      display: "block",
      maxWidth: "100%", // Prevents iframe from exceeding parent width
    }}
    title="Terms and Conditions"
    // onLoad={() => setIframeLoaded(true)}
  />
</Box>

      <Stack spacing={2} style={{ width: "90%" }}>
        <span style={{ height: 10 }}></span>
        <FormGroup>
          {/* <Typography>{T("I_am_responsible")}</Typography> */}
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: colors.farm_blue200,
                  "&.Mui-checked": {
                    color: colors.farm_blue200,
                  },
                  "&.Mui-checked .MuiSvgIcon-root": {
                    color: colors.farm_blue200,
                  },
                }}
                onChange={handleAgreeChange}
                value={agree}
              />
            }
            label={'上記の注意事項を確認し、すべての事項に同意いたします。'}
          />
        </FormGroup>
        <Button
          onClick={() => {
            navigate("/creategroupstep1");
          }}
          disabled={!agree}
          style={{
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: colors.farm_blue200,
          }}
          size={"large"}
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          <span style={{ minWidth: 200 }}>つぎへ</span>
        </Button>
      </Stack>
      <div style={{ height: 120 }}></div>
    </div>
  );
}

export default Disclosure;
