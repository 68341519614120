import { useEffect, useRef, useState } from "react";

const useLegacyKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const viewportHeight = useRef(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      const heightDiff = viewportHeight.current - newHeight;

      if (heightDiff > 200) {
        // Threshold for keyboard detection
        setKeyboardHeight(heightDiff);
      } else {
        setKeyboardHeight(0);
      }

      viewportHeight.current = newHeight;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return keyboardHeight;
};

export default useLegacyKeyboardHeight;
