import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { colors } from "../../../common/colors";
import { styles } from "../../../pages/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Define the props interface
interface DisclosureModalProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const DisclosureModal: React.FC<DisclosureModalProps> = ({ open, onClose, onContinue }) => {
  const [isAgreed, setIsAgreed] = useState(false);

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(event.target.checked);
  };

  const handleContinue = () => {
    if (isAgreed) {
      onContinue();
      onClose();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          backgroundColor: colors.farm_gray15,
          paddingTop: 110,
          paddingLeft: "10px",
          paddingRight: "10px",
          height: "100%",
        }}
      >
        {/* <GCBackButton title="もどる" onClick={() => navigate(-1)} /> */}
        <Box
  style={{
    height: 300,
    maxWidth: "100vw", // Ensures no overflow
    borderColor: colors.farm_gray100,
    borderWidth: 2,
    borderRadius: 8,
    borderStyle: "solid",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    padding: 10,
    boxSizing: "border-box",
  }}
>
  <iframe
    src="https://www.mebukupay.com/terms2"
    width="100%"
    height="100%"
    style={{
      border: "none",
      marginTop: -100,
      overflowY: "auto",
      display: "block",
      maxWidth: "100%", // Prevents iframe from exceeding parent width
    }}
    title="Terms and Conditions"
    // onLoad={() => setIframeLoaded(true)}
  />
</Box>
        <FormControlLabel control={<Checkbox checked={isAgreed} onChange={handleAgreeChange} />} label="上記の内容を確認し、全ての内容に同意します。" />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleContinue}
            disabled={!isAgreed}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>つぎへ</span>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DisclosureModal;
