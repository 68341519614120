import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccessibleTable from "../components/tableItems";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SelectedListItem from "../components/listItems";
import GroupTable from "../components/groupTable";
import { styles } from "./styles";
import PendingPosts from "../components/pendingPosts";
import { LanguageList } from "react-translator-component";
import { useLocation, useNavigate } from "react-router-dom";
import { T } from "react-translator-component";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAppDispatch } from "../redux/store";
import { reset } from "../redux/Reducers/adminAuthReducer";
import setAuthToken from "../services/axios-util/set-auth-token";
import ShowSuggestionsList from "../components/ShowSuggestionsList";
import appServices from "../services/app-services/appServices";
import downarrow from "../assets/images/downarrow.png";
import LoadingModal from "../components/LoadingModal";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
import PendingComments from "../components/PendingComments";
import DashboardComponent from "./MyFarmsPages/DashboardComponent";
const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();
export default function Dashboard() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { admin } = useSelector((state: any) => state.adminAuth);

  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("Dashboard");
  const [pagination, setPagination] = React.useState<any>({});
  const [loader, setLoader] = React.useState(false);
  const [suggestions, setSuggestion] = useState<any>([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [snake, setSnake] = useState<any>("");

  useEffect(() => {
    if (state?.index === "PendingPosts") setSelectedItem("PendingPosts");
  }, [state]);
  const handleListItemClick = (index: number) => {
    if (index === 0) {
      setSelectedItem("Dashboard");
    } else if (index === 1) {
      setSelectedItem("Users");
    } else if (index === 2) {
      setSelectedItem("Groups");
    } else if (index === 3) {
      setSelectedItem("PendingPosts");
    } else if (index === 4) {
      setSelectedItem("Pool");
    } else if (index === 5) {
      setSelectedItem("Comments");
    }
  };

  useEffect(() => {
    getSuggestion();
    // eslint-disable-next-line
  }, []);

  const timeouttxt = T("Session_timeout_Please_log_in_again");
  const getSuggestion = async () => {
    try {
      setLoader(true);
      const res = await appServices.getSuggestion(1);
      setSuggestion(res.suggestions);
      setPagination(res.pagination);
      setLoader(false);
    } catch (error: any) {
      console.error("error", error);
      setLoader(false);
      console.error(error?.response?.status);
      if (error?.response?.status === 403) {
        setSnake(timeouttxt);
        setTimeout(() => {
          dispatch(reset());
          setAuthToken("__");
        }, 1000);
      }
    }
  };
  const handleMore = async () => {
    //suggestions
    if (pagination?.next_page > 0) {
      try {
        setLoader(true);
        const res = await appServices.getSuggestion(pagination.next_page);
        setLoader(false);
        const newItems = [...suggestions, ...res.suggestions];
        setSuggestion(newItems);
        setPagination(res.pagination);
      } catch (error) {
        setLoader(false);
        if (admin.auth_token) {
          setAuthorizationToken(admin.auth_token);
        }
      }
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setDialog(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {T("Dashboard")}
            </Typography>
            <IconButton color="inherit">
              <Badge color="secondary">
                <AccountCircleIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit">
              <LanguageList
                onChange={(item: any) => navigate("/adminhome")}
                Theme="Dropdown"
              />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(reset());
                setAuthToken("__");
              }}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "rgba(134,187,87,1)",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon sx={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          <Divider style={{ backgroundColor: "black" }} />
          <List
            component="nav"
            sx={{ backgroundColor: "rgba(134,187,87,1)", height: "100%" }}
          >
            <SelectedListItem handleListItemClick={handleListItemClick} />
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container style={{ marginTop: 4, marginBottom: 4 }}>
            {/* Dashboard's table starts*/}
            {selectedItem === "Dashboard" && (
              <Grid>
                <Grid>
                  <Paper
                    sx={{
                      p: 2,
                      m: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>{T("Dashboard_Content")}...</span>
                    <DashboardComponent />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {/* Dashboard's table ends*/}

            {/* User's table starts*/}
            {selectedItem === "Users" && (
              <Grid>
                <Grid>
                  <Paper
                    sx={{
                      p: 2,
                      m: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <AccessibleTable />
                    <Dialog open={dialog} onClose={handleClose}>
                      <DialogTitle style={styles.adminUserDialogTitle}>
                        {T("Add_New_Group")}
                      </DialogTitle>
                      <DialogContent>
                        <div style={styles.adminUserDialogContent}>
                          <div style={styles.adminUserDialogTitleName}>
                            <span style={styles.adminUserName}>Group Name</span>
                            <TextField style={styles.adminUserInputField} />
                          </div>
                          <Button
                            style={styles.adminUserBtn}
                            size={"large"}
                            variant="contained"
                          >
                            <span
                              onClick={handleClose}
                              style={styles.adminUserBtnTxt}
                            >
                              {T("Create_Group")}
                            </span>
                          </Button>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          style={styles.adminUserCancelBtnTxt}
                          onClick={handleClose}
                        >
                          {T("Cancel")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Paper>
                </Grid>
              </Grid>
            )}
            {/* User's table ends */}

            {/* Admin Add table starts*/}
            {selectedItem === "Groups" && (
              <Grid>
                <Grid>
                  <Paper
                    sx={{
                      p: 2,
                      m: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <GroupTable />
                    <Dialog open={dialog} onClose={handleClose}>
                      <DialogTitle style={styles.adminUserDialogTitle}>
                        {T("Add_New_Group")}
                      </DialogTitle>
                      <DialogContent>
                        <div style={styles.adminUserDialogContent}>
                          <div style={styles.adminUserDialogTitleName}>
                            <span style={styles.adminUserName}>Group Name</span>
                            <TextField style={styles.adminUserInputField} />
                          </div>
                          <Button
                            style={styles.adminUserBtn}
                            size={"large"}
                            variant="contained"
                          >
                            <span
                              onClick={handleClose}
                              style={styles.adminUserBtnTxt}
                            >
                              {T("Create_Group")}
                            </span>
                          </Button>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          style={styles.adminUserCancelBtnTxt}
                          onClick={handleClose}
                        >
                          {T("Cancel")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Paper>
                </Grid>
                {/* <Grid item xs={12}>
                <Paper
                  sx={{ p: 2, height:300, display: "flex", flexDirection: "column", backgroundColor:'gray' }}
                ></Paper>
              </Grid> */}
              </Grid>
            )}
            {/* Admin Add table ends */}

            {/* Pending Posts starts*/}
            {selectedItem === "PendingPosts" && (
              <Grid>
                <Grid>
                  <Paper
                    sx={{
                      p: 2,
                      m: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PendingPosts />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {/*  */}
            {selectedItem === "Comments" && (
              <Grid>
                <Grid>
                  <Paper
                    sx={{
                      p: 2,
                      m: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PendingComments />
                  </Paper>
                </Grid>
              </Grid>
            )}
            {selectedItem === "Pool" && (
              <TableContainer component={Paper}>
                <LoadingModal
                  visible={loader}
                  handleClose={() => {}}
                />
                <div style={{ height: 20 }}></div>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>{T("Suggestions")}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => getSuggestion()}>
                          {T("Refresh")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ShowSuggestionsList
                      items={suggestions}
                      onClickItem={() => {}}
                    />
                    {pagination.next_page > 0 ? (
                      <div
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Button onClick={() => handleMore()}>
                          <img
                            src={downarrow}
                            alt=""
                            style={{ width: 20, height: 20, margin: 15 }}
                          />
                        </Button>
                      </div>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {/* Pending Posts ends */}
          </Container>
        </Box>
      </Box>
      <Snackbar
        open={snake !== ""}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnake("")}
      >
        <Alert
          onClose={() => setSnake("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snake}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
