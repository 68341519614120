import { Box, Button, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../common/colors";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventCalendar from "../../components/GreenCity/EventCalender";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CurrencyYenSharpIcon from "@mui/icons-material/CurrencyYenSharp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "../styles";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import uploadFiles from "../../util/upload-multiple-file";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import TableComponent from "../../components/GreenCity/TableComponent";
import { useAppDispatch } from "../../redux/store";
import { clearGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import LoadingModal from "../../components/LoadingModal";
import DisclosureModal from "../../components/Models/GreenCity/DisclosureModal";
import PIIConfirmation from "../../components/Models/GreenCity/PIIConfirmation";
import PIIViewInfo from "../../components/Models/GreenCity/PIIViewInfo";
import MessageModal from "../../components/Models/GreenCity/MessageModal";
import appServices from "../../services/app-services/appServices";
import dayjs from "dayjs";
import LeaveGroupConfirmation from "../../components/Models/GreenCity/LeaveGroupConfirmation";
import { isUnderEighteen } from "../../util/utils";

const defaultImage = require("../../assets/images/default.png");

function GroupInfoReview(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { invitionlink } = location.state || {};
  // console.log("invitionlink", invitionlink);
  const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  // console.log("user", user);

  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>();
  const [groupMainImage, setGroupMainImage] = useState<any>();
  const [openDisclosure, setOpenDisclosure] = useState(false);
  const [openPIIConfiramtion, setopenPIIConfiramtion] = useState(false);
  const [viewPII, setViewPII] = useState(false);
  const [message, setMessage] = useState("");
  const [isMember, setIsMember] = useState(true);
  const [member, setMemeber] = useState<any>({});
  const [isInvited, setInvited] = useState<any>(false);
  const [posts, setPosts] = useState([]);

  const getEvents = async () => {
    try {
      setLoading(true);

      const response = await appServices.getGroupEvents(groupInfo.id);
      const datesArray = response.posts
        .filter((post: { status: string }) => post.status !== "Cancelled")
        .flatMap((post: { post_events: any[] }) =>
          post.post_events.map((event) => ({
            title: event.title,
            start: event.start_date,
            end: dayjs(event?.end_date).add(1, "day").format("YYYY-MM-DD"),
          }))
        );

      setPosts(response.posts);
      setDates(datesArray);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log(groupInfo);
    if (groupInfo.groupImage) {
      const icon = groupInfo?.groupImage instanceof File ? URL.createObjectURL(groupInfo.groupImage) : `${groupInfo?.groupImage}` || defaultImage;

      setImage(icon); // Set the image URL
    } else {
      setImage(defaultImage);
    }
    if (groupInfo.groupMainImage) {
      const groupMainImage = groupInfo?.groupMainImage instanceof File ? URL.createObjectURL(groupInfo.groupMainImage) : `${groupInfo?.groupMainImage}` || defaultImage;
      setGroupMainImage(groupMainImage);
    } else {
      setGroupMainImage(defaultImage);
    }

    checkIsMemeber();
    getEvents();
    // eslint-disable-next-line
  }, [groupInfo]);

  const checkIsMemeber = async () => {
    if (groupInfo.id && groupInfo?.user?.id !== user?.id) {
      // console.log(groupInfo);
      // console.log(groupInfo.id && groupInfo?.user?.id !== user?.id);
      const res = await gcServices.checkIsMembership(user?.id, groupInfo?.id);
      setMemeber(res?.member_group_users[0]);
      // console.log("...", res?.member_group_users);
      if (res?.member_group_users.length === 0) {
        setIsMember(false);
      } else if (res?.member_group_users[0].status === "invited") {
        // console.log("status", res?.member_group_users);
        setInvited(true);
      } else {
        setInvited(false);
        setIsMember(true);
      }
    }
  };

  const createImageObject = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          name: file.name,
          file: file,
          imageSrc: reader.result, // Base64 representation of the file
          path: undefined, // Add your path logic if needed
          _destroy: "0", // Default value as you mentioned
        });
      };
      reader.onerror = () => {
        reject(new Error("Failed to convert file to base64"));
      };
      reader.readAsDataURL(file); // Convert the file to base64
    });
  };

  const createImageArray = async (files: any) => {
    const imageObjects = await Promise.all(
      files?.map((file: any) => createImageObject(file)) // Convert each file into the desired format
    );
    return imageObjects;
  };

  const handleImageProcessing = async () => {
    if (groupInfo) {
      // Array of files or URLs
      let files: any = [];

      if (groupInfo?.groupImage) files.push(groupInfo?.groupImage);

      if (groupInfo?.groupMainImage) files.push(groupInfo?.groupMainImage);

      // const files = [
      //   groupInfo?.groupImage, // Could be File or URL
      //   groupInfo?.groupMainImage, // Could be File or URL
      // ];
      // console.log(files);

      // Separate Files and URLs
      const fileObjects = files.filter((file: any) => typeof file !== "string"); // Only File objects
      // console.log(fileObjects);
      const urlStrings = files.filter((file: any) => typeof file === "string"); // Only URLs (strings)

      // console.log(fileObjects);
      const fileFile = await createImageArray(fileObjects);
      // await Promise.all(
      //   fileObjects.map(async (file) => await createImageArray([file]))
      // );

      // If fileFile is empty, set it to an empty array
      const validFileFile = fileFile.length > 0 ? fileFile : [];

      // Process URL strings
      const splitted = urlStrings.map((str: any) => {
        return { path: str.split("images")[1] };
      });

      // If splitted is empty, set it to an empty array
      const validSplitted = splitted.length > 0 ? splitted : [];

      // Upload fileObjects (if any) and get their URLs
      const uploadedUrls = validFileFile.length > 0 ? await uploadFiles.uploadMultipleImages(validFileFile) : [];

      // If uploadedUrls is empty, set it to an empty array
      const validUploadedUrls = uploadedUrls.length > 0 ? uploadedUrls : [];

      // Combine uploaded URLs with existing URLs
      const allUrls = [...validUploadedUrls, ...validSplitted];

      // console.log("All Image URLs:", allUrls);
      return allUrls.length > 0 ? allUrls : []; // Return all URLs or an empty array if none
    } else {
      // console.log("here", groupInfo);
    }
  };

  const handleGroupJoin = async (group: any) => {
    try {
      setLoading(true);
      if (group.groupApprovalRequired || invitionlink) {
        // console.log("ar", group.approvalRequired);
        await gcServices.joinGroup(user?.id, group.id, "pending", "waiting-approval");
        setMessage("申し込みが完了しました。\nグループ管理者が承認するまでお待ちください。\n 場合によっては参加ができない場合もございます。");
      } else if (group.isPaid) {
        // console.log("paid", group.isPaid);
        await gcServices.joinGroup(user?.id, group.id, "unpaid_approved");
        setMessage("グループの参加には入会金が必要になります。");
      } else if (group.piiRequired === "YES") {
        // console.log('group.piiRequired === "YES"', group.piiRequired === "YES");
        await gcServices.joinGroup(user?.id, group.id, "approved");
        setMessage("参加が完了しました。\nマイページのグループから確認してください。");
      } else {
        // console.log("else");
        await gcServices.joinGroup(user?.id, group.id, "approved");
        setMessage("参加が完了しました。\nマイページのグループから確認してください。");
      }

      if (group.piiRequired === "YES") {
      } else if (group.approvalRequired) {
      }
    } catch (error) {
      setLoading(false);
      setMessage("Error");
    } finally {
      await checkIsMemeber();
      setLoading(false);
    }
  };

  const acceptInvited = async () => {
    try {
      setLoading(true);
      if (groupInfo.groupApprovalRequired || invitionlink) {
        // console.log("ar", groupInfo.approvalRequired);
        // await gcServices.joinGroup(user?.id, groupInfo.id, "pending",'waiting-approval');
        const body = { role: "waiting-approval", status: "pending" };
        await gcServices.updateMemberAccept(member?.id, body);
        setMessage("申し込みが完了しました。\nグループ管理者が承認するまでお待ちください。\n 場合によっては参加ができない場合もございます。");
      } else if (groupInfo.isPaid) {
        // console.log(member);
        setInvited(false);
        const body = { role: "member", status: "unpaid_approved" };
        await gcServices.updateMemberAccept(member?.id, body);
        setMessage("グループの参加には入会金が必要になります。");
      } else {
        // console.log(member);
        const body = { role: "member", status: "approved" };
        // console.log(body);
        setInvited(false);
        await gcServices.updateMemberAccept(member?.id, body);
        setMessage("参加が完了しました。\nマイページのグループから確認してください。");
      }
    } catch (error) {
      setLoading(false);
      setMessage("Error");
    } finally {
      await checkIsMemeber();
      setLoading(false);
    }
  };

  const getSelectedEvent = (date: any) => {
    const isMember = groupInfo?.memberGroupUsers.some((member: any) => {
      return member?.id === user?.id || groupInfo?.owner?.id === user?.id;
    });

    if (!isMember) return;

    const filteredPosts = posts.filter((post: any) =>
      post.post_events.some((event: any) => {
        return event.start_date === date.start && date.end === dayjs(event.end_date).add(1, "day").format("YYYY-MM-DD");
      })
    );

    navigate("/calenderevent", { state: { event: filteredPosts } });
  };

  const handleDisclosuer = (group: any) => {
    if (group.piiRequired === "YES") {
      setopenPIIConfiramtion(true);
    } else {
      handleGroupJoin(group);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const allImages = (await handleImageProcessing()) || [];
      const member_group_activity_values_attributes = [
        {
          member_group_activity_id: 1,
          activity_value: groupInfo?.groupAnnouncements[0].value?.trim() || null,
        },
        {
          member_group_activity_id: 2,
          activity_value: groupInfo?.groupAnnouncements[1].value?.trim() || null,
        },
        {
          member_group_activity_id: 8,
          activity_value: groupInfo?.groupAnnouncements[2].value?.trim() || null,
        },
        {
          member_group_activity_id: 4,
          activity_value: groupInfo?.groupAnnouncements[3].value?.trim() || null,
        },
        {
          member_group_activity_id: 5,
          activity_value: groupInfo?.groupAnnouncements[4].value?.trim() || null,
        },
      ].filter((item) => item.activity_value);

      var body = {
        user_id: user.id,
        is_under_eighteen:isUnderEighteen(user?.dob),
        name: groupInfo?.groupName,
        group_icon: allImages[0]?.path || "",
        banner_image: allImages[1]?.path || "",
        group_type: groupInfo?.groupIsPublic ? "Public" : "Private",
        personal_information_needed: groupInfo?.piiRequired,
        membership_option_id: 2,
        participants_count: groupInfo?.groupMaxMembers,
        description: groupInfo?.groupDesc,
        social_links: groupInfo?.groupWebURLs,
        approval_required: groupInfo?.groupApprovalRequired,
        is_paid: groupInfo?.groupMemberShipFeeAmount ? true : false, // need.
        joining_fee_amount: groupInfo?.groupMemberShipFeeAmount,
        payment_type: groupInfo?.groupPaymentAutomatic ? "Automatic" : "Manual",
        membership_fee_deduction_date: "",
        fee_type: groupInfo?.isMonthly ? "monthly" : "yearly",
        fee_amount: groupInfo?.groupPaymentField,
        fee_explanation: groupInfo?.groupPaymentDescription,
        member_group_activity_values_attributes: member_group_activity_values_attributes,
      };

      try {
        // console.log("group", body);
        const res = groupInfo?.edit ? await gcServices.editGroup(groupInfo.id, body) : await gcServices.createGroup(body);
        // console.log(res);
        if (res?.data?.success) {
          dispatch(clearGroupInfo());
          setLoading(false);
          setTimeout(() => {
            navigate("/myfarm");
          }, 200);
        }
      } catch (error: any) {
        setLoading(false);
        setMessage(error?.response?.data?.message?.[0] ||   error?.message);
      }
    } catch (error:any) {
      setLoading(false);
      setMessage(error?.response?.data?.message?.[0] ||   error?.message);
    }
  };

  // console.log(groupInfo?.id ? "owner" + groupInfo?.user : user);

  const renderTextWithLinks = (text: string) => {
    // Regular expression to find URLs in the text
    const urlRegex = /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    // Replace URLs with anchor tags
    const replacedText = text.replace(urlRegex, (url) => {
      // Exclude cases like "1.pakistan" or "1.pakistan.com"
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        // Prepend "https://" if the URL doesn't have a protocol specified
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" >${url} </a>`;
      } else {
        return url;
      }
    });

    // Replace newline characters with HTML line breaks
    return replacedText.replace(/\n/g, "<br>");
  };

  if (!groupInfo) return <></>;

  return (
    <div style={{ paddingTop: 100 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} handleClose={() => {}} />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 16,
          borderBottomColor: colors.farm_gray15,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            style={{
              height: 32,
              width: 32,
              borderRadius: 18,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: colors.farm_green500,
            }}
            src={image || defaultImage}
          />
          <Typography style={{ fontSize: 14, fontWeight: 600 }}>&nbsp;{groupInfo.groupName}</Typography>
        </div>
        {!groupInfo.edit && groupInfo?.user?.id === user?.id && <Typography onClick={() => navigate("/creategroupstep1")}>編集する</Typography>}
      </Box>

      <Box style={{ padding: 16, paddingTop: 4 }}>
        <Typography>
          {groupInfo?.groupIsPublic === true || groupInfo?.groupIsPublic === "Public" ? "このグループは公開グループです。" : "このグループは非公開グループです。"}
        </Typography>
        <img
          alt=""
          style={{
            marginTop: 4,
            height: 220,
            width: "100%",
          }}
          src={groupMainImage}
        />

        {groupInfo?.owner?.id === user?.id && (
          <Box style={{ display: "flex", justifyContent: "flex-end", margin: 2 }}>
            <Typography style={{ fontSize: 10 }}>
              {groupInfo.owner.id === user.id && <>あなたはこのグループの「リーダー」です。</>}
              {groupInfo?.role === "sub-leader" && <>あなたはこのグループの「サブリーダー」です。</>}
              {groupInfo?.role === "finance" && <>あなたはこのグループの「会計」です。</>}
              {groupInfo?.role === "member" && <>あなたはこのグループの「メンバー」です。</>}
              <CheckCircleOutlineIcon style={{ color: colors.farm_green500 }} />
            </Typography>
          </Box>
        )}
        <Box>
          <Typography>{groupInfo?.groupDesc}</Typography>
        </Box>
        <Typography
          style={{
            borderBottomColor: colors.farm_green150,
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            fontSize: 14,
            marginBottom: 6,
            marginTop: 16,
          }}
        >
          <CalendarMonthIcon />
          イベントカレンダー
        </Typography>
        <Box>
          <EventCalendar getSelectedEvent={getSelectedEvent} dates={dates} />
        </Box>
        <Box>
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
              marginBottom: 6,
              marginTop: 16,
            }}
          >
            <CampaignOutlinedIcon style={{ transform: "rotate(-30deg)" }} />
            &nbsp;グループの主な活動内容
          </Typography>

          {groupInfo?.groupAnnouncements[0].value && <Typography>{"・" + groupInfo?.groupAnnouncements[0].value}</Typography>}
          {groupInfo?.groupAnnouncements[1].value && <Typography>{"・" + groupInfo?.groupAnnouncements[1].value}</Typography>}
          {groupInfo?.groupAnnouncements[2].value && <Typography>{"・" + groupInfo?.groupAnnouncements[2].value}</Typography>}
          {groupInfo?.groupAnnouncements[3].value && <Typography>{"・" + groupInfo?.groupAnnouncements[3].value}</Typography>}
          {groupInfo?.groupAnnouncements[4].value && <Typography>{"・" + groupInfo?.groupAnnouncements[4].value}</Typography>}

          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
              marginBottom: 6,
              marginTop: 16,
            }}
          >
            &nbsp;WEBサイトなどのURL
          </Typography>
          <div>
            <p
              style={{
                userSelect: "text",
                fontSize: 16,
                fontFamily: "mplus-1c-regular",
                wordWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{
                __html: renderTextWithLinks(groupInfo?.groupWebURLs),
              }}
              onClick={(event) => {
                const target = event.target as HTMLElement;
                if (target.tagName === "A") {
                  event.preventDefault();
                  const url = (target as HTMLAnchorElement).href;
                  // console.log("Link clicked:", url);
                  // You can add additional logic here, such as opening the URL in a new tab/window
                  //window.open(url, "_system");
                  try {
                    const messageData = {
                      url: url,
                      condition: true,
                    };
                    window.parent.postMessage({ type: "url", data: messageData }, "*");
                  } catch (error) {}
                }
              }}
            ></p>
          </div>
          {/* <Typography>{groupInfo?.groupWebURLs}</Typography> */}
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
              marginBottom: 6,
              marginTop: 16,
            }}
          >
            <PeopleAltOutlinedIcon />
            &nbsp;グループへの参加について
          </Typography>
          <Typography>
            {!groupInfo?.groupApprovalRequired
              ? groupInfo.groupIsPublic === true || groupInfo.groupIsPublic === "Public"
                ? "このグループは誰でも参加できます。"
                : "このグループは招待されたユーザーのみ参加できます"
              : "このグループに参加するには承認が必要です。"}
          </Typography>
          {(groupInfo?.piiRequired === true || groupInfo?.piiRequired === "YES") && (
            <>
              <Typography>氏名の提供が必要です。</Typography>
            </>
          )}
          {/* {groupInfo?.groupIsLimited && (
            <>
              <Typography
                style={{
                  borderBottomColor: colors.farm_green150,
                  borderBottomWidth: 2,
                  borderBottomStyle: "solid",
                  fontSize: 14,
                  marginBottom: 6,
                  marginTop: 16,
                }}
              >
                <PeopleAltOutlinedIcon />
                &nbsp;参加券人数について
              </Typography>
              <Typography>以下の人数を供していま</Typography>
            </>
          )} */}
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
              marginBottom: 6,
              marginTop: 16,
            }}
          >
            <CurrencyYenSharpIcon
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                width: 30,
                height: 30,
                borderRadius: 15,
                padding: 2,
                margin: 2,
              }}
            />
            &nbsp;会費について
          </Typography>
          <Typography>団体参加費：{groupInfo.groupMemberShipFeeAmount || 0}円</Typography>
          {groupInfo?.groupPaymentField !== "" && groupInfo?.groupPaymentField !== 0 && (
            <Typography>{groupInfo?.isMonthly ? "このグループは月会費があります。" : "このグループには年会費がかかります。"}</Typography>
          )}

          {groupInfo?.groupPaymentField !== "" && groupInfo?.groupPaymentField !== 0 && (
            <Typography>
              {groupInfo?.isMonthly ? "月会費" : "年会費"}：{groupInfo?.groupPaymentField}円
            </Typography>
          )}
          <Typography>{groupInfo.groupPaymentDescription}</Typography>
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
              marginBottom: 6,
              marginTop: 16,
            }}
          >
            <FeedOutlinedIcon />
            &nbsp;グループ詳細
          </Typography>
          <TableComponent
            user={groupInfo?.id ? groupInfo?.user : user}
            data={[
              { col1: "団体名", col2: groupInfo?.groupName },
              {
                col1: "会費",
                col2:
                  (groupInfo?.groupMemberShipFeeAmount === 0 || groupInfo?.groupMemberShipFeeAmount === "") &&
                  (groupInfo?.groupPaymentField === 0 || groupInfo?.groupPaymentField === "")
                    ? "なし"
                    : "あり",
              },
              {
                col1: "メンバー数",
                col2: groupInfo?.memberGroupUsers.length || 0 + "人",
              },
              { col1: "投稿数", col2: groupInfo?.posts_count || 0 },
            ]}
          />
        </Box>
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!groupInfo?.id && !groupInfo?.edit && (
          <Button
            onClick={() => {
              handleSave();
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>完了する </span>
          </Button>
        )}
        {groupInfo?.id && groupInfo?.edit && (
          <Button
            onClick={() => {
              handleSave();
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>完了する </span>
          </Button>
        )}
      </Box>

      {/* <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(groupInfo?.id && isMember&&!isInvited&& groupInfo?.owner?.id!==user?.id) && (
          <Button
            onClick={() => {
              
              navigate("/viewGroupPost", {
                state: {
                  post_type: 'public',
                },
              });
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>View Posts</span>
          </Button>
        )}
      </Box> */}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {groupInfo?.id && (
          <Button
            onClick={() => {
              navigate("/viewGroupPost", {
                state: {
                  post_type: "public",
                },
              });
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>投稿を見る</span>
          </Button>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!isMember && groupInfo.id && groupInfo?.owner?.id !== user?.id && (
          <Button
            disabled={user?.id_kind !== '1'}
            onClick={() => {
              setOpenDisclosure(true);
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 280,
              color: "white",
              opacity: user?.id_kind !== '1' ? 0.7 : 1,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>グループに参加する</span>
          </Button>
        )}
        {isInvited && (
          <Button
          disabled={user?.id_kind !== '1'}
            onClick={() => {
              acceptInvited();
            }}
            style={{
              ...styles.introSliderBtn,
              marginTop: 40,
              width: 220,
            }}
            size={"large"}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <span style={{ paddingRight: 30, paddingLeft: 40 }}>招待を承諾</span>
          </Button>
        )}

        <DisclosureModal
          open={openDisclosure}
          onClose={() => {
            setOpenDisclosure(false);
          }}
          onContinue={() => {
            handleDisclosuer(groupInfo);
          }}
        />

        <PIIConfirmation
          open={openPIIConfiramtion}
          onClose={() => {
            setopenPIIConfiramtion(false);
          }}
          onContinue={() => {
            setopenPIIConfiramtion(false);
            setViewPII(true);
          }}
        />

        <PIIViewInfo
          groupName={groupInfo.groupName}
          groupImage={image}
          open={viewPII}
          onClose={() => {
            setViewPII(false);
          }}
          onContinue={() => {
            setViewPII(false);
            handleGroupJoin(groupInfo);
          }}
        />

        {groupInfo?.groupApprovalRequired || invitionlink ? (
          <LeaveGroupConfirmation
            showButton={groupInfo?.groupApprovalRequired}
            open={message?.length > 0}
            message1={message}
            yesText={"閉じる"}
            noText={"dontshowno"}
            onClose={() => setMessage("")}
            onContinue={() => setMessage("")}
          />
        ) : (
          <MessageModal
            showButton={groupInfo?.groupApprovalRequired}
            open={message?.length > 0}
            message={message}
            onClose={() => setMessage("")}
            onContinue={() => {}}
          />
        )}
      </Box>
      <Box style={{ height: 100 }}></Box>
    </div>
  );
}

export default GroupInfoReview;
