import { Avatar, Box, Button, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { colors } from "../../../common/colors";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import LeaveGroupConfirmation from "../../../components/Models/GreenCity/LeaveGroupConfirmation";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import LoadingModal from "../../../components/LoadingModal";
const KickUser = () => {
  const navigate = useNavigate();
  const targetUser = useLocation().state;
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [check1, setCheck1] = useState("Yes");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);

  const onDeleteUser = async () => {
    setLoading(true);
    setConfirm(false);
    try {
      await gcServices.deleteMember(targetUser.id);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={styles.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={styles.rowMargin}>
          <Avatar src={groupInfo.groupImage} sx={{ ...styles.avatar, width: "30px" }} />
          <Typography>{groupInfo.groupName}</Typography>
        </Box>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.rowMargin}>
          <Avatar src={"data:image/jpeg;base64," + targetUser?.user?.image} alt={"profile"} sx={{ ...styles.avatar, width: "50px", height: "50px" }} />
          <Typography sx={{ fontSize: "18px" }}>{targetUser?.user?.nickname}</Typography>
        </Box>
        <Typography sx={{ ...styles.titleSeprator }}>このユーザーを退会させる理由を教えてください</Typography>
        <Box sx={{ flexDirection: "column", display: "flex" }}>
          <RadioGroup
            value={check1}
            onChange={(value) => setCheck1(value.target.value)}
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
          >
            <FormControlLabel value="Yes" control={<Radio />} label={"不快なチャットやプライベート投稿を送ってくる。 "} />
            <FormControlLabel value="other" control={<Radio />} label={"その他"} />
          </RadioGroup>

          <TextField
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            sx={styles.textInput}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            placeholder="差し支えなければ通報する理由をお書きください。"
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
          <Button
            onClick={() => setConfirm(true)}
            disabled={check1 === "other" && text.trim() === ""}
            style={styles.button}
            size={"large"}
            variant="contained"
            endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            <span style={{ minWidth: 200 }}>{"退会させる"}</span>
          </Button>
        </Box>
      </Box>
      <LoadingModal visible={loading} />
      <LeaveGroupConfirmation
        message1="ご注意"
        message2="本当にユーザーを退会させてよろしいですか？"
        message3="一度退会させると元に戻せません。"
        yesText="退会させる"
        noText="戻る"
        open={confirm}
        onClose={() => setConfirm(false)}
        onContinue={onDeleteUser}
      />
      <MessageModal open={success} onClose={() => navigate(-1)} showButton={false} message="退会させました。" />
    </Box>
  );
};

const styles = {
  headerBox: {
    padding: "1px 16px",
    paddingTop: "90px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  avatar: {
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    objectFit: "cover",
    overFlow: "hidden",
    backgroundColor: "#E8E8E8",
  },

  container: {
    padding: "0px 20px",
  },
  titleSeprator: {
    borderBottomColor: colors.farm_green500,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    fontSize: 14,
    fontFamily: "mplus-1c-regular",
    marginTop: "10px",
  },
  rowMargin: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
  },
  button: {
    alignSelf: "center",
    borderRadius: 20,
    backgroundColor: colors.farm_blue200,
    margin: "0 auto",
  },
  textInput: {
    width: "100%",
    backgroundColor: "white",
    marginTop: "10px",
  },
};
export default KickUser;
