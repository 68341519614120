import { Button, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DropDown from "../components/DropDown";
import "../App.css";
import { styles } from "./styles";
import LoadingModal from "../components/LoadingModal";

import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { getOpinionPosts, removeOpinionPost } from "../redux/Reducers/postReducer";
import { T } from "react-translator-component";
import { colors } from "../common/colors";
import moment from "moment";
import VerticalListP2 from "../components/lists/VerticalListP2";
import appServices from "../services/app-services/appServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import InfoMessage from "../components/InfoMessage";
import MessageModal from "../components/Models/GreenCity/MessageModal";
import { setFacilitatorFilters } from "../redux/Reducers/filtersReducer";

export default function FacilitatorList() {
  const [filteredPost, setFilteredPosts] = useState([]);
  // const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState("desc");
  const { selectedSession, facilitatorSort } = useSelector((state: any) => state.filters);

  const [eventsPost, setEventsPosts] = useState<any>([]);
  // const [current_page, setCurrentPage] = useState<any>(0);
  // const { groups } = useSelector((state: any) => state.groups);
  const { opinionPosts, opinionPostsPagination, isLoadingOpinionPosts } = useSelector((state: any) => state.posts);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setFilteredPosts([]);
    setEventsPosts([]);
    setSort(facilitatorSort);
    setSelectedSession(selectedSession);
    apiCalls();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCalls = async () => {
    let data = { page: 1, order: order };
    await dispatch(getOpinionPosts(data));
  };
  // useEffect(() => {
  //   setCurrentPage(opinionPostsPagination?.current_page);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [opinionPostsPagination]);

  useEffect(() => {
    if (opinionPostsPagination?.current_page > 1) {
      const newItems = [...eventsPost, ...opinionPosts];
      // console.log(newItems);
      if (newItems) setEventsPosts(newItems);
    } else {
      setEventsPosts(opinionPosts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opinionPosts]);

  const [session, setSelectedSession] = useState<any>({
    value: "",
    name: "Session",
  });

  const loadMore = () => {
    if (opinionPostsPagination?.next_page > 0) {
      let data = { page: opinionPostsPagination?.next_page, order: order };
      dispatch(getOpinionPosts(data));
    }
  };

  const [sort, setSort] = useState<any>({
    value: "",
    name: "Sort",
  });

  const handleSelectSession = (event: SelectChangeEvent) => {
    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Group" : event.target.name,
    };
    dispatch(setFacilitatorFilters({ selectedSession: v, facilitatorSort: sort }));
    setSelectedSession(v);
  };

  const handleSort = (event: SelectChangeEvent) => {
    // console.log(event.target);

    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Sort" : event.target.name,
    };
    // console.log(v);
    if (v.value.toString() === "2") {
      setOrder("asc");
      let data = { page: 1, order: "asc" };
      dispatch(getOpinionPosts(data));
    } else if (v.value.toString() === "1") {
      setOrder("desc");
      let data = { page: 1, order: "desc" };
      dispatch(getOpinionPosts(data));
    }
    dispatch(setFacilitatorFilters({ selectedSession: session, facilitatorSort: v }));
    setSort(v);
  };
  const [message, setMessage] = useState("");
  const handleReport = async (post_id: number, status: any) => {
    try {
      // setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await appServices.acceptRejectPost(post_id, status);
      // console.log(response, "responseeeeeee");
      setMessage("報告しました。");
      // setMessage('報告の問題');
      setTimeout(() => {
        dispatch(removeOpinionPost(post_id));
      }, 1000);
    } catch (error) {
      setMessage("報告の問題");
      // setLoading(false);
    }
  };
  const [likeLoading, setLikeLoading] = useState(false);
  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        let data = { page: opinionPostsPagination.current_page, order: order };
        await dispatch(getOpinionPosts(data));

        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
    }

    // console.log('like')
  };
  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const renderList = useMemo(() => {
    if (filteredPost?.length === 0) return;
    if (sort.value === 4) {
      const temp = [...filteredPost].sort((a: any, b: any) => (a.comments?.length > b.comments?.length ? 1 : -1));
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (sort.value === 3) {
      const temp = [...filteredPost].sort((a: any, b: any) => (a.comments?.length < b.comments?.length ? 1 : -1));
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (sort.value === 2) {
      // const temp = [...filteredPost].sort((a: any, b: any) =>
      //   a.id > b.id ? 1 : -1
      // );
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={filteredPost}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (sort.value === 1) {
      // const temp = [...filteredPost].sort((a: any, b: any) =>
      //   a.id < b.id ? 1 : -1
      // );
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={filteredPost}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (session.value === 1) {
      const temp = eventsPost.filter((item: any) => moment(item?.expiry_date).isAfter(moment().format()));
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (session.value === 2) {
      const temp = eventsPost.filter((item: any) => moment(item?.expiry_date).isSameOrBefore(moment().format()));
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else {
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={opinionPostsPagination?.current_page}
          order={order}
          items={filteredPost}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    }
    // eslint-disable-next-line
  }, [filteredPost, sort, session, eventsPost]);

  useEffect(() => {
    if (session.value === 1) {
      const temp = eventsPost.filter((item: any) => moment(item?.expiry_date).isAfter(moment().format()));
      setFilteredPosts(temp);
    } else if (session.value === 2) {
      const temp = eventsPost.filter((item: any) => moment(item?.expiry_date).isSameOrBefore(moment().format()));
      setFilteredPosts(temp);
    } else {
      setFilteredPosts(eventsPost);
    }
  }, [session, eventsPost]);

  useEffect(() => {
    if (eventsPost?.length !== 0) setFilteredPosts(eventsPost);
  }, [eventsPost]);
  const navigate = useNavigate();
  const gotoPost = () => {
    navigate("/suggestion");
  };

  return (
    <div style={{ ...styles.communityContainer, marginTop: 80 }}>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 16,
          display: "flex",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 16,
            display: "flex",
            marginBottom: 14,
          }}
        >
          <Button
            onClick={() => gotoPost()}
            // disabled={disabled}
            style={{
              alignSelf: "center",
              borderRadius: 20,
              backgroundColor: colors.farm_blue200,
            }}
            size={"large"}
            variant="contained"
            endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            <span style={{ minWidth: 200 }}>意見箱に意見を入れる</span>
          </Button>
        </div>
      </div>

      <p
        style={{
          ...styles.communiTitleDescrip,
          textAlign: "center",

          padding: 2,
        }}
      >
        意見箱に寄せられた取り上げてほしい内容や議論をしたい内容をハンドラーがピックアップしました。気になる内容がありましたら積極的に意見交換をしてみましょう。
      </p>

      <DropDown
        title={T("Sort")}
        value={sort.value}
        options={[
          { value: 1, name: T("New") },
          { value: 2, name: T("Old") },
          { value: 3, name: "コメントが多い順" },
          { value: 4, name: "コメントが少ない順" },
        ]}
        onChange={handleSort}
      />

      <DropDown
        title={"開催中"}
        value={session.value}
        options={[
          { value: 1, name: T("In_Session") },
          { value: 2, name: T("End_of_page") },
        ]}
        onChange={handleSelectSession}
      />

      {renderList}
      {/* <p style={{ ...styles.communiNormalTxt, textAlign: "center" }}>
        {T("dead_linetxt")}
      </p> */}
      <LoadingModal visible={isLoadingOpinionPosts} handleClose={() => {}} />
      {opinionPostsPagination?.next_page > 0 && (
        <Stack sx={{ width: "100%", color: "grey.500", justifyContent: "center" }} spacing={2}>
          <Button onClick={loadMore} size="small">
            {T("Load_More")}
          </Button>
        </Stack>
      )}
      <InfoMessage show={true} message="some message" />
      <MessageModal open={message !== ""} message={message} onClose={() => setMessage("")} />
    </div>
  );
}
