import { Avatar, Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import Separator from "../../../components/Separator";
import profile from "../../../assets/images/profile_img.png";
import { styles } from "../../styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import LoadingModal from "../../../components/LoadingModal";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import { isUnderEighteen } from "../../../util/utils";
import { colors } from "../../../common/colors";

function GroupRoles(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const { groupInfo, member, counts } = location?.state || {};
  const [role, setRole] = useState(member?.role || "member");
  const [loading, setLoading] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState("");
  const [isUnderEighteenAge,setUnderEighteen]=useState(true);

 
  useEffect(() => {
    setRole(member?.role);
    // console.log(member)
    // console.log(member?.user?.dob)
    let temp=isUnderEighteen(member?.user?.dob);
    setUnderEighteen(temp)
  }, [groupInfo, member]);

  const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const handleSave = async () => {
    if (role === member.role) {
      setShowMessageModal("現在の役割と同じ役割が選択されています。役割を変更する場合は、異なる役割を選択してください。");
      return;
    }
    if (role === "finance" && counts.finance >= 5) {
      setShowMessageModal("サブリーダー・会計はそれぞれ最大5名まで役割の割り当てが可能です。");
      return;
    } else if (role === "sub-leader" && counts.subLeader >= 5) {
      setShowMessageModal("サブリーダー・会計はそれぞれ最大5名まで役割の割り当てが可能です。");
      return;
    }
    try {
      setLoading(true);
      if (member?.id) {
        // console.log(member?.id);
        // console.log(role);
        await gcServices.updateMemberRoleOnly(member?.id, role);
        setShowMessageModal("権限の変更が完了しました");
        setLoading(false);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      setShowMessageModal("権限の変更に失敗しました");
    }
  };

  const radioGroupContent = useMemo(() => {
    return (
      <RadioGroup aria-labelledby="payment-radio-buttons-group-label" value={role} onChange={handleRole} name="radio-buttons-group">
        <FormControlLabel
          disabled
          value="leader"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">リーダー</Typography>
              <Typography variant="body2" color="textSecondary">
                グループ内のすべての機能を使用できます。リーダーの変更、グループの脱退はできません。
              </Typography>
              <br />
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
        <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />
        <FormControlLabel
          value="sub-leader"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">サブリーダー</Typography>
              <Typography variant="body2" color="textSecondary">
                グループトップページの修正以外、グループ内のすべての機能を使用できます。
              </Typography>
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
        <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />
        <FormControlLabel
          value="finance"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">会計</Typography>
              <Typography variant="body2" color="textSecondary">
                主にお支払い関係の機能を使用できます。
              </Typography>
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
        <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />
        <FormControlLabel
          value="member"
          control={<Radio sx={{ alignSelf: "flex-start" }} />}
          label={
            <div>
              <Typography variant="body1">役割なし</Typography>
              <Typography variant="body2" color="textSecondary">
                一般のユーザーとなります。
              </Typography>
            </div>
          }
          sx={{ alignItems: "flex-start" }}
        />
      </RadioGroup>
    );
  }, [role]);

  const getRoleJP = (role: string) => {
    if (role === "member") {
      return "メンバー";
    } else if (role === "sub-leader") {
      return "サブリーダー";
    } else if (role === "finance") {
      return "会計";
    } else {
      return "メンバー";
    }
  };

  return (
    <div style={style.headerContainer}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <Box style={style.eventPostingBox}>
        <Box component="div" style={style.eventPostingIcon}>
          <Avatar src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`} sx={{ width: 30, height: 30 }} />
        </Box>
        <Typography style={{ fontWeight: "700", marginTop: "5px" }}>
          {groupInfo?.name}
          <span>チャットリスト</span>
        </Typography>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box component="div" style={style.eventPostingIcon}>
          <Avatar src={member?.image !== null ? "data:image/jpeg;base64," + member?.user.image : profile} sx={{ width: 30, height: 30 }} />
        </Box>
        <Typography style={{ fontWeight: "700", marginTop: "5px" }}>{member?.user?.nickname}</Typography>
      </Box>
      <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />

      <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box>
          <Typography style={{ fontSize: 10 }}>本名</Typography>
          <Typography>{member?.name}</Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: 10 }}>役割</Typography>
          <Typography>{getRoleJP(member?.role)}</Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: 10 }}>権限付与日</Typography>
          <Typography>{member?.updated_at?.split("T")[0]}</Typography>
        </Box>
      </Box>

      <Separator sx={{ marginTop: "10px", marginBottom: "10px" }} color="gray" />

      <Box>{radioGroupContent}</Box>
      { isUnderEighteenAge&&<Typography style={{color:colors.farm_gray200, marginTop: 40,textAlign:'center'}}> 18歳未満の年齢役割は変更できません</Typography>}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
     
       
        <Button
        disabled={isUnderEighteenAge}
          onClick={() => {
            handleSave();
          }}
          style={{
            ...styles.introSliderBtn,
            // marginTop: 40,
            width: 220,
          }}
          size={"large"}
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
        >
          <span style={{ paddingRight: 30, paddingLeft: 40 }}>保存</span>
        </Button>
        <Box style={{ height: 100 }}></Box>
      </Box>
      <LoadingModal visible={loading} handleClose={() => {}} />
      <MessageModal message={showMessageModal} open={showMessageModal !== ""} onClose={() => setShowMessageModal("")} showButton={false} key="message" />
    </div>
  );
}

export default GroupRoles;

const style = {
  eventPostingIcon: {
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  headerContainer: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
};
