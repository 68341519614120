import React, { ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Separator from "../../components/Separator";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  isIOS,
  responsive,
  makeArrayUnique,
  defaultPostImages,
  isUnderEighteen,
} from "../../util/utils";
import { ImageItem } from "../CreatePost";
import Carousel from "react-multi-carousel";
import { AsyncImage } from "loadable-image";
import { useSelector } from "react-redux";
import { T } from "react-translator-component";
import appServices from "../../services/app-services/appServices";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import { TagsInput } from "react-tag-input-component";
import uploadFiles from "../../util/upload-multiple-file";
import { useLocation } from "react-router-dom";
import LoadingModal from "../../components/LoadingModal";
import { styles as oldstyles } from "../styles";
import "dayjs/locale/ja";
import { colors } from "../../common/colors";
dayjs.extend(timezone);
dayjs.extend(utc);
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
interface FormSection {
  id?: number;
  inputs: { [key: string]: string };
  free: boolean;
  group: boolean;
}
export type FileItem = {
  name: string | undefined;
  file: any;
  fileSrc: string;
  path: string | undefined;
  _destroy: string | undefined;
};
function CreateNewEvent(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  // console.log(">>>>>><<<<<", groupInfo);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const { event } = location.state || {};
  // console.log("event", event);

  const { user } = useSelector((state: any) => state.userAuth);
  const [loading, setLoading] = React.useState(false);

  const [selectedValue, setSelectedValue] = useState("No");
  const [selectedValueThird, setSelectedValueThird] =
    useState("キャンセルはできるが返金不可");
  const [votingQuestion, setVotingQuestion] = useState("");
  const [votingOption, setVotingOption] = useState("No");
  const [commentsOption, setCommentsOption] = useState(1);
  const [checked, setChecked] = useState(false);
  const [inputFields, setInputFields] = useState([
    { placeholder: "犬派", value: "", _destroy: "" },
    { placeholder: "猫派", value: "", _destroy: "" },
    { placeholder: "両方好き", value: "", _destroy: "" },
  ]);
  const [title, setTitle] = useState("");
  // const [eventStartDate, setEventStartDate] = React.useState<Dayjs | null>(
  //   dayjs(new Date())
  // );
  const [eventStartDate, setEventStartDate] = React.useState<Dayjs | null>(
    dayjs().locale("ja") // Set the locale to Japanese
  );

  // const [eventEndDate, setEventEndDate] = React.useState<Dayjs | null>(
  //   eventStartDate
  // );
  const [eventEndDate, setEventEndDate] = React.useState<Dayjs | null>(
    eventStartDate
  );

  const [eventOverview, setEventOverview] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  // const [applicationStartDate, setApplicationStartDate] =
  //   React.useState<Dayjs | null>(dayjs(new Date()));
  const [applicationStartDate, setApplicationStartDate] =
    useState<Dayjs | null>(
      dayjs().locale("ja") // Default to Japanese locale
    );
  // const [applicationEndDate, setApplicationEndDate] =
  //   React.useState<Dayjs | null>(dayjs(new Date()));
  const [applicationEndDate, setApplicationEndDate] = useState<Dayjs | null>(
    dayjs().locale("ja") // Default to Japanese locale
  );
  const [votingEndDate, setVotingEndDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [venueName, setVenueName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState("");

  const [tags, setTags] = useState<any[]>([]);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [removedImages, setRemovedImage] = useState<ImageItem[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<ImageItem[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<ImageItem[]>([]);
  // ------------------------------------------------------------------------------
  const [files, setFiles] = useState<FileItem[]>([]);
  const [removedFiles, setRemovedFiles] = useState<FileItem[]>([]);
  const [docUploaded, setDocUploaded] = useState<FileItem[]>([]);
  const [docsToUpload, setDocsToUpload] = useState<FileItem[]>([]);

  const [editTags, setEditTags] = useState<any[]>([]);
  const [removeTags, setRemoveTags] = useState<any[]>([]);
  const [finalEditTags, setFinalEditTags] = useState<any>([]);
  const [EventDatehasPassed, setEventDatehasPassed] = useState(false);
  let participationSettings =
    groupInfo.groupIsPublic === "Public"
      ? "誰でも参加できます。"
      : "グループの会員のみ参加できます。";
  const [memberParticipate, setMemberParticipate] = useState<string>(
    participationSettings
  );
  // グループの会員のみ参加できます。
  const [formSections, setFormSections] = useState<FormSection[]>(
    event
      ? []
      : [
          {
            inputs: { name: "", numberOfParticipants: "", FeeAmount: "" },
            free: false,
            group: false,
          },
        ]
  );

  const [errors, setErrors] = useState({
    title: "",
    venueName: "",
    postalCode: "",
    address: "",
    voteOption: "",
    formSection: "",
  });

  // Handler to add a new section
  const handleAddSection = () => {
    setFormSections([
      ...formSections,
      {
        inputs: { name: "", numberOfParticipants: "", FeeAmount: "" },
        free: false,
        group: false,
      },
    ]);
  };

  const getGroupMembers = async () => {
    try {
      const res = await gcServices.getMemberList(groupInfo?.id);
      // console.log(res?.member_group_users);
      const users = res?.member_group_users.map((group: any) => group.user);
      // console.log(">>>>>>", users);
      setSelectedUsers(users);
    } catch (error) {}
  };

  useEffect(() => {
    getGroupMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for input changes
  const handleInputChangeEvent = (index: number, event: any) => {
    const { name, value } = event.target;
    const updatedSections = [...formSections];
    updatedSections[index].inputs[name] = value;
    setFormSections(updatedSections);
  };

  // Handler for radio button changes
  const handleRadioChangeEvent = (
    index: number,
    radioGroup: "free" | "group",
    value: any
  ) => {
    const updatedSections = [...formSections];
    updatedSections[index][radioGroup] = value;
    setFormSections(updatedSections);
  };

  const handleAddField = () => {
    setInputFields([
      ...inputFields,
      { value: "", placeholder: "", _destroy: "" },
    ]);
  };

  const handleInputChange = (index: number, event: any) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
  };

  const handleFileUploadIOS = (event: any) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const totalFiles = files.length + selectedFiles.length;

      if (totalFiles > 5) {
        // setError("画像は5枚まで許可されます");
        // Only take the required number of files to stay within the limit
        selectedFiles.splice(5 - images.length);
      }

      const newFiles = selectedFiles.map((file: any) => {
        let reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e: any) => {
            resolve({
              name: file.name,
              file: file,
              fileSrc: e.target.result,
              path: undefined,
              _destroy: "0",
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newFiles).then((loadedFiles) => {
        setFiles((prevFiles: any) => [...prevFiles, ...loadedFiles]);
      });
    }
  };
  const removeFile = (index: number) => {
    setFiles((prev: FileItem[]) =>
      prev.filter((_: FileItem, i: number) => i !== index)
    );
  };
  const saveRemoveFile = (file: FileItem) => {
    if (file.path !== undefined) {
      const item = file;
      const addDestroy = [...removedFiles, { ...item, _destroy: "1" }];
      // console.log(addDestroy);
      setRemovedFiles(addDestroy);
      // console.log(removedImages);
    }
  };

  useEffect(() => {
    const filesToUpload = files.filter((file) => file.file !== undefined);
    setDocsToUpload(filesToUpload);

    const filesUploaded = files.filter((file) => file.path !== undefined);
    setDocUploaded(filesUploaded);
  }, [files]);

  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Check for empty or invalid data
        if (!event?.data) return;

        try {
          // Extract Base64 data and determine file type
          const base64Data = event?.data.split(",")[1];
          const mimeType = event?.data.split(";")[0]?.split(":")[1];
          const byteCharacters = atob(base64Data);

          // Convert Base64 string to byte array
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob with the detected MIME type
          const blob = new Blob([byteArray], { type: mimeType });
          const timestamp = new Date().toISOString().replace(/:/g, "-");

          // Derive the file extension from the MIME type
          const extension = mimeType.split("/")[1];
          const fileName = `any_${timestamp}.${extension}`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: mimeType });

          // Update state with the new file
          setFiles((prevFiles: any) => [
            ...prevFiles,
            {
              name: fileName,
              file: file,
              fileSrc: event?.data,
              path: undefined,
              _destroy: "0",
            },
          ]);
        } catch (error) {
          console.error("Error processing file:", error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("file_upload", handleMessage);

    return () => {
      // Cleanup the event listener when the component is unmounted
      window.removeEventListener("file_upload", handleMessage);
    };
  }, []);

  const handleImageUploadAndroid = () => {
    try {
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };

  const handleImageUploadIOS = (event: any) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const totalFiles = images.length + selectedFiles.length;

      if (totalFiles > 5) {
        // setErrors("画像は5枚まで許可されます");
        // Only take the required number of files to stay within the limit
        selectedFiles.splice(5 - images.length);
      }

      const newImages = selectedFiles.map((file: any) => {
        let reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e: any) => {
            resolve({
              name: file.name,
              file: file,
              imageSrc: e.target.result,
              path: undefined,
              _destroy: "0",
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((loadedImages) => {
        setImages((prevImages: any) => [...prevImages, ...loadedImages]);
      });
    }
  };
  const removeImage = (index: number) => {
    setImages((prev: ImageItem[]) =>
      prev.filter((_: ImageItem, i: number) => i !== index)
    );
  };

  const handleDeleteTag = (tag: string) => {
    if (event) {
      try {
        const removedTg = event?.post_tags?.filter(
          (item: any) => item.tag === tag
        );
        const removed = [
          ...removeTags,
          { tag: tag, _destroy: "1", id: removedTg[0].id },
        ];

        setRemoveTags(removed);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validateForm2 = () => {
    let valid = true;

    let newErrors = {
      title: "",
      venueName: "",
      postalCode: "",
      address: "",
      voteOption: "",
      formSection: "",
    };
    if (title === "") {
      newErrors.title = "タイトルを入力してください";
      valid = false;
    }
    if (venueName === "") {
      newErrors.venueName = "Please enter venue name";
      valid = false;
    }
    if (postalCode === "") {
      newErrors.postalCode = "Please enter postal code";
      valid = false;
    }
    if (address === "") {
      newErrors.address = "Please enter address";
      valid = false;
    }
    if (votingOption === "Yes") {
      const filledOptions = inputFields.filter(
        (field) => field.value.trim() !== ""
      );

      if (filledOptions.length < 2) {
        newErrors.voteOption = "Enter a minimum of 2 vote options";
        valid = false;
      }
    }
    if (selectedValue === "Yes") {
      formSections.forEach((section) => {
        // console.log(section);

        const { name, FeeAmount, numberOfParticipants } = section.inputs;

        // Check if name is empty (always required)
        if (!name || name?.trim() === "") {
          newErrors.formSection = "Enter complete form details";
          valid = false;
        }

        // Check FeeAmount only if free is false
        if (!section.free && (!FeeAmount || String(FeeAmount).trim() === "")) {
          newErrors.formSection = "Enter Fee Amount or mark it as free";
          valid = false;
        }

        // Check numberOfParticipants only if group is false
        if (
          !section.group &&
          (!numberOfParticipants || String(numberOfParticipants).trim() === "")
        ) {
          newErrors.formSection =
            "Enter Number of Participants or mark it as a group event";
          valid = false;
        }
      });
    }

    // if (acceptVotes == "") {
    //   newErrors.acceptVotes = "投票を選択してください";
    //   valid = false;
    // }
    // if (
    //   acceptVotes == "Yes" &&
    //   voteOptions.some((option) => !option.value || option.value.trim() === "")
    // ) {
    //   newErrors.acceptVotes = "投票内容を入力してください";
    //   valid = false;
    // }

    setErrors(newErrors);
    return valid;
  };

  const submit = async () => {
    if (!validateForm2()) {
      window.scrollTo(0, 0);
      return;
    } else {
      setLoading(true);
      try {
        const allImages = filesToUpload
          ? await uploadFiles.uploadMultipleImages(filesToUpload)
          : [];

        const allFiles = docsToUpload
          ? await uploadFiles.uploadMultipleImages(docsToUpload)
          : [];

        const votesWithTextOnly = inputFields.map((item: any) => {
          if (!item.value?.trim()) {
            // If the value is empty, add "_destroy": "1" to the object
            return { ...item, _destroy: "1" };
          }
          return item; // Keep the object as-is if value is not empty
        });
        const votes = votesWithTextOnly.map((item: any) => ({
          option_text: item.value,
          ...item,
        }));

        const eventsArray = formSections
          .map((section) => {
            const { name, FeeAmount, numberOfParticipants } = section.inputs;

            if (!name || name === "") return undefined;
            return {
              id: section?.id ? section?.id : "",
              name: name,
              number_of_participants: numberOfParticipants,
              fee_amount: FeeAmount,
              free: section.free,
              group: section.group,
            };
          })
          .filter((event) => event !== undefined);

        const privatePostUsersNew = selectedUsers.map((user: any) => ({
          user_id: user.id,
        }));
        const privatePostUsersEdit = event?.post_private_users?.map(
          (user: any) => {
            if (user.user_id === user.id) {
              return { user_id: user.user_id };
            } else {
              return { user_id: user.user_id, id: user.id };
            }
          }
        );

        const threadType =
          memberParticipate === "グループの会員のみ参加できます。" ? "1" : "0";
        const body = {
          post: {
            is_private: threadType,
            post_private_users_attributes:
              threadType === "1"
                ? event?.id
                  ? [...privatePostUsersEdit]
                  : [{ user_id: groupInfo?.owner.id }, ...privatePostUsersNew]
                : null,
            title: title,
            details: eventOverview,
            user_id: user?.id,
            member_group_id: groupInfo?.id,
            group_id: 1,
            category_id: 1,
            post_type: user?.source === "admin" ? "1" : "0",
            moderation_count: 0,
            expiry_date:
              votingOption === "Yes" ? votingEndDate?.format("YYYY-M-D") : "",
            is_anonymous: 1,
            enable_comments: commentsOption,
            enable_participants: votingOption === "Yes" ? true : false,
            number_of_participants: 2,
            post_events_attributes: [
              {
                id: event ? event?.post_events[0]?.id : "",
                title: title,
                description: eventOverview,
                start_date: eventStartDate?.format("YYYY-M-D"),
                // end_date: dayjs(eventEndDate)
                //   .add(1, "day")
                //   .format("YYYY-MM-DD"),
                end_date: dayjs(eventEndDate)
                  .tz(userTimeZone, true)
                  .format("YYYY-MM-DD"),
                venue_name: venueName,
                postal_code: postalCode,
                address: address,
                url: websiteURL,
                member_participation_rule: memberParticipate,
                anyone_can_participate: true,
                application_install_needed: false,
                application_start_date:
                  applicationStartDate?.format("YYYY-M-D"),
                application_end_date: applicationEndDate?.format("YYYY-M-D"),
                application_cancellation_rule: selectedValueThird,
                voting_question: votingQuestion,
                post_event_participant_settings_attributes: eventsArray,
              },
            ],
            vote_options_attributes: votes,
            images_attributes: [
              ...allImages,
              ...removedImages,
              ...filesUploaded,
            ],
            attachments_attributes: [
              ...allFiles,
              ...removedFiles,
              ...docUploaded,
            ],

            tags_attributes: finalEditTags,
          },
        };

        let res;

        if (event) {
          const response = await gcServices.editEvent(event.id, body);
          res = response?.data?.success;
        } else {
          const response = await appServices.createGroupEvent(body);
          res = response?.success;
        }
        // console.log(res);
        setLoading(false);

        if (res === true) {
          navigate(-2);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    // console.log("Edit Post:", event);
    if (event) {
      // eslint-disable-next-lin
      const {
        title,
        enable_comments,
        expiry_date,
        post_tags,
        vote_options,
        images,
        attachments,
      } = event;

      const {
        description,
        start_date,
        end_date,
        venue_name,
        postal_code,
        address,
        url,
        member_participation_rule,
        voting_question,
        application_end_date,
        application_start_date,
        application_cancellation_rule,
      } = event?.post_events[0];

      const Applications = event?.post_events[0]?.participant_settings;

      setTitle(title);
      setEventOverview(description);
      setSelectedValueThird(application_cancellation_rule);
      setApplicationEndDate(dayjs(application_end_date));
      setApplicationStartDate(dayjs(application_start_date));
      setVotingEndDate(dayjs(expiry_date));
      setCommentsOption(enable_comments ? 1 : 0);
      setEventStartDate(dayjs(start_date));
      setEventEndDate(dayjs(end_date));
      setVenueName(venue_name);
      setPostalCode(postal_code);
      setMemberParticipate(member_participation_rule);
      setAddress(address);
      setWebsiteURL(url);
      setVotingOption(voting_question ? "Yes" : "No");
      setVotingQuestion(voting_question);
      const votes = vote_options.map((vote: any) => ({
        id: vote.id,
        post_id: vote.post_id,
        value: vote.option_text,
      }));
      const today = dayjs();
      const endDate = dayjs(end_date);
      setEventDatehasPassed(endDate.isBefore(today));
      // eslint-disable-next-line array-callback-return
      Applications.map((application: any) => {
        const { id, name, number_of_participants, fee_amount, free, group } =
          application;

        const newApplication = {
          id,
          inputs: {
            name: name,
            numberOfParticipants: number_of_participants,
            FeeAmount: fee_amount,
          },
          free: free,
          group: group,
        };
        setFormSections((prevSections) => [...prevSections, newApplication]);
      });
      setSelectedValue(Applications.length > 0 ? "Yes" : "No");

      const tags = post_tags.map((tag: any) => tag.tag);
      setTags(tags);
      setEditTags(post_tags);

      const imgs = images.map((img: any) => ({
        path: img.path,
        id: img.id,
        name: img.path,
        file: undefined,
        imageSrc: undefined,
      }));

      const files = attachments.map((file: any) => ({
        path: file.path,
        id: file.id,
        name: file.path,
        file: undefined,
        fileSrc: undefined,
      }));

      setFiles(files);
      setImages(imgs);

      if (votes.length > 0) {
        setInputFields(votes);
      }

      // const totalVotes = vote_options.reduce((total: number, vote: any) => total + vote.votes_count, 0);
      // setVoteCounts(totalVotes);
    }
    // eslint-disable-next-line
  }, [event]);

  useEffect(() => {
    const filesToUpload = images.filter((img) => img.file !== undefined);
    setFilesToUpload(filesToUpload);

    const filesUploaded = images.filter((img) => img.path !== undefined);
    setFilesUploaded(filesUploaded);
  }, [images]);

  const isImageAdded = (image: { id: undefined; path: string }) => {
    const imageAlreadyExists = images.find((img) => img.path === image.path);
    if (imageAlreadyExists) {
      return true;
    }
    return false;
  };

  const handleDefaultImage = (image: { id: undefined; path: string }) => {
    if (images.length >= 5) return;
    let newImage = {
      id: image.id,
      imageSrc: "",
      name: image.path.split("/").pop(),
      file: undefined,
      path: image.path,
      _destroy: "0",
    };
    // let newImages = images.filter((img) => img.id !== newImage.id);
    let newImages = [...images];
    const imageAlreadyExists = newImages.find(
      (img) => img.path === newImage.path
    );
    if (!imageAlreadyExists) {
      newImages = [...newImages, newImage];
    }
    setImages(newImages);
  };

  useEffect(() => {
    const select = tags.map((tag) => {
      return { tag: tag };
    });
    // console.log(select);
    const all = select.concat(editTags);
    // var ages = array.map(function(obj) { return obj.age; });
    const uniqueArray = makeArrayUnique(all);
    // console.log(uniqueArray);

    const f = uniqueArray.concat(removeTags);
    const fin = makeArrayUnique(f);
    // console.log("fin", fin);
    setFinalEditTags(fin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, removeTags]);

  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (
          event?.data === "" ||
          event?.data === null ||
          event?.data === undefined
        )
          return;
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });

          setImages((prevImages: any) => [
            ...prevImages,
            {
              name: fileName,
              file: file,
              imageSrc: event?.data,
              path: undefined,
              _destroy: "0",
            },
          ]);
        } catch (error) {
          // alert("error" + error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  const saveRemoveImg = (img: ImageItem) => {
    if (img.path !== undefined) {
      const item = img;
      const addDestroy = [...removedImages, { ...item, _destroy: "1" }];
      // console.log(addDestroy);
      setRemovedImage(addDestroy);
      // console.log(removedImages);
    }
  };

  const shouldDisableDate = (date: any) => {
    // Disable dates before today
    return date.isBefore(dayjs(), "day");
  };

  const shouldDisableEndDate = (date: any) => {
    // Disable dates before today
    return date.isBefore(eventStartDate, "day");
  };

  const shouldDisableApplicationEndDate = (date: any) => {
    // Disable dates before today
    return date.isBefore(applicationStartDate, "day");
  };

  const newEventsOptions = () => {
    return (
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {formSections.map((section, index) => (
          <Box sx={{ marginTop: "10px", background: "white" }} key={index}>
            <Typography
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "35px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              参加項目名：{index + 1}（必須）
            </Typography>
            <TextField
              required
              sx={{
                marginTop: "15px",
                background: "white",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
              value={section.inputs.name}
              onChange={(e) => handleInputChangeEvent(index, e)}
              name="name"
              placeholder="大人"
              multiline
              rows={1} // Set the number of rows
              variant="outlined" // You can choose "filled" or "standard" as well
              fullWidth // Makes the TextField take the full width of its container
            />
            <Separator sx={{ marginTop: "10px" }} color="#D1D1D1" />
            <Typography
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "35px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              参加人数（必須）
            </Typography>
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <TextField
                required
                sx={{
                  marginTop: "15px",
                  // background: "white",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  width: "45%",
                }}
                value={section.inputs.numberOfParticipants}
                onChange={(e) => {
                  handleInputChangeEvent(index, e);

                  // if (event !== undefined) {
                  //   e.target.value = "5";
                  //   handleInputChangeEvent(index, e);
                  // } else {
                  //   handleInputChangeEvent(index, e);
                  // }
                }}
                name="numberOfParticipants"
                disabled={section.group}
                placeholder="5"
                type="number"
                inputProps={{
                  max: 5, // Maximum value allowed
                  min: 0, // Minimum value allowed
                }}
                variant="outlined" // You can choose "filled" or "standard" as well
              />
              <Typography sx={{ marginLeft: -2 }}>人</Typography>

              {/* <FormControl component="fieldset">
                <RadioGroup
                  name={`radio1-${index}`}
                  value={section.group}
                  onChange={(event) =>
                    handleRadioChangeEvent(
                      index,
                      "group",
                      event.target.value === "true" ? true : false
                    )
                  }
                  row
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="人"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="組"
                  />
                </RadioGroup>
              </FormControl> */}
            </Box>
            <Separator sx={{ marginTop: "10px" }} color="#D1D1D1" />
            <Typography
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "35px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              参加料金
              {/* 料金（必須） */}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
              }}
            >
              <FormControl
                component="fieldset"
                sx={{
                  paddingLeft: "30px",
                }}
              >
                <RadioGroup
                  name={`radio2-${index}`}
                  value={section.free}
                  onChange={(event) =>
                    handleRadioChangeEvent(
                      index,
                      "free",
                      event.target.value === "true" ? true : false
                    )
                  }
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        disabled={
                          section?.id && event?.post_events[0]?.users.length > 0
                            ? true
                            : false
                        }
                      />
                    }
                    label="無料"
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        disabled={
                          section?.id && event?.post_events[0]?.users.length > 0
                            ? true
                            : false
                        }
                      />
                    }
                    label=""
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                required
                style={{
                  marginTop: "15px",
                  background: "white",
                  marginLeft: "-15px",
                  paddingRight: "5px",
                  width: "25%",
                }}
                value={section.inputs.FeeAmount}
                onChange={(e) => handleInputChangeEvent(index, e)}
                name="FeeAmount"
                disabled={
                  section.free ||
                  (section?.id && event?.post_events[0]?.users.length > 0
                    ? true
                    : false)
                }
                placeholder="1000"
                multiline
                rows={1} // Set the number of rows
                variant="outlined" // You can choose "filled" or "standard" as well
              />
              <Typography>円</Typography>
            </Box>
            <Separator sx={{ marginTop: "10px" }} color="#D1D1D1" />
          </Box>
        ))}
      </Box>
    );
  };

  const VotingQuestions = () => {
    return (
      <Box>
        {inputFields.slice(0, 5).map((input, index) => (
          <Box key={index}>
            <Typography
              sx={{
                paddingTop: "20px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              回答0{index + 1}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{ marginTop: "1px", background: "white" }}
                multiline
                placeholder={input.placeholder}
                value={input.value}
                rows={1} // Set the number of rows
                variant="outlined" // You can choose "filled" or "standard" as well
                fullWidth // Makes the TextField take the full width of its container
                onChange={(event) => handleInputChange(index, event)}
              />
              {/* {index > 1 && (
                <DeleteIcon
                  onClick={() => handleRemoveField(index)}
                  sx={{ color: "red", fontSize: "35px" }}
                />
              )} */}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <div>
      <div
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />

        <GCBackButton title="もどる" onClick={() => navigate(-1)} />

        <Box style={styles.eventPostingBox}>
          <Typography style={{ fontWeight: "700" }}>
            <img
              src={`${groupInfo?.groupImage}`}
              alt={"user"}
              style={{
                ...oldstyles.farmProfileIcon,
                height: 50,
                width: 50,
                margin: 0,
              }}
            />
            &nbsp;{groupInfo?.groupName}
          </Typography>
        </Box>
      </div>
      <Box component="div" style={styles.container}>
        <Typography
          sx={{
            borderBottom: "2px solid #86BB57",
            marginTop: "10px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          画像のアップロード （最大5枚）（任意）
        </Typography>
        <Carousel responsive={responsive}>
          {defaultPostImages.map(
            (item: { id: undefined; path: string }, index: number) => (
              <div
                key={item.path}
                style={{ ...styles.carouselImage, marginRight: "10px" }}
              >
                <AsyncImage
                  style={styles.carouselImage}
                  src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${item.path}`}
                  alt=""
                />
                {isImageAdded(item) ? (
                  <CheckCircleIcon
                    style={{ top: -30, position: "relative" }}
                    onClick={() => {}}
                    color="secondary"
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ top: -30, position: "relative" }}
                    onClick={() => handleDefaultImage(item)}
                    color="secondary"
                  />
                )}
              </div>
            )
          )}
        </Carousel>
        <Box sx={{ marginTop: "10px" }}>
          <Carousel responsive={responsive}>
            {images.map((item: ImageItem, index: number) => (
              <div
                key={item.name}
                style={{ ...styles.carouselImage, marginRight: "10px" }}
              >
                <AsyncImage
                  style={styles.carouselImage}
                  src={
                    item.path
                      ? `${process.env.REACT_APP_AWS_PATH_BUCKET}${"/images"}${
                          item.path
                        }`
                      : item.imageSrc
                  }
                  alt=""
                />
                <DeleteIcon
                  style={{ top: -30, position: "relative", color: "red" }}
                  onClick={() => {
                    removeImage(index);
                    saveRemoveImg(item);
                  }}
                  color={"inherit"}
                />
              </div>
            ))}
          </Carousel>
          {isIOS(user?.client_id) ? (
            <Button
              disabled={images.length >= 5}
              color={"secondary"}
              sx={styles.uploadImageButton}
              component="label"
              variant="outlined"
            >
              {T("upload_images_txt")}
              <input
                accept="image/*"
                type="file"
                hidden
                multiple
                onChange={handleImageUploadIOS}
              />
            </Button>
          ) : (
            <Button
              disabled={images.length === 1}
              color={"secondary"}
              sx={styles.uploadImageButton}
              component="label"
              variant="outlined"
              onClick={handleImageUploadAndroid}
            >
              {T("upload_images_txt")}
            </Button>
          )}
        </Box>

        <Typography sx={styles.typographyText}>タイトル（必須）</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => {
            if (event.target.value.length > 30) return;
            setTitle(event.target.value);
            setErrors({ ...errors, title: "" });
          }}
          value={title}
          multiline
          rows={2} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
          error={!!errors.title}
          helperText={errors.title}
        />

        <Typography sx={styles.typographyText}>イベント開催日・期間</Typography>

        <Typography
          sx={{
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          開始日（必須）
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="ja"
          localeText={{
            cancelButtonLabel: "キャンセル", // Change "CANCEL" to "キャンセル"
            okButtonLabel: "決定", // Optional: Change "OK" to "決定" (Confirm)
          }}
        >
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              // label="日付の選択"
              value={eventStartDate}
              onChange={(newDate) =>
                setEventStartDate(newDate?.locale("ja") ?? null)
              }
              shouldDisableDate={shouldDisableDate}
              format="YYYY年M月D日 (dd)" // Format to "2025年1月27日 (月)"
              slotProps={{
                toolbar: {
                  toolbarTitle: "日付の選択", // Change toolbar title to "日付の選択"
                  toolbarFormat: "M月D日 (dd)",
                } as any,
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              value={eventStartDate}
              onChange={(newDate) => setEventStartDate(newDate)}
              shouldDisableDate={shouldDisableDate}
              format="YYYY/MM/DD"
            />
          </DemoContainer>
        </LocalizationProvider> */}

        <Typography
          sx={{
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          終了日（開催日と異なる場合は記入）
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="ja"
          localeText={{
            cancelButtonLabel: "キャンセル", // Change "CANCEL"
            okButtonLabel: "決定", // Change "OK"
            todayButtonLabel: "今日", // Change "Today"
            clearButtonLabel: "クリア", // Change "Clear"
          }}
        >
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              value={eventEndDate}
              onChange={(newValue) => setEventEndDate(newValue)}
              shouldDisableDate={shouldDisableEndDate} // Keep existing logic
              format="YYYY年M月D日 (dd)" // Display format in Japanese
              slotProps={{
                toolbar: {
                  toolbarTitle: "日付の選択", // Change toolbar title to "日付の選択"
                  toolbarFormat: "M月D日 (dd)",
                } as any,
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              value={eventEndDate}
              onChange={(newValue) => setEventEndDate(newValue)}
              shouldDisableDate={shouldDisableEndDate}
              format="YYYY/MM/DD"
            />
          </DemoContainer>
        </LocalizationProvider> */}

        <Typography sx={styles.typographyText}>イベント会場</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => {
            setVenueName(event.target.value);
            setErrors({ ...errors, venueName: "" });
          }}
          value={venueName}
          placeholder="会場名"
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
          error={!!errors.venueName}
          helperText={errors.venueName}
        />
        <TextField
          style={styles.textField}
          onChange={(event) => {
            setPostalCode(event.target.value);
            setErrors({ ...errors, postalCode: "" });
          }}
          value={postalCode}
          placeholder="郵便番号"
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
          error={!!errors.postalCode}
          helperText={errors.postalCode}
        />
        <TextField
          style={styles.textField}
          onChange={(event) => {
            setAddress(event.target.value);
            setErrors({ ...errors, address: "" });
          }}
          value={address}
          placeholder="住所"
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
          error={!!errors.address}
          helperText={errors.address}
        />

        <Typography sx={styles.typographyText}>イベント概要</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setEventOverview(event.target.value)}
          value={eventOverview}
          multiline
          rows={10} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />

        <Typography sx={styles.typographyText}>WEBサイトのURL</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setWebsiteURL(event.target.value)}
          value={websiteURL}
          placeholder="[公式サイト] https://www.abc.com [イベント会場] https://www.event.com"
          multiline
          rows={5} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />

        <Typography sx={styles.typographyText}>
          本文内に入れるファイルのアップロード (最大5ファイル)(任意)
        </Typography>
        <Typography sx={{ mt: 2 }}>
          QRなどを掲載するための画像ファイル (JPEGやPNG)
          から、PDFやパワボ、エクセルなどのファイルまでアッ プロードいただけます。
        </Typography>
        <Box>
          {files?.map((item: FileItem, index: number) => (
            <div key={item.name} style={styles.filesBox}>
              <Typography>
                {item?.name?.startsWith("/")
                  ? decodeURIComponent(item.name.replace(/^\/?\d+-/, ""))
                  : item?.name}
              </Typography>
              <DeleteIcon
                style={{
                  color: "red",
                }}
                onClick={() => {
                  removeFile(index);
                  saveRemoveFile(item);
                }}
                color={"inherit"}
              />
            </div>
          ))}

          {isIOS(user?.client_id) ? (
            <Button
              disabled={images.length >= 5}
              color={"secondary"}
              sx={styles.uploadImageButton}
              component="label"
              variant="outlined"
            >
              {T("ファイルを選択")}
              <input
                accept="image/*,.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx"
                type="file"
                hidden
                multiple
                onChange={handleFileUploadIOS}
              />
            </Button>
          ) : (
            <Button
              // disabled={images.length >= 5}
              disabled
              color={"secondary"}
              sx={styles.uploadImageButton}
              component="label"
              variant="outlined"
            >
              近日公開
              {/* <input
                  accept="image/*,.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx"
                  type="file"
                  hidden
                  multiple
                  onChange={handleFileUploadAndroid}
                /> */}
            </Button>
          )}
        </Box>

        <Typography sx={styles.typographyText}>
          このイベント設定に参加できる人
        </Typography>
        <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            value={memberParticipate}
            onChange={(event) => setMemberParticipate(event.target.value)}
          >
            <FormControlLabel
              disabled
              value="グループの会員のみ参加できます。"
              control={<Radio />}
              label="グループの会員のみ参加できます。"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            />

            <Typography>グループの会員のみ参加できます。</Typography>
            <br />
            <FormControlLabel
              disabled
              value="誰でも参加できます。"
              control={<Radio />}
              label="誰でも参加できます。"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: "bold",
                },
              }}
            />
            <Typography>
              公開グループの場合はイベント投稿が公開されます。グループメンバーでない人がイベントに応募しようとした場合、グループへの参加を促すメッセージが表示されます。
            </Typography>
          </RadioGroup>
        </FormControl>

        <Typography sx={styles.typographyText}>
          応募ボタンを設置しますか？
        </Typography>
        <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
          >
            <FormControlLabel
              value="No"
              disabled={isUnderEighteen(groupInfo?.owner?.dob)}
              control={<Radio />}
              label="設置しない。 "
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            />
            <ul>
              <li>誰でも何人でも参加できるイベントです。</li>
            </ul>

            <FormControlLabel
              value="Yes"
              disabled={isUnderEighteen(groupInfo?.owner?.dob)}
              control={<Radio />}
              label="設置する：先着順"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            />
            <ul>
              <li>参加人数の制限があるイベントです。</li>
              <li>選考は先着順です。 </li>
              <li>参加料がある場合は参加申し込み時にお支払いとなります。</li>
              <li>参加人数に達成後、ボタンは押せなくなります。</li>
            </ul>
            {isUnderEighteen(groupInfo?.owner?.dob) && (
              <Typography style={{ color: colors.farm_gray200 }}>
                グループリーダーが18歳未満の場合、支払いは利用できません。
              </Typography>
            )}
            {selectedValue === "Yes" && (
              <>
                <Box style={styles.containerInternal}>
                  <Typography sx={styles.conditions}>
                    【応募開始日（必須）】
                  </Typography>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        value={applicationStartDate}
                        onChange={(newValue) =>
                          setApplicationStartDate(newValue)
                        }
                        shouldDisableDate={shouldDisableDate}
                        format="YYYY/MM/DD"
                      />
                    </DemoContainer>
                  </LocalizationProvider> */}
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja"
                    localeText={{
                      cancelButtonLabel: "キャンセル", // Change "CANCEL"
                      okButtonLabel: "決定", // Change "OK"
                    }}
                  >
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        value={applicationStartDate}
                        onChange={(newValue) =>
                          setApplicationStartDate(newValue)
                        }
                        shouldDisableDate={shouldDisableDate} // Keep existing logic
                        format="YYYY年M月D日 (dd)" // Display format in Japanese
                        slotProps={{
                          toolbar: {
                            toolbarTitle: "日付の選択", // Change toolbar title to "日付の選択"
                            toolbarFormat: "M月D日 (dd)",
                          } as any,
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Typography sx={styles.conditions}>
                    【応募終了日（必須）】
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja"
                    localeText={{
                      cancelButtonLabel: "キャンセル", // Change "CANCEL"
                      okButtonLabel: "決定", // Change "OK"
                    }}
                  >
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        value={applicationEndDate}
                        onChange={(newValue) => setApplicationEndDate(newValue)}
                        shouldDisableDate={shouldDisableApplicationEndDate} // Keep existing logic
                        format="YYYY年M月D日 (dd)" // Display format in Japanese
                        slotProps={{
                          toolbar: {
                            toolbarTitle: "日付の選択", // Change toolbar title to "日付の選択"
                            toolbarFormat: "M月D日 (dd)",
                          } as any,
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        value={applicationEndDate}
                        onChange={(newValue) => setApplicationEndDate(newValue)}
                        shouldDisableDate={shouldDisableApplicationEndDate}
                        format="YYYY/MM/DD"
                      />
                    </DemoContainer>
                  </LocalizationProvider> */}
                </Box>
                {errors?.formSection && (
                  <Typography sx={{ color: "red", paddingTop: "10px" }}>
                    {errors?.formSection}
                  </Typography>
                )}
                {newEventsOptions()}

                <Button
                  disabled={false}
                  color="secondary"
                  sx={styles.submitButton}
                  style={{ marginBottom: "20px" }}
                  size={"large"}
                  variant="outlined"
                  onClick={handleAddSection}
                >
                  参加項目を追加する
                </Button>
                <Box
                  style={styles.containerInternal}
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Typography sx={styles.conditions}>
                    【キャンセルについて】
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={selectedValueThird}
                      onChange={(event) => {
                        setSelectedValueThird(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="キャンセルはできるが返金不可"
                        control={<Radio />}
                        label="キャンセルはできるが返金不可"
                      />
                      <FormControlLabel
                        value="1週間前まではキャンセルも返金も可能"
                        control={<Radio />}
                        label="キャンセルおよび返金可能"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </>
            )}
          </RadioGroup>
        </FormControl>

        <Typography sx={styles.typographyText}>タグ</Typography>

        <TagsInput
          value={tags}
          onChange={setTags}
          onRemoved={handleDeleteTag}
          name="fruits"
          separators={[" ", ",", "Enter", "return"]}
        />
        <Typography sx={styles.typographyText}>
          投票を設定しますか？（必須）
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup
            value={votingOption}
            onChange={(event) => setVotingOption(event.target.value)}
            row
          >
            <FormControlLabel value="Yes" control={<Radio />} label="はい" />
            <FormControlLabel value="No" control={<Radio />} label="いいえ" />
          </RadioGroup>
        </FormControl>
        {votingOption === "Yes" && (
          <Box>
            <Typography sx={styles.typographyText}>投票したい質問</Typography>
            <TextField
              style={styles.textField}
              onChange={(event) => setVotingQuestion(event.target.value)}
              value={votingQuestion}
              placeholder="犬と猫どちらが好きですか？"
              multiline
              rows={2} // Set the number of rows
              variant="outlined" // You can choose "filled" or "standard" as well
              fullWidth // Makes the TextField take the full width of its container
            />
            {VotingQuestions()}
            {errors?.voteOption && (
              <Typography sx={{ color: "red" }}>
                {errors?.voteOption}
              </Typography>
            )}
            <Button
              disabled={false}
              color="secondary"
              sx={styles.submitButton}
              size={"large"}
              variant="outlined"
              onClick={handleAddField}
            >
              回答を追加する
            </Button>
            <Typography
              style={{
                paddingTop: "30px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              投票終了日 （最大３ヶ月先まで）
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={{ background: "white" }}
                  value={votingEndDate}
                  onChange={(newValue) => setVotingEndDate(newValue)}
                  format="YYYY/MM/DD"
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        )}
        <Typography sx={styles.typographyText}>
          コメントを受け付けますか？（任意）
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup
            value={commentsOption}
            onChange={(event) =>
              setCommentsOption(parseInt(event.target.value))
            }
            row
          >
            <FormControlLabel value={1} control={<Radio />} label="はい" />
            <FormControlLabel value={0} control={<Radio />} label="いいえ" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box component="div" style={styles.container2}>
        <Typography
          style={{
            borderBottom: "2px solid #86BB57",
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          注意事項（必須）
        </Typography>
        <Typography sx={styles.conditions}>
          ターゲットを絞った誹謗中傷の内容ではありません。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color="gray" />
        <Typography sx={styles.conditions}>
          わいせつ行為や犯罪を促進する内容及び募集ではありません。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color="gray" />
        <Typography sx={styles.conditions}>
          投稿内容に責任を持ちます。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color="gray" />

        {(!event || groupInfo?.role === "sub-leader" || groupInfo?.role === "leader") && (
          <>
            <FormControlLabel
              style={{ paddingTop: "30px" }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.target.checked)}
                  name="singleCheckbox"
                />
              }
              label="上記の注意事項を確認し、すべての事項に同意いたします。"
            />
            <Button
              disabled={!checked}
              color="secondary"
              onClick={submit}
              sx={styles.submitButton}
              size={"large"}
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "10px",
                  }}
                />
              }
            >
              投稿する
            </Button>
            {event && !EventDatehasPassed && (
              <Button
                color="error"
                onClick={() =>
                  navigate("/eventcanceldetails", {
                    state: {
                      event,
                    },
                  })
                }
                sx={styles.submitButton}
                size={"large"}
                variant="outlined"
                endIcon={
                  <ArrowForwardIosIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      margin: "10px",
                    }}
                  />
                }
              >
                イベントを中止する
              </Button>
            )}
          </>
        )}
      </Box>
    </div>
  );
}
const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  container: {
    background: "#ECEFF1",
    padding: "20px 30px 20px 30px",
  },
  container2: {
    padding: "20px 30px 20px 30px",
  },
  containerInternal: {
    background: "white",
    padding: "20px 30px 20px 30px",
    borderRadius: "5px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  conditions: {
    paddingTop: "5px",
    textAlign: "left",
    fontSize: "15px",
  },
  typographyText: {
    borderBottom: "2px solid #86BB57",
    paddingTop: "50px",
    textAlign: "left",
    fontSize: "15px",
  },
  carouselImage: {
    height: 100,
    width: "auto",
    borderRadius: 2,
  },
  filesBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "25px",
    marginTop: "10px",
    borderBottom: "1px solid #86BB57",
    padding: "5px 0",
  },
  uploadImageButton: {
    alignSelf: "center",
    margin: "10px auto",
    maxWidth: 200,
    display: "flex",
    borderRadius: 20,
    fontFamily: "mplus-1c-bold",
  },
  textField: {
    marginTop: "15px",
    background: "white",
  },
};
export default CreateNewEvent;
