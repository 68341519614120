import { Avatar, Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import style from "./styles";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonOutlinedCentered from "../../../components/ButtonOutlined";
import { colors } from "../../../common/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import moment from "moment";

export const AddTransactionDetails = ({ item }: any) => {
  const getPaymentTypeJp = (item: any) => {
    if (item.billing_cycle === "monthly") {
      return "月会費";
    } else if (item.billing_cycle === "yearly") {
      return "年会費";
    } else if (item?.payment_type === "joining_fee") {
      return "入会金";
    } else if (item?.payment_type === "annual" || item?.payment_type === "yearly") {
      return "年会費";
    } else if (item?.payment_type === "monthly") {
      return "月会費";
    } else if (item?.payment_type === "event_fee") {
      return "イベント費";
    } else if (item?.payment_type === "StorePayment") {
      return "店舗支払い";
    } else if (item?.payment_type === "reimbursement") {
      return "立替精算";
    } else if (item?.payment_type === "other_fee") {
      return "その他";
    } else {
      return item?.payment_type;
    }
  };

  if (item.reimbursement) {
    return (
      <Box sx={{ ...style.boxContainer, borderColor: colors.farm_green500 }}>
        <Box sx={{ ...style.row, backgroundColor: colors.farm_green100 }}>
          <Typography sx={{ padding: "5px", flex: 1 }}>{moment(item?.created_at).format("YYYY/MM/DD hh:mm")}</Typography>
          <AddCircleOutlineIcon sx={{ ...style.addIcon, color: colors.farm_green500 }} />
        </Box>

        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>種類</Typography>
          <Typography sx={style.boxRight}>{getPaymentTypeJp(item)}</Typography>
        </Box>
        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>送信元</Typography>
          <Typography sx={style.boxRight}>{item?.payer.nickname || item?.payer.name}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い日</Typography>
          <Typography sx={style.boxRight}>{item.reimbursement?.payment_date}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い先</Typography>
          <Typography sx={style.boxRight}>{item?.payee?.nickname || item?.payee?.name}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い金額</Typography>
          <Typography sx={{ ...style.boxRight, color: colors.farm_green500, fontFamily: "mplus-1c-bold" }}>{`¥ ${item.formatted_amount}`}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い方法</Typography>
          <Typography sx={style.boxRight}>{"めぶくPay"}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>メモ</Typography>
          <Typography sx={style.boxRight}>{item?.payment_details}</Typography>
        </Box>
        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>申請ID</Typography>
          <Typography sx={style.boxRight}>{item.reimbursement.id}</Typography>
        </Box>
        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>取引ID</Typography>
          <Typography sx={style.boxRight}>{item?.transaction_id}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ ...style.boxContainer, borderColor: colors.farm_green500 }}>
      <Box sx={{ ...style.row, backgroundColor: colors.farm_green100 }}>
        <Typography sx={{ padding: "5px", flex: 1 }}>{moment(item?.created_at).format("YYYY/MM/DD hh:mm")}</Typography>
        <AddCircleOutlineIcon sx={{ ...style.addIcon, color: colors.farm_green500 }} />
      </Box>
      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>送信元</Typography>
        <Typography sx={style.boxRight}>{item?.payer.nickname || item?.payer.name}</Typography>
      </Box>
      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>支払い金額</Typography>
        <Typography sx={{ ...style.boxRight, color: colors.farm_green500, fontFamily: "mplus-1c-bold" }}>{`¥ ${item.formatted_amount}`}</Typography>
      </Box>
      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>種類</Typography>
        <Typography sx={style.boxRight}>{getPaymentTypeJp(item)}</Typography>
      </Box>
      <Box sx={style.separator2} />

      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>取引ID</Typography>
        <Typography sx={style.boxRight}>{item?.transaction_id}</Typography>
      </Box>
    </Box>
  );
};

export const RemoveTransactionDetails = ({ item }: any) => {
  const getPaymentTypeJp = (item: any) => {
    if (item.billing_cycle === "monthly") {
      return "月会費";
    } else if (item.billing_cycle === "yearly") {
      return "年会費";
    } else if (item?.payment_type === "joining_fee") {
      return "入会金";
    } else if (item?.payment_type === "annual" || item?.payment_type === "yearly") {
      return "年会費";
    } else if (item?.payment_type === "monthly") {
      return "月会費";
    } else if (item?.payment_type === "event_fee") {
      return "イベント費";
    } else if (item?.payment_type === "StorePayment") {
      return "店舗支払い";
    } else if (item?.payment_type === "reimbursement") {
      return "立替精算";
    } else if (item?.payment_type === "other_fee") {
      return "その他";
    } else {
      return item?.payment_type;
    }
  };
  if (item.reimbursement) {
    return (
      <Box sx={{ ...style.boxContainer, borderColor: colors.farm_red600 }}>
        <Box sx={{ ...style.row, backgroundColor: colors.farm_red10 }}>
          <Typography sx={{ padding: "5px", flex: 1 }}>{moment(item?.created_at).format("YYYY/MM/DD hh:mm")}</Typography>
          <RemoveCircleOutlineOutlinedIcon sx={{ ...style.addIcon, color: colors.farm_red600 }} />
        </Box>

        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>種類</Typography>
          <Typography sx={style.boxRight}>{getPaymentTypeJp(item)}</Typography>
        </Box>
        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>送金先</Typography>
          <Typography sx={style.boxRight}>{item?.payee.nickname || item?.payee?.name}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い日</Typography>
          <Typography sx={style.boxRight}>{item.reimbursement?.payment_date}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い先</Typography>
          <Typography sx={style.boxRight}>{item?.payer?.name}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い金額</Typography>
          <Typography sx={{ ...style.boxRight, color: colors.farm_red600, fontFamily: "mplus-1c-bold" }}>{`¥ ${item.formatted_amount}`}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>支払い方法</Typography>
          <Typography sx={style.boxRight}>{"めぶくPay"}</Typography>
        </Box>
        <Box sx={style.separator2} />

        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>メモ</Typography>
          <Typography sx={style.boxRight}>{item?.payment_details}</Typography>
        </Box>
        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>申請ID</Typography>
          <Typography sx={style.boxRight}>{}</Typography>
        </Box>
        <Box sx={style.separator2} />
        <Box sx={style.row}>
          <Typography sx={style.boxLeft}>取引ID</Typography>
          <Typography sx={style.boxRight}>{item?.transaction_id}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ ...style.boxContainer, borderColor: colors.farm_red600 }}>
      <Box sx={{ ...style.row, backgroundColor: colors.farm_red10 }}>
        <Typography sx={{ padding: "5px", flex: 1 }}>{moment(item?.created_at).format("YYYY/MM/DD hh:mm")}</Typography>
        <RemoveCircleOutlineOutlinedIcon sx={{ ...style.addIcon, color: colors.farm_red600 }} />
      </Box>

      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>種類</Typography>
        <Typography sx={style.boxRight}>{getPaymentTypeJp(item)}</Typography>
      </Box>
      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>支払先</Typography>
        <Typography sx={style.boxRight}>{item?.payee.nickname || item?.payee?.name}</Typography>
      </Box>
      <Box sx={style.separator2} />

      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>支払い金額</Typography>
        <Typography sx={{ ...style.boxRight, color: colors.farm_red600, fontFamily: "mplus-1c-bold" }}>{`¥ ${item.formatted_amount}`}</Typography>
      </Box>
      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>メモ</Typography>
        <Typography sx={style.boxRight}>{item?.payment_details}</Typography>
      </Box>
      <Box sx={style.separator2} />
      <Box sx={style.row}>
        <Typography sx={style.boxLeft}>取引ID</Typography>
        <Typography sx={style.boxRight}>{item?.transaction_id}</Typography>
      </Box>
    </Box>
  );
};

const PaymentHistory = () => {
  const { user } = useSelector((state: any) => state.userAuth);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const years = Array.from({ length: 50 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const [payments, setPayments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const dropdownOptions = ["全て", "収入", "支出"];
  const [selectedOption, setSelectedOption] = useState("全て");

  const handleAPICall = (page: number) => {
    setLoading(true);
    gcServices.getUserPaymentInfo(user.id, selectedYear, selectedMonth).then((res) => {
      setPayments(res.payments);
      setLoading(false);
    });
  };

  const showAdd = (item: any) => {
    return item.payee.id === user.id && item?.payer?.type==='MemberGroup';
  };

  const filterData = () => {
    if (selectedOption === "全て") {
      return payments;
    } else if (selectedOption === "収入") {
      return payments.filter((item: any) => item.payee.id === user.id);
    } else if (selectedOption === "支出") {
      return payments.filter((item: any) => item.payee.id !== user.id);
    }
  };

  useEffect(() => {
    handleAPICall(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.rowMargin}>
          <Avatar src={"data:image/jpeg;base64," + user.image} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>利用履歴</Typography>
        </Box>
      </Box>
      <Box sx={style.container}>
        <Typography sx={style.titleSeprator}>利用月</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedYear} 年
            </MenuItem>
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} displayEmpty sx={{ width: "30%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedMonth} 月
            </MenuItem>
            {months.map((mn) => (
              <MenuItem key={mn} value={mn}>
                {mn}
              </MenuItem>
            ))}
          </Select>

          <ButtonOutlinedCentered onClick={() => handleAPICall(1)} title="表示する" />
        </Box>
        <Select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} displayEmpty sx={{ height: "40px", width: "100%", marginTop: "10px" }}>
          {dropdownOptions.map((mn) => (
            <MenuItem key={mn} value={mn}>
              {mn}
            </MenuItem>
          ))}
        </Select>

        {!loading ? (
          filterData()?.map((item) => <Box sx={{ marginTop: "20px" }}>{showAdd(item) ? <AddTransactionDetails item={item} /> : <RemoveTransactionDetails item={item} />}</Box>)
        ) : (
          <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PaymentHistory;
