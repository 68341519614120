import { Avatar, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { colors } from "../../../common/colors";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";

const PaymentInfo = () => {
  const navigate = useNavigate();
  const groupInfo = useLocation().state.groupInfo;
  const shopInfo = useLocation().state.shopInfo;
  const [payment, setPayment] = useState("");
  const [accountInfo, setAccountInfo] = useState<any>();

  const handleSubmit = () => {
    navigate("/paymentreview", { state: { groupInfo: groupInfo, payment: payment, shopInfo: shopInfo } });
  };

  useEffect(() => {
    getGroupBalance();
    // eslint-disable-next-line
  }, []);

  const getGroupBalance = async () => {
    try {
      const res = await gcServices.getGroupBalance(groupInfo?.id);
      // console.log(res?.wallet_information?.balance?.money?.amount);
      setAccountInfo(res?.wallet_information?.balance?.money?.amount);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={styles.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={styles.rowMargin}>
          <Avatar src={groupInfo.groupImage} sx={{ ...styles.avatar, width: "30px" }} />
          <Typography>{groupInfo.groupName}</Typography>
        </Box>
      </Box>
      <Box sx={styles.container}>
        <Typography sx={styles.title}>{shopInfo.name}</Typography>
        <Typography sx={styles.subtitle}>支払い金額を入力する</Typography>
        <TextField
          sx={{
            "& .MuiInputBase-input": styles.paymentInput,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={styles.paymentInput}>¥</Typography>
              </InputAdornment>
            ),
          }}
          placeholder="0"
          size={"medium"}
          color={"secondary"}
          id="standard-basic"
          inputMode="numeric"
          value={payment}
          onChange={(e) => setPayment(e.target.value)}
          variant="standard"
        />
        <Typography sx={{ color: colors.farm_gray200 }}>グループの「めぶくPay」残高：{accountInfo} 円</Typography>
        <Button
          color="secondary"
          sx={styles.button}
          disabled={!payment}
          size={"large"}
          variant={!payment ? "outlined" : "contained"}
          onClick={handleSubmit}
          endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
        >
          つぎへ
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  headerBox: {
    padding: "1px 16px",
    paddingTop: "90px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  avatar: {
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    objectFit: "cover",
    overFlow: "hidden",
    backgroundColor: "#E8E8E8",
  },

  container: {
    padding: "0px 20px",
    backgroundColor: colors.farm_gray50,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 20,
    fontFamily: "mplus-1c-regular",
    fontWeight: "600",
    marginTop: "10px",
  },

  subtitle: {
    fontSize: 16,
    fontFamily: "mplus-1c-regular",
    marginTop: "10px",
  },
  paymentInput: {
    color: colors.farm_blue200, // Change text color
    fontSize: "26px", // Change font size
    fontWeight: "600",
  },
  rowMargin: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
  },
  button: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    // border: "2px solid",
    marginBottom: 8,
    marginTop: "10px",
  },
  textInput: {
    width: "100%",
    backgroundColor: "white",
    marginTop: "10px",
  },
};
export default PaymentInfo;
