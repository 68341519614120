import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import postReducer from "./Reducers/postReducer";
import { useDispatch } from "react-redux";
import groupReducer from "./Reducers/groupReducer";
import categoriesReducer from "./Reducers/categoriesReducer";
import adminAuthReducer from "./Reducers/adminAuthReducer";
import userAuthReducer from "./Reducers/userAuthReducer";
import dashboardReducer from "./Reducers/dashboardReducer";
import gcGroupReducer from "./Reducers/gcGroupReducer";
import gcSearchReducer from "./Reducers/gcSearchReducer";
import filtersReducer from "./Reducers/filtersReducer";

const reducers = combineReducers({
  posts: postReducer,
  groups: groupReducer,
  categories: categoriesReducer,
  adminAuth: adminAuthReducer,
  userAuth: userAuthReducer,
  dashboard: dashboardReducer,
  gcGroup: gcGroupReducer,
  gcSearch: gcSearchReducer,
  filters: filtersReducer,
});

const persistConfig = {
  key: "root",
  storage,
  sessionStorage,
  whitelist: ["posts", "userAuth", "groups", "categories", "adminAuth", "dashboard"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const persistor = persistStore(store);
