import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../../common/colors";
import GCBackButton from "../../../../components/GreenCity/GCBackButton";
import Separator from "../../../../components/Separator";
import { styles } from "../../../styles";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import FindUserModal from "../../../../components/Models/GreenCity/FindUserModal";

import profile from "../../../../assets/images/profile_img.png";
import gcServices from "../../../../services/app-services/greenCity/gcServicesR6";
import MessageModal from "../../../../components/Models/GreenCity/MessageModal";
import LoadingModal from "../../../../components/LoadingModal";
// eslint-disable-next-line
function InvitionLinkCreations() {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>({});
  const [message, setMessage] = useState("");
  const [isMember, setMemeber] = useState(false);

  const link = `${process.env.REACT_APP_API_URL?.replace("api.", "")}/groupinvitation?link=${groupInfo?.id}`;

  // const copyToClipboard = () => {
  //     navigator.clipboard.writeText(link)
  //         .then(() => alert('Copied successfully!'))
  //         .catch(err => alert('Failed to copy: ' + err));
  // };

  function copyTextFallback(text: string) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("リンクをコピーしました");
  }

  function copyToClipboard() {
    try {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          alert("リンクをコピーしました");
        })
        .catch((err) => {
          console.error("Clipboard write failed, using fallback:", err);
          copyTextFallback(link);
        });
    } catch (err) {
      console.error("Clipboard API not supported, using fallback:", err);
      copyTextFallback(link);
    }
  }

  const addUser = async () => {
    setLoading(true);
    try {
      await gcServices.inviteMember(user?.id, groupInfo.id, "invited");
      setMessage("ユーザーを招待しました。ユーザーのマイページにこのグループが表示されるようになりました。");
      setTimeout(() => {
        setUser({});
      }, 3000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkIsMemeber();
    // eslint-disable-next-line
  }, [user]);

  const checkIsMemeber = async () => {
    if (groupInfo.id && groupInfo?.user?.id !== user?.id) {
      // console.log(groupInfo);
      // console.log(groupInfo.id && groupInfo?.user?.id !== user?.id);
      const res = await gcServices.checkIsMembership(user?.id, groupInfo?.id);

      if (res?.member_group_users.length === 0) {
        setMemeber(false);
      } else {
        setMemeber(true);
      }
    }
  };

  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} handleClose={() => {}} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${groupInfo?.groupImage}`}
            alt={"user_img"}
            style={{
              ...styles.farmProfileIcon,
              height: 50,
              width: 50,
              margin: 0,
            }}
          />
          &nbsp;{groupInfo?.groupName}
        </Typography>
      </Box>

      <Separator sx={{ marginTop: "4px", marginBottom: "4px" }} color={colors.farm_gray100} />
      {/* <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
                marginBottom: 6,
                marginTop: 16,
              }}
            >
              <PeopleAltOutlinedIcon />
              &nbsp;グループへ招待する
            </Typography>
            <Typography>QRコード</Typography>
            <Typography style={{fontSize:12,color:'#747474'}}>メンバーになってもらいたい人に「めぶくアプリ」のパスポートから、下記QRコードを読み込んでもらいます。読み込み後メンバーとなります。</Typography>
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
        <QRCode
          size={280}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={link}
          viewBox={`0 0 256 256`}
        />
      </div> */}
      {/* <br/> */}
      {/* <Separator sx={{ marginTop: "4px" , marginBottom: "4px" }} color={colors.farm_gray100} /> */}
      {/* <br></br> */}
      <Typography>招待リンクを送る</Typography>
      <Typography style={{ fontSize: 12, color: "#747474" }}>
        下記ボタンをクリックすると、招待リンクがコピーされますので、メールやSNS等にペーストしてメンバーになってもらいたい人宛に送ってください。マイページから招待リンクを入力するとグループトップページが表示されます。ユーザーが「参加する」ボタンを押した後、参加を承認されると参加が完了します。
      </Typography>

      {/* <Typography>招待コードを送る</Typography>
      <Typography style={{ fontSize: 12, color: "#747474" ,padding:4}}>
      下記のボタンをクリックすると、招待コードがコピーされますので、メールやSNS等にペーストしてメンバーになってもらいたい人に送ってください。
このコードを受け取った方は、マイページの「招待コードでグループに参加する」からコードを入力すると招待したグループが表示され、グループのトップページからグループの参加ボタンを押します。
グループに参加リクエストが届きますので、リーダーが承認したらグループへの参加が完了します。

      </Typography> */}
      <br />
      <br />
      <Typography style={{ fontSize: 12, color: "#747474" }}>{link}</Typography>
      <Button
        color="secondary"
        sx={style.submitButton}
        size={"large"}
        variant="outlined"
        onClick={copyToClipboard}
        endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faCopy} />}
      >
       招待コードをコピー
      </Button>

      <Typography>検索する</Typography>
      <Separator sx={{ marginTop: "4px", marginBottom: "4px" }} color={colors.farm_gray100} />
      {user?.id && (
        <>
          <Typography style={{ fontWeight: "700" }}>
            <img
              src={user?.image !== null ? "data:image/jpeg;base64," + user?.image : profile}
              alt={"user_img"}
              style={{
                ...styles.farmProfileIcon,
                height: 50,
                width: 50,
                margin: 0,
              }}
            />
            &nbsp;{user?.nickname}
          </Typography>
          {isMember && <Typography style={{ fontSize: 12, color: colors.farm_gray200 }}>すでにグループのメンバーまたは招待されている</Typography>}

          <Button disabled={isMember} color="secondary" sx={style.submitButton} size={"large"} variant="outlined" onClick={() => addUser()}>
            招待を送信
          </Button>
          <Separator sx={{ marginTop: "4px", marginBottom: "4px" }} color={colors.farm_gray100} />
        </>
      )}
      <Button color="secondary" sx={style.submitButton} size={"large"} variant="outlined" onClick={() => setOpen(true)}>
        ユーザーを検索する
      </Button>

      <MessageModal
        open={message?.length > 0}
        message={message}
        onClose={() => {
          setMessage("");
          setUser(undefined);
        }}
        onContinue={() => {}}
      />

      <FindUserModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSelect={(user) => {
          setOpen(false);
          setUser(user?.user);
          // console.log("user", user?.user);
        }}
      />
    </div>
  );
}

export default InvitionLinkCreations;

const style = {
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
    marginBottom: 8,
  },
};
