import { Box, Modal, Typography } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
  onContinue: () => void;
};

const ConfirmationModalV2 = (props: Props) => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "85%",
          maxWidth: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <Typography variant="body1" sx={{ mb: 2, textAlign: "center", padding: "30px 20px" }}>
          {props.message}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ cursor: "pointer", width: "50%", textAlign: "center", border: "0.5px solid gray", borderRight: "none", borderLeft: "none", padding: "10px" }}
            onClick={props.onClose}
          >
            いいえ
          </Typography>
          <Typography sx={{ cursor: "pointer", width: "50%", textAlign: "center", border: "0.5px solid gray", borderRight: "none", padding: "10px" }} onClick={props.onContinue}>
            はい
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModalV2;
