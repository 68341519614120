import { useEffect, useState } from "react";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { colors } from "../../common/colors";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { T } from "react-translator-component";
import VerticalListP2 from "../../components/lists/VerticalListP2";
import LoadingModal from "../../components/LoadingModal";
import { Button, Stack, Typography } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import appServices from "../../services/app-services/appServices";
import MessageModal from "../../components/Models/GreenCity/MessageModal";
export default function CommentHistory() {
  const { user } = useSelector((state: any) => state.userAuth);
  //   const { privatePosts, isLoadingPrivatePosts } = useSelector(
  //     (state: any) => state.posts
  //   );
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [commentHistory, setCommentHistory] = useState<any>([]);
  const [commentHistoryPagination, setCommentHistoryPagination] = useState<any>(
    {}
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);
  useEffect(() => {
    if (user?.id) {
      apiCalls();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const apiCalls = async () => {
    setVisible(true);
    try {
      const temp = await appServices.getCommentHistoryPosts(user.id, 1);
      // console.log(rejectPosts.posts, "Reject Postsssssssssssssss");
      setCommentHistory(temp.posts);
      setCommentHistoryPagination(temp.pagination);
      setVisible(false);
    } catch (error) {
      setVisible(false);
    }
  };

  const handleMoreComment = async () => {
    if (commentHistoryPagination?.next_page > 0) {
      setVisible(true);
      const posts = await appServices.getCommentHistoryPosts(
        user.id,
        commentHistoryPagination?.next_page
      );
      setVisible(false);
      const newItems = [...commentHistory, ...posts.posts];
      setCommentHistory(newItems);
      setCommentHistoryPagination(posts.pagination);
    }
  };
  const removeObjectById = (id: any) => {
    setCommentHistory((prevObjects: any) =>
      prevObjects.filter((obj: any) => obj.id !== id)
    );
  };
  const [message,setMessage]=useState('');
  const handleReport = async (post_id: number, status: any) => {
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await appServices.acceptRejectPost(post_id, status);
      // console.log(response, "responseeeeeee");
      setLoading(false);
      setMessage('報告しました。')
      // setMessage('報告の問題');
      setTimeout(() => {
        // dispatch(removeReportedPrivatePost(post_id));
        removeObjectById(post_id);
      }, 1000);
    } catch (error) {
      // setMessage('報告しました。')
      setMessage('報告の問題');
      setLoading(false);
    }
  };

  const [likeLoading, setLikeLoading] = useState(false);

  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        handleLikeToggle(item.id, action === "like" ? false : true);
        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
      // setReSearch(true);
    }

    // console.log('like')
  };
  const handleLikeToggle = (postId: any, isLiked: boolean) => {
    setCommentHistory((prevPosts: any) =>
      prevPosts.map((post: any) => {
        if (post.id === postId) {
          // Update the post's liked_by_me and likes_count based on the current state
          return {
            ...post,
            liked_by_me: !isLiked, // Toggle the liked_by_me value
            likes_count: isLiked ? post.likes_count - 1 : post.likes_count + 1, // Increase or decrease the likes_count
          };
        }
        return post;
      })
    );
  };

  return (
    <div>
      <div style={{ height: "100px" }}></div>
      <div style={{ marginLeft: 8 }} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"center"}
        spacing={2}
      >
        <DriveFileRenameOutlineIcon />
        <Typography style={{}}>{T("Comment_History")}</Typography>
      </Stack>
      <div
        style={{
          width: "95%",
          height: 3,
          backgroundColor: "black",
          margin: 4,
        }}
      ></div>
      <LoadingModal
        visible={visible || loading}
        handleClose={() => {}}
      />
      <LoadingModal
        visible={likeLoading}
        handleClose={() => {}}
      />

      <VerticalListP2
        handleReport={handleReport}
        page={"1"}
        order={"order"}
        items={commentHistory}
        onLike={handleThumbUp}
        onUnLike={handleThumbUp}
        likeLoading={likeLoading}
      />
      {commentHistoryPagination?.next_page > 0 && (
        <Stack
          sx={{
            width: "100%",
            color: "grey.500",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Button onClick={handleMoreComment} size="small">
            {T("Load_More")}
          </Button>
        </Stack>
      )}
        <MessageModal open={message!==""} message={message} onClose={()=>setMessage("")}/>
    </div>
  );
}
