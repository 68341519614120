import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
  oldDescriptions?:any;
  onContinue: (text: string) => void;
  lable?: string;
};

const styles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    maxWidth: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    transition: "opacity 0.3s ease-in-out",
    // alignItems: "center",
  },
  message: {
    // mb: 2,
    textAlign: "center",
    padding: "20px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    cursor: "pointer",
    width: "50%",
    textAlign: "center",
    border: "0.5px solid gray",
    padding: "10px",
  },
  buttonLeft: {
    borderRight: "none",
  },
  buttonRight: {
    borderLeft: "none",
  },
};

const UpdateInput = (props: Props) => {
  const [text, setText] = useState<any>(props.oldDescriptions);
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={styles.container}>
        <Typography variant="body1" sx={styles.message}>
          {props.message}
        </Typography>
        <Box sx={{ display: "flex", margin: "10px" }}>
          <TextField value={text} onChange={(e) => setText(e.target.value)} sx={{ width: "100%" }} id="outlined-basic" label={props.lable} variant="outlined" />
        </Box>
        <Box sx={styles.buttons}>
          <Typography
            sx={{ ...styles.button }}
            onClick={() => {
              props?.onClose();
              setText("");
            }}
          >
            いいえ
          </Typography>
          <Typography sx={{ ...styles.button, ...styles.buttonLeft }} onClick={() => props.onContinue(text)}>
            アップデート
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateInput;
