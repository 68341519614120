import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
} from "@mui/material";
import appServices from "../services/app-services/appServices";
import LoadingModal from "./LoadingModal";
import { styles } from "../pages/styles";
// import { colors } from "../common/colors";
// import { AsyncImage } from "loadable-image";
import { T } from "react-translator-component";
import downarrow from "../assets/images/downarrow.png";
import profile from "../assets/images/profile_img.png";

import deleteicon from "../assets/images/deleteicon.png";
import checkicon from "../assets/images/checkicon.png";
import cancel from "../assets/images/cancel.png";
import { colors } from "../common/colors";
import { useAppDispatch } from "../redux/store";
import { reset } from "../redux/Reducers/adminAuthReducer";
import setAuthToken from "../services/axios-util/set-auth-token";
export default function PendingComments() {
  // const [dialogEdit, setDialogEdit] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [pagination, setPagination] = React.useState<any>({});
  const [approve, setApprove] = React.useState(false);
  const [dialog, setDialog] = React.useState("");
  const [snake, setSnake] = React.useState<any>("");
  const dispatch = useAppDispatch();
  const handleMore = async () => {
    if (pagination?.next_page > 0) {
      setLoader(true);
      const comments = await appServices.getPendingComments(
        pagination.next_page
      );
      setLoader(false);
      const newItems = [...data, ...comments.comments];
      setData(newItems);
      setPagination(comments.pagination);
    }
  };
  const handleClose = () => {
    setDialog("");
  };

  const handleDialog = (id: any, approve: boolean) => {
    setDialog(id);
    setApprove(approve);
  };
  const timeouttxt = T("Session_timeout_Please_log_in_again");
  const g = async () => {
    try {
      setLoader(true);
      const comments = await appServices.getPendingComments(1);
      // console.log(comments);
      setLoader(false);
      setData(comments.comments);
      setPagination(comments.pagination);
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.status === 403) {
        setSnake(timeouttxt);
        setTimeout(() => {
          dispatch(reset());
          setAuthToken("__");
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    g();
    // eslint-disable-next-line
  }, []);

  const handleApproveRejected = async (status: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await appServices.acceptRejectComment(dialog, status); //dialog contain id
      // console.log(response, "responseeeeeee");
      g();
      setDialog("");
      setApprove(false);
    } catch (error) {}
  };
  return (
    <TableContainer component={Paper}>
      <LoadingModal visible={loader} handleClose={() => {}} />
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>{T("Comments")}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right" style={{ width: 100 }}>
              <Typography style={{ ...styles.titletable, width: 80 }}>
                {T("Comments")} {data?.length}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.title}>
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <img
                    src={
                      row?.user?.image !== null
                        ? "data:image/jpeg;base64," + row?.user?.image
                        : profile
                    }
                    alt={"userimg"}
                    style={styles.farmProfileIcon}
                  />
                  <Box marginLeft={2} sx={{ width: "100%" }}>
                    <Typography
                      className="truncate"
                      // variant="body2"
                      variant="subtitle1"
                      style={styles.desTable}
                    >
                      {row?.user_nickname}
                    </Typography>
                    <Typography
                      className="truncate"
                      // variant="body2"
                      variant="subtitle1"
                      style={styles.desTable}
                    >
                      {row.content}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right">
                {" "}
                <Button onClick={() => handleDialog(row.id, true)}>
                  {" "}
                  {T("Approve")}
                </Button>
              </TableCell>
              <TableCell align="right">
                <Button
                  color={"error"}
                  onClick={() => handleDialog(row.id, false)}
                >
                  {T("Reject")}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={dialog ? true : false} onClose={() => handleClose()}>
        <img
          src={cancel}
          alt="BigCo Inc. logo"
          onClick={() => handleClose()}
          style={{
            width: 30,
            height: 30,
            alignSelf: "flex-end",
            marginRight: 10,
            marginTop: 10,
          }}
        />
        {approve ? (
          <img
            src={checkicon}
            alt="BigCo Inc. logo"
            style={{
              width: 100,
              height: 100,
              alignSelf: "center",
              color: colors.farm_red600,
            }}
          />
        ) : (
          <img
            src={deleteicon}
            alt="BigCo Inc. logo"
            style={{
              width: 100,
              height: 100,
              alignSelf: "center",
              color: colors.farm_red600,
            }}
          />
        )}
        <Typography
          style={{
            textAlign: "center",
            fontSize: 30,
            marginTop: 20,
            marginBottom: 20,
            height: 20,
          }}
        >
          Are You Sure?
        </Typography>
        <DialogTitle
          style={{
            ...styles.adminUserDialogTitle,
            color: approve ? colors.farm_green500 : colors.farm_red600,
            textAlign: "center",
          }}
        >
          {approve
            ? T("Do_you_really_want_to_APPROVE_this_post")
            : T("Do_you_really_want_to_REJECT_this_post")}
        </DialogTitle>
        <DialogContent>
          <div style={{ ...styles.adminUserDialogContent, marginTop: 0 }}>
            <Button
              onClick={() => handleClose()}
              variant="contained"
              style={{
                textAlign: "center",
                backgroundColor: "gray",
                marginRight: 5,
              }}
            >
              {T("Cancel")}
            </Button>
            {approve ? (
              <Button
                onClick={() => handleApproveRejected("approve")}
                variant="contained"
                color={"success"}
                sx={{ textAlign: "center", alignItems: "center" }}
              >
                {T("Approve")}
              </Button>
            ) : (
              <Button
                onClick={() => handleApproveRejected("reject")}
                variant="contained"
                color={"error"}
                sx={{ textAlign: "center" }}
              >
                {T("Reject")}
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {pagination.next_page > 0 ? (
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button onClick={() => handleMore()}>
            <img
              src={downarrow}
              alt=""
              style={{ width: 20, height: 20, margin: 15 }}
            />
          </Button>
        </div>
      ) : null}
      <Snackbar
        open={snake !== ""}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnake("")}
      >
        <Alert
          onClose={() => setSnake("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snake}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
