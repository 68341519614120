import { Box, Switch, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { colors } from "../../common/colors";
import { styled } from "@mui/material/styles";

interface Props {
  title: string;
  desc?: string; // `desc` can be optional if it's not used
  checked: boolean;
  disabled?:boolean
  onChange(checked: boolean): void; // The argument should be `boolean`
}

function SwitchComponent({
  title,
  desc,
  checked,
  disabled,
  onChange,
}: Props): ReactElement {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked); // Pass only the `checked` value
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    display: "flex",
    alignItems: "center",
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(24px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#1890ff",
          ...theme.applyStyles("dark", {
            backgroundColor: "#177ddc",
          }),
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 20,
      height: 20,
      borderRadius: 10,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 13,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
      ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255,255,255,.35)",
      }),
    },
  }));

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginTop={2}
      >
        <Typography
          style={{
            fontSize: 14,
            flexGrow: 1, // Allows the title to take as much space as possible
            marginRight: 16, // Adds spacing between the text and the switch
            whiteSpace: "normal", // Allows the text to wrap into multiple lines
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box", // Necessary for limiting lines
            WebkitLineClamp: 2, // Limit the text to 2 lines
            WebkitBoxOrient: "vertical",
          }}
        >
          {title}
        </Typography>

        <AntSwitch
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={handleSwitchChange}
        />
      </Box>

      {desc && (
        <Typography
          style={{
            fontSize: 14,
            color: colors.farm_gray200,
            marginTop: 8,
          }}
        >
          {desc}
        </Typography>
      )}
    </Box>
  );
}

export default SwitchComponent;
