import s3UploadFiles from "../services/app-services/s3UploadFiles";


const removeImagesPrefix = (url: any) => {
  const parts = url.split('/images'); // Split URL at '/images/'

  // /images ''
  return parts.length > 1 ? '' + parts[1] : url; // Return the part after '/images/'
};


const uploadMultipleImages = async (images: any) => {
  try {
    // Use API upload directly without AWS-specific types
    let promises = images.map((image: any) =>
      s3UploadFiles.uploadFileAWS(image.file)
    );


    const img = await Promise.all(promises).then((uploadedImgs) => {
      const all = uploadedImgs.map((res) => {
        // Extract path from 'file_url' based on new API response
        const imgPath = { path: removeImagesPrefix(res?.file_url || '') };
        return imgPath;
      });
      return all;
    });


    return img;
  } catch (error) {
    console.error('Error uploading multiple images:', error);
    throw error;
  }
};


const uploadFiles = {
  uploadMultipleImages,
};


export default uploadFiles;