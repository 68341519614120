import { Avatar, Box, Button, Typography } from "@mui/material";
import GCBackButton from "../components/GreenCity/GCBackButton";
import { useNavigate, useLocation } from "react-router-dom";
import Separator from "../components/Separator";
import { colors } from "../common/colors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import profile from "../assets/images/profile_img.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import CancelConfirmationModal from "../components/Models/GreenCity/CancelConfirmation";
import gcServices from "../services/app-services/greenCity/gcServicesR6";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";
import { styles as oldstyles } from "./styles";
import SuccessModal from "../components/Models/GreenCity/SuccessModal";
import OptionsModal2 from "../components/Models/OptionsModal2";
import appServices from "../services/app-services/appServices";

const EventCancelDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const { event } = location.state || {};
  const [openModal, setModal] = useState(false);
  const Users = event?.post_events[0]?.users;
  const [loading, setLoading] = useState(false);
  const [openModalSuccess, setModalSuccess] = useState(false);
  const [openOptions, setOpenOptions] = useState<any>(null);
  const [accountInfo, setAccountInfo] = useState<any>();
  const [modalMessage, setModalMessage] = useState("");
  const [partialPaymentCheck, setPartialPaymentCheck] = useState(false);
  const Options = [
    { title: "投稿を見る", path: "eventPost", disabled: false },
    { title: "チャットする", path: "chat", disabled: false },
    { title: "プライベート投稿を送る", path: "private_users", disabled: false },
    { title: "役割を変更する", path: "", disabled: true },
    { title: "強制退会させる", path: "", disabled: true },
  ];
  const userData = Users?.filter((response: { status: string }) => response.status !== "Cancelled") // Skip cancelled users
  ?.map((response: {user_id:any, nickname: any; application_details: string; name: any; image: string }) => {
    // console.log(response);
    let applicationDetails;

    // Check if application_details is already an array
    if (typeof response.application_details === "string") {
      applicationDetails = JSON.parse(response.application_details);
    } else {
      applicationDetails = response.application_details; // Use as-is if already an array
    }

    // Sum up the total_amount for each user's activities
    const totalAmount = applicationDetails.reduce((sum: number, app: { total_amount: number }) => sum + app.total_amount, 0);

    return {
      user_id : response?.user_id,
      userName: response.name,
      Image: response?.image,
      nickname: response?.nickname,
      totalAmount,
    };
  });

  
  // Calculate the total number of users
  const totalUsers = userData?.length;

  // Calculate the grand total of all amounts
  const grandTotal = userData?.reduce((sum: any, data: any) => sum + data.totalAmount, 0);
  // console.log(event);

  const cancelEvent = async () => {
    try {
      setLoading(true);
      const res = await gcServices?.changePostStatus(event?.id, "cancel");
      setLoading(false);
      if (res.data.success === false) {
        setModalMessage(res?.data?.message);
        setPartialPaymentCheck(true);
        return;
      }
      setModalMessage(" イベントの中止が完了したため、イベントカレンダーからイベントが削除されました。");
      // setModalMessage(`
      //   グループの1ヵ月あたりの支払い可能金額
      //   (10万円/月)に達した為、返金が保留になっています。「終了したイベント」から確認してください。
      // `);
    } catch (error: any) {
      setModalMessage(error.response.data.message);
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const singleUserChat = async (newUser_id: any) => {
    try {
      const existChat = await appServices.singleUserChatExist(groupInfo?.id, newUser_id, user.auth_token);

      // Find chat where both users exist
      const existingChat = existChat?.chats?.find(
        (chat: any) => chat.chat_type === "one_to_one" && chat.users.some((u: any) => u.id === newUser_id) && chat.users.some((u: any) => u.id === user.id)
      );
      if (existingChat) {
        navigate("/userchat", {
          state: {
            chatId: existingChat.id,
            chatUsers: existingChat.users,
            chat_type: "one_to_one",
            isGroupChat: false,
            isCreator: existingChat.creator_id === user.id,
            groupInfo: groupInfo,
          },
        });
      } else {
        const response = await appServices.addSingleUserToChat(groupInfo?.id, newUser_id, user.auth_token);
        if (response?.chat?.id) {
          navigate("/userchat", {
            state: {
              chatId: response.chat.id,
              chatUsers: response.chat.users,
              chat_type: "one_to_one",
              isGroupChat: false,
              isCreator: response.chat.creator_id === user.id,
              groupInfo: groupInfo,
            },
          });
        }
      }
    } catch (error) {
      console.error("Failed to create single user chat:", error);
    }
  };

  const handleSelect = async (path: string) => {
    // console.log("userid: ", openOptions);
    if (path === "chat") {
      singleUserChat(openOptions?.user_id);
    }
    if (path === "private_users") {
      // console.log("user: ", openOptions);
      navigate("/createGroupPost", {
        state: {
          post_type: "private_users",
          preUsers: [
            {
              nickname: openOptions?.nickname,
              user_id: openOptions?.user_id,
              image: openOptions?.Image,
            },
          ],
        },
      });
    } else if (path === "eventPost") {
      navigate("/viewGroupPost", {
        state: {
          post_type: "user",
          user: {
            // nickname: user?.nickname,
            // id: user?.id,
            // image: user?.image,
            nickname: openOptions?.nickname,
            id: openOptions?.user_id,
            image: openOptions?.image,
          },
        },
      });
    }
  };

  const getGroupBalance = async () => {
    try {
      const res = await gcServices.getGroupBalance(groupInfo?.id);
      // console.log(res?.wallet_information?.balance?.money?.amount);
      setAccountInfo(res?.wallet_information?.balance?.money?.amount);
    } catch (error) {
      console.error(error);
    }
  };

  function copyTextFallback(text: string) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("リンクをコピーしました");
  }

  function copyToClipboard(text: string) {
    try {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("リンクをコピーしました");
        })
        .catch((err) => {
          console.error("Clipboard write failed, using fallback:", err);
          copyTextFallback(text);
        });
    } catch (err) {
      console.error("Clipboard API not supported, using fallback:", err);
      copyTextFallback(text);
    }
  }

  useEffect(() => {
    getGroupBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <div
        style={{
          paddingTop: 100,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />
        <OptionsModal2
          description={openOptions?.description}
          avatar={openOptions?.Image !== null ? "data:image/jpeg;base64," + openOptions?.Image : profile}
          userName={openOptions?.nickname}
          open={openOptions !== null}
          onClose={() => setOpenOptions(null)}
          options={Options}
          onSelect={handleSelect}
        />
        <CancelConfirmationModal
          open={openModal}
          message={"イベントを中止してよろしいですか？有料イベントの場合は参加費が返金されます。"}
          yesText="はい"
          noText="閉じる"
          showButton
          onContinue={() => {
            setModal(false);
            cancelEvent();
            setModalSuccess(true);
          }}
          onClose={() => {
            setModal(false);
          }}
        />{" "}
        {modalMessage && (
          <SuccessModal
            buttonTitle={partialPaymentCheck ? "とじる" : "閉じる"}
            open={openModalSuccess}
            message={<Typography style={{ textAlign: "center" }}>{modalMessage}</Typography>}
            showButton
            onContinue={() => {
              setModalSuccess(false);
              if (partialPaymentCheck) {
                copyToClipboard(modalMessage);
              }
              setTimeout(() => {
                navigate("/myfarm");
              }, 1000);
            }}
            onClose={() => {
              setModalSuccess(false);
            }}
          />
        )}
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box style={styles.eventPostingBox}>
          <Typography style={{ fontWeight: "700" }}>
            <img
              src={`${groupInfo?.groupImage}`}
              alt={"user"}
              style={{
                ...oldstyles.farmProfileIcon,
                height: 50,
                width: 50,
                margin: 0,
              }}
            />
            &nbsp;{groupInfo?.groupName}
          </Typography>
        </Box>
      </div>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          width: "100%",
          paddingTop: "10px",
          padding: "10px 15px",
        }}
      >
        グループの「めぶくPay」残高: {accountInfo}円
      </Box>
      <Box sx={{ padding: "10px 15px" }}>
        <Typography sx={{ marginTop: 2 }}>イベント申込者と参加費金額</Typography>
        <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
        {userData?.map((user: any, index: number) => (
          <Box key={index}>
            <Box sx={styles.UserBox}>
              <Typography>{index + 1}</Typography>
              <Box sx={styles.UserDetail}>
                <Avatar sx={{ width: 30, height: 30 }} src={user.Image !== null ? "data:image/jpeg;base64," + user?.Image : profile} alt={"userimg"} />
                <Typography>{user?.nickname}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "50px", marginLeft: "auto" }}>
                <Typography>{user?.totalAmount}円</Typography>
                <Box sx={{ margin: "0 5px" }} style={styles.optionButton} onClick={() => setOpenOptions(user)}>
                  <MoreHorizIcon sx={styles.MoreOptions} />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>イベント申込者数: {totalUsers}人</Typography>
          <Typography sx={{ fontWeight: "bold" }}>返金額合計: {grandTotal}円</Typography>
        </Box>
        <Button
          color="secondary"
          onClick={() => setModal(true)}
          sx={styles.submitButton}
          size={"large"}
          variant="outlined"
          endIcon={<ArrowForwardIosIcon sx={{ position: "absolute", right: 0, top: 0, margin: "10px" }} />}
        >
          イベントを中止する
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  topButtonContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
  },
  headBox: {
    paddingTop: 100,
    paddingLeft: 16,
    paddingBottom: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  BoxIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    padding: 1.5,
  },

  ApplicationFilterBox: {
    padding: "30px 20px",
    backgroundColor: "#F4F4F4",
    marginTop: 4,
    borderRadius: 2,
  },
  ApplicationFilter: {
    marginTop: 2,
    display: "flex",
    gap: 1,
    padding: "12px 10px",
    borderRadius: "5px",
  },
  UserBox: {
    borderBottom: "1px gray solid",
    marginTop: "10px",
    textAlign: "left",
    paddingBottom: "8px",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  UserDetail: {
    display: "flex",
    alignItems: "center",
    marginLeft: 2,
    gap: 1,
  },
  MoreOptions: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "25px",
  },
};
export default EventCancelDetails;
