import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
// import ButtonPrimary from "../../ButtonPrimary";
import { styles } from "../../../pages/styles";

// Define the props interface
interface SuccessModalProps {
  open: boolean;
  showButton?: boolean;
  message: any;
  onClose: () => void;
  onContinue: () => void;
  buttonTitle?: string;
  variantBtn?: "contained" | "text" | "outlined";
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  open,
  onClose,
  onContinue,
  showButton,
  message,
  buttonTitle = "次",
  variantBtn = "contained",
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="body1">{message}</Typography>
        <Button
          style={{
            ...styles.introSliderBtn,
            marginTop: "40px",
            width: 100,
           
          }}
          size={"large"}
          variant={variantBtn}
          onClick={onContinue}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
