import { Avatar, Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import style from "./styles";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonOutlinedCentered from "../../../components/ButtonOutlined";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";

import { AddTransactionDetails, RemoveTransactionDetails } from "./PaymentHistory";

const GroupPaymentHistory = () => {
  const { user } = useSelector((state: any) => state.userAuth);
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const currentMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const years = Array.from({ length: 50 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const [payments, setPayments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const dropdownOptions = ["全て", "収入", "支出"];
  const [selectedOption, setSelectedOption] = useState("全て");

  const handleAPICall = (page: number) => {
    setLoading(true);
    gcServices.getGroupPaymentInfo(groupInfo.id, selectedYear.toString(), selectedMonth.toString()).then((res) => {
      setPayments(res.payments);
      setLoading(false);
    });
  };

  const showAdd = (item: any) => {
    return item.payee.id === groupInfo.id && item?.payee?.type==='MemberGroup';
  };

  const filterData = () => {
    if (selectedOption === "全て") {
      return payments;
    } else if (selectedOption === "収入") {
      return payments.filter((item: any) => item.payee.id === groupInfo?.id);
    } else if (selectedOption === "支出") {
      return payments.filter((item: any) => item.payee.id !== groupInfo.id);
    }
  };

  useEffect(() => {
    handleAPICall(1);
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.rowMargin}>
          <Avatar src={"data:image/jpeg;base64," + user.image} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>利用履歴</Typography>
        </Box>
      </Box>
      <Box sx={style.container}>
        <Typography sx={style.titleSeprator}>めぶくPay 利用履歴</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedYear} 年
            </MenuItem>
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} displayEmpty sx={{ width: "30%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedMonth} 月
            </MenuItem>
            {months.map((mn) => (
              <MenuItem key={mn} value={mn}>
                {mn}
              </MenuItem>
            ))}
          </Select>

          <ButtonOutlinedCentered onClick={() => handleAPICall(1)} title="表示する" />
        </Box>
        <Select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} displayEmpty sx={{ height: "40px", width: "100%", marginTop: "10px" }}>
          {dropdownOptions.map((mn) => (
            <MenuItem key={mn} value={mn}>
              {mn}
            </MenuItem>
          ))}
        </Select>

        {!loading ? (
          filterData()?.map((item) => <Box sx={{ marginTop: "20px" }}>{showAdd(item) ? <AddTransactionDetails item={item} /> : <RemoveTransactionDetails item={item} />}</Box>)
        ) : (
          <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GroupPaymentHistory;
