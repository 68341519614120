import React, { useState } from "react";
import { Modal, Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { colors } from "../../../common/colors";

// Define the props interface
interface LeaveGroupConfirmationProps {
  open: boolean;
  showButton?: boolean;
  headerText?: string;
  message1?: string;
  message2?: string;
  message3?: string;
  onClose: () => void;
  onContinue: () => void;
  noText?: string;
  yesText?: string;
  showCheckBoxText?:string;
}

const LeaveGroupConfirmation: React.FC<LeaveGroupConfirmationProps> = ({ open, onClose, onContinue, showCheckBoxText,headerText, message1, message2, message3, noText, yesText }) => {
  //   const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  //   const [showMainModal, setShowMainModal] = useState(open);
  const [check1,setCheck1]=useState(false);

  // Update showMainModal when 'open' prop changes
  //   useEffect(() => {
  //     setShowMainModal(open);
  //   }, [open]);

  //   const handleDelete = () => {
  //     setShowMainModal(false); // Hide first modal
  //     onContinue();
  //     setShowSuccessPopup(true);
  //     setTimeout(() => {
  //       setShowSuccessPopup(false);
  //       onClose();
  //     }, 1000);
  //   };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "30px",
            // opacity: showMainModal ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center", paddingTop: "10px" }}>
            {headerText}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center", padding: "5px 20px" }}>
            {message1}
          </Typography>

          <Typography variant="body1" sx={{  textAlign: "center", padding: "5px 20px" }}>
            {message2}
          </Typography>

          <Typography variant="body1" sx={{ mb: 2, textAlign: "center", padding: "5px 5px" }}>
            {message3}
          </Typography>

          <Box   sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%", // Ensures it takes full width
              
              }} >
          {showCheckBoxText&& <FormControlLabel
            control={<Checkbox checked={check1} onChange={(e) => setCheck1(e.target.checked)} />}
            color="error"
            sx={{
              color: false ? colors.farm_red600 : "black",
              marginTop: "20px",
            }}
            label={showCheckBoxText}
          />}
          </Box>
         

          
         

          <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
              sx={{
                cursor: !check1 && showCheckBoxText ? "default" : "pointer",
                pointerEvents: !check1 && showCheckBoxText ? "none" : "auto",
                opacity: !check1 && showCheckBoxText ? 0.5 : 1, // Optional: Dim the button when disabled
                color: colors.farm_white,
                textAlign: "center",
                backgroundColor: colors.farm_blue200,
                border: "2px solid " + colors.farm_blue200,
                margin: "0px 30px",
                borderRadius: "10px",
                borderLeft: "none",
                padding: "10px",
              }}
              onClick={!check1 && showCheckBoxText ? undefined : onContinue} // Prevent function execution when disabled
            >
              {yesText ? yesText : "消去"}
            </Typography>
            <br></br>
           {noText!=='dontshowno'&& <Typography
              sx={{
                cursor: "pointer",
                textAlign: "center",
                padding: "10px",
                color: colors.farm_blue200,
                margin: "5px 30px 30px 30px",
                borderRadius: "10px",
                border: "2px solid " + colors.farm_gray100,
              }}
              onClick={onClose}
            >
              {noText ? noText : "近い"}
            </Typography>}
          </Box>
          <br/>
        </Box>
       
      </Modal>

      {/* <Modal open={showSuccessPopup}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            padding: "20px",
            opacity: showSuccessPopup ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            削除されました
          </Typography>
        </Box>
      </Modal> */}
    </>
  );
};

export default LeaveGroupConfirmation;
