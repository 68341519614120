import React, { useEffect } from "react";
import {
  colors,
  ImageList,
  ImageListItem,
  ListItem,
  Typography,
  CircularProgress,
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

import { colors as farmColor } from "../../common/colors";
import styled from "styled-components";
import { T } from "react-translator-component";
import CommentIcon from "@mui/icons-material/Comment";
import { styles } from "../../pages/styles";
//   import EventDetailSeeMore from "../Phase2/EventDetailSeeMore";
import Thumbnail from "../../components/Phase2/Thumbnail";
import { faThumbsUp as thumbup } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import EditIcon from "@mui/icons-material/Edit";
import CommentsModel from "../../components/Models/CommentsModel";
import Separator from "../../components/Separator";
import { styles as oldstyles } from "../../pages/styles";
import HowToVoteIcon from "@mui/icons-material/HowToVote";

import appServices from "../../services/app-services/appServices";
import VotesModel from "../../components/Models/VotesModel";
import dayjs from "dayjs";
import EventPostDetailSeeMore from "../../components/Phase2/EventPostDetailSeeMore";
import GCBackButton from "../../components/GreenCity/GCBackButton";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  height: 85%;
  overflow-y: hidden;
  z-index: 9999;
`;

export default function EventPostDetails() {
  const [showCommentsModal, setShowCommentsModal] = React.useState(0);
  const [showVotesModal, setShowVotesModal] = React.useState(0);
  const location = useLocation();
  const { item } = location.state || {};

  // console.log(item);

  const navigate = useNavigate();

  const [reportConfirmation, setReportConfirmation] = React.useState(0);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  //   const { user } = useSelector((state: any) => state.userAuth);

  const [thumbsUp, setThumbsUp] = React.useState(false);

  const like = async (item: any) => {
    // setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, "like");
      // Optimistically update the UI here
      item.liked_by_me = true;
    } catch (error) {
      console.error("Error while liking:", error);

      // setLikeLoading(false);
    }
    // console.log('like')
  };

  const unlike = async (item: any) => {
    // setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, "delete_like");
      // Optimistically update the UI here
      item.liked_by_me = false;
    } catch (error) {
      // setLikeLoading(false);
    }
    // console.log("unlike");
  };

  const handleThumbUp = async (item: any) => {
    // setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        unlike(item);
      } else {
        like(item);
        // setLikeCount(likeCount + 1); // Increase the like count
      }
      setThumbsUp(!thumbsUp);
      // Optimistic UI update for liked_by_me
      item.liked_by_me = !item.liked_by_me;
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      // setLikeLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formatEventDate = (start_date: any, end_date: any) => {
    const startDate = dayjs(start_date);
    const endDate = dayjs(end_date);

    if (startDate.isSame(endDate, "day")) {
      // If start and end dates are the same
      return startDate.format("YYYY年M月D日(ddd)");
    } else {
      // If start and end dates are different
      return `${startDate.format("YYYY年M月D日(ddd)")}~${endDate.format(
        "M月D日(ddd)"
      )}`;
    }
  };
  return (
    <div
      style={{
        marginTop: "90px",
        borderBottomWidth: 4,
        overflow: "hidden",
        display: "block",
      }}
    >
      <GCBackButton title="もどる" onClick={() => navigate(-1)} />{" "}
      <ImageList style={{ display: "block" }} cols={1}>
        {/* {items?.map((item, index) => {
          console.log(item);

          return ( */}
        <ImageListItem
          sx={{
            backgroundColor: farmColor.farm_gray10,
            borderRadius: 2,
          }}
          //   key={index}
        >
          <ListItem
            className=""
            alignItems="flex-start"
            style={{
              ...styles.detailListItem,
              borderBottomStyle: "solid",
              borderTopStyle: "solid",
              paddingLeft: 0,
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Box style={style.eventPostingBox}>
              <Typography style={{ fontWeight: "700" }}>
                <img
                  src={`${groupInfo?.groupImage}`}
                  alt={"user"}
                  style={{
                    ...oldstyles.farmProfileIcon,
                    height: 50,
                    width: 50,
                    margin: 0,
                  }}
                />
                &nbsp;{groupInfo?.groupName}
              </Typography>
            </Box>

            <Dialog
              open={reportConfirmation === item.id}
              onClose={() => setReportConfirmation(0)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {T("Report")} {/* //are you sure? */}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  この投稿を本当に報告しますか?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color={"secondary"}
                  onClick={() => setReportConfirmation(0)}
                >
                  {T("No")}
                </Button>
                <Button
                  sx={{ fontFamily: "mplus-1c-bold" }}
                  color={"secondary"}
                  //   onClick={() => {
                  //     handleReport(item?.id, "report");
                  //   }}
                  autoFocus
                >
                  {T("Yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </ListItem>
          <div>
            <Thumbnail deleteItem={() => {}} item={item} />
          </div>
          <div style={{ justifyContent: "center" }}>
            {false ? (
              <CircularProgress
                style={{
                  marginTop: 10,
                  marginBottom: -8,
                  marginLeft: 8,
                  marginRight: 8,
                }}
                size={24}
              />
            ) : (
              <IconButton
                onClick={() => handleThumbUp(item)}
                aria-label="thumbs"
              >
                <FontAwesomeIcon
                  size={"lg"}
                  icon={item?.liked_by_me ? thumbup : faThumbsUp}
                  color={
                    item?.liked_by_me
                      ? farmColor.farm_green500
                      : farmColor.farm_gray200
                  }
                />
              </IconButton>
            )}
            <span style={{ paddingTop: 6, fontSize: 16 }}>
              いいね！{item?.likes_count}件
            </span>
          </div>
          <Typography
            style={{
              marginLeft: 6,
              marginRight: 6,
              fontSize: 20,
              fontWeight: "600",
            }}
          >
            {item.title}
          </Typography>

          <Typography sx={{ color: "#A2A2A2", fontSize: 12, marginLeft: 1 }}>
            イベント開催日：
            {formatEventDate(
              item?.post_events[0]?.start_date,
              item?.post_events[0]?.end_date
            )}
          </Typography>

          <EventPostDetailSeeMore item={item} />
          <Separator color={colors.grey[400]} sx={{ marginTop: 2 }} />

          <Box sx={{ width: "100%" }}></Box>

          <Stack direction="row" spacing={2}>
            {item?.enable_comments && (
              <Box>
                <IconButton
                  onClick={() => setShowCommentsModal(item.id)}
                  aria-label="thumbs"
                >
                  <CommentIcon />
                  <Typography>
                    コメント{item.comments_count} 件を読む
                  </Typography>
                </IconButton>
              </Box>
            )}

            {item?.enable_participants && (
              <Box>
                <IconButton
                  onClick={() => setShowVotesModal(item.id)}
                  aria-label="thumbs"
                >
                  <HowToVoteIcon />
                  <Typography>投票する</Typography>
                </IconButton>
              </Box>
            )}
          </Stack>

          {showCommentsModal === item.id && (
            <ModalBackground onClick={() => setShowCommentsModal(0)}>
              <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                <CommentsModel
                  comments={item.comments}
                  post_id={item.id}
                  closeModal={() => setShowCommentsModal(0)}
                />
              </ModalContainer>
            </ModalBackground>
          )}
          {showVotesModal === item.id && (
            <ModalBackground onClick={() => setShowVotesModal(0)}>
              <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                <VotesModel
                  post={item}
                  closeModal={() => setShowVotesModal(0)}
                />
              </ModalContainer>
            </ModalBackground>
          )}
        </ImageListItem>
        {/* ); */}
        {/* })} */}
      </ImageList>
    </div>
  );
}

const style = {
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "left",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
};
