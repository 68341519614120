import { Stack, Button, SxProps } from "@mui/material";
// import { colors } from "../common/colors";
import { styles } from "../pages/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
interface Props {
  title: string;
  sx?: SxProps;
  onClick(): void;
  pLR?: number;
}

function ButtonPrimary({ title, onClick, pLR }: Props) {
  return (
    <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", m: 4 }}>
      <Button
        onClick={onClick}
        style={{
          ...styles.introSliderBtn,
          marginTop: "40px",
          width: 220,
        }}
        size={"large"}
        variant="contained"
        endIcon={<ArrowForwardIosIcon />}
      >
        <span style={{ paddingRight: 20, paddingLeft: 20, width: "100%" }}>{title}</span>
      </Button>
    </Stack>
  );
}

export default ButtonPrimary;
