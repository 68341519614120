import React, { useState, useCallback, useRef, useEffect } from "react";
import { TextField, InputAdornment, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios, { CancelTokenSource } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setGcSearchText, clearGcSearchText } from "../../redux/Reducers/gcSearchReducer";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";

interface SearchBarProps {
  onResults: (data: any[]) => void;
  reSearch: boolean;
  focus?: boolean;
}

const GroupSearchbar: React.FC<SearchBarProps> = ({ onResults, reSearch, focus = false }) => {
  const dispatch = useDispatch();
  const searchText = useSelector((state: any) => state.gcSearch.searchText);
  const location = useLocation(); // Get current URL

  const [searchTerm, setSearchTerm] = useState<string>(searchText);
  const [loading, setLoading] = useState(false);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  // ✅ Define fixed paths where search should NOT be cleared
  const pathsToPreserve = ["/group_search", "/groupleader", "/groupinforeview"];

  // ✅ Check if current path starts with any fixed path
  const isPathPreserved = pathsToPreserve.some(path => location.pathname.startsWith(path));

  // Debounce function to limit API calls
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  // Fetch search results
  const fetchSearchResults = async (term: string) => {
    if (!term.trim()) {
      setLoading(false);
      onResults([]);
      return;
    }

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Canceled due to new request.");
    }
    cancelTokenRef.current = axios.CancelToken.source();

    try {
      setLoading(true);
      const posts = await gcServices.search(term, {
        cancelToken: cancelTokenRef.current.token,
      });
      onResults(posts);
      dispatch(setGcSearchText(term)); // Save search term in Redux
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error fetching search results:", error);
        onResults([]);
      }
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of fetchSearchResults
  // eslint-disable-next-line
  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, 800),
    []
  );

  // Handle input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedFetchSearchResults(term);
  };

  // Watch for reSearch state
  useEffect(() => {
    if (reSearch && searchTerm) {
      debouncedFetchSearchResults(searchTerm);
    }
    // eslint-disable-next-line
  }, [reSearch]);

  // Restore search text when coming back
  useEffect(() => {
    if (searchText) {
      setSearchTerm(searchText);
      fetchSearchResults(searchText);
    }
    // eslint-disable-next-line
  }, [searchText]);

  // ✅ Clear search when navigating away from fixed paths
  useEffect(() => {
    // console.log('here',isPathPreserved)
    if (!isPathPreserved) {
      // console.log('here')
      dispatch(clearGcSearchText());
      setSearchTerm(""); // Clear local state
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Search..."
      value={searchTerm}
      onChange={handleChange}
      focused={focus}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: loading ? (
          <InputAdornment position="end">
            <CircularProgress size={24} />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default GroupSearchbar;
