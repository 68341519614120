import { Box } from "@mui/material";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import IconButtonArrow from "../../../components/GreenCity/IconButtonArrow";
import profile_placeholder from "../../../assets/images/profile_img.png";
import { useSelector } from "react-redux";
import { getChatUserName } from "../../../util/utils";
import ChatIcon from "@mui/icons-material/Chat";

const UserChatList = () => {
  const navigate = useNavigate();
  const [allChats, setAllChats] = useState([]);
  const { user } = useSelector((state) => state.userAuth);

  useEffect(() => {
    gcServices.getAllChatList().then((res) => {
      setAllChats(res.chats);
    });
  }, []);
  return (
    <Box>
      <Box sx={styles.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <p style={{ marginTop: 10, fontSize: 14, fontWeight: 600, marginLeft: 10 }}>
          <ChatIcon /> チャットする
        </p>
      </Box>

      <Box sx={{ marginTop: "10px" }} display="flex" flexDirection="column">
        {allChats.map((chat) => {
          let icon;

          if (chat.chat_type === "one_to_one") {
            icon = (
              <img style={styles.image} src={chat.users[1]?.image ? `data:image/png;base64,${chat.users[1].image}` : profile_placeholder} alt={chat.users[0]?.nickname || "User"} />
            );
          } else if (chat.chat_type === "one_to_many") {
            icon = (
              <div style={{ display: "flex" }}>
                {chat.users.slice(1, 6).map((user, index) => (
                  <img
                    key={user.id}
                    alt="User"
                    style={{ ...styles.image, marginLeft: index === 0 ? "0px" : "-10px" }}
                    src={user.image ? `data:image/png;base64,${user.image}` : profile_placeholder}
                  />
                ))}
              </div>
            );
          } else {
            icon = (
              <img
                style={{ width: "26px" }}
                src={chat.member_group_image ? `data:image/png;base64,${chat.member_group_image}` : require("../../../assets/images/group_chat.png")}
                alt={chat.member_group_name || "Group"}
              />
            );
          }

          return (
            <IconButtonArrow
              key={chat.id} // Use a unique key in a real app
              icon={icon} // Pass the icon variable
              title={chat.chat_type === "group" ? chat.member_group_name || "No Group Name" : getChatUserName(chat.users, user.id)}
              role={""}
              onClick={() => {
                if(chat?.id){
                navigate("/userchat", {
                  state: {
                    chatId: chat.id,
                    chatUsers: chat.users,
                    chat_type: chat.chat_type,
                    isGroupChat: chat.chat_type === "group",
                    isCreator: chat.creator_id === user.id,
                    groupMembers: [],
                    groupInfo: { id: chat.member_group_id, name: chat.member_group_name },
                  },
                });
              }
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default UserChatList;

const styles = {
  headerBox: {
    padding: "1px 16px",
    paddingTop: "100px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
  },
  image: {
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    objectFit: "cover",
  },
};
