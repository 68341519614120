import { Box, Typography, TextField, Button, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { colors } from "../../common/colors";
import ButtonOutlinedCentered from "../../components/ButtonOutlined";
import { styles } from "../styles";
import { useAppDispatch } from "../../redux/store";
import { setGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import { useSelector } from "react-redux";
import { isIOS } from "../../util/utils";

const defaultImage = require("../../assets/images/placeholder.png");
const ERROR_MESSAGE = "＊未入力の項目があります。";
function CreateGroupStep1(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [groupName, setGroupName] = useState("");
  const [image, setImage] = useState<any>();
  const { user } = useSelector((state: any) => state.userAuth);
  const [imageFile, setImageFile] = useState<any>();
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [errors, setErrors] = useState({
    groupName: false,
    groupImage: false,
  });
  const [isPrivate, setIsPrivate] = useState("PUBLIC");

  useEffect(() => {
    // console.log(groupInfo);
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);

  const handleIsPrivateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //public private
    setIsPrivate((event.target as HTMLInputElement).value);
  };

  const fileInputRef = useRef<any>();

  const handleButtonClick = () => {
    fileInputRef?.current.click();
  };

  const handleImageUploadAndroid = () => {
    try {
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };
  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (event?.data === "" || event?.data === null || event?.data === undefined) return;
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });

          if (file) {
            setImageFile(file);

            const imageUrl = URL.createObjectURL(file); // Generate URL from the file
            setImage(imageUrl); // Set the image URL
            setErrors((prev) => ({ ...prev, groupImage: false }));
          }
        } catch (error) {
          // alert("error" + error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file); // Generate URL from the file
      setImage(imageUrl); // Set the image URL
      setErrors((prev) => ({ ...prev, groupImage: false }));
    }
  };

  const handleNextStep = () => {
    if (groupName.trim().length < 3) {
      setErrors({
        ...errors,
        groupName: true,
      });
      return;
    }

    if (!image) {
      setErrors({
        ...errors,
        groupImage: true,
      });
      return;
    }

    if (image && groupName.trim().length > 3) {
      dispatch(
        setGroupInfo({
          id: groupInfo?.id || "",
          edit: groupInfo?.id ? true : false,
          groupName,
          groupIsPublic: isPrivate === "PUBLIC",
          groupImage: imageFile || image,
        })
      );

      navigate("/steppii");
      // navigate("/creategroupsteptwo");
    }
  };

  useEffect(() => {
    if (groupInfo) {
      setGroupName(groupInfo?.groupName);
      let isPublic;
      if (typeof groupInfo?.groupIsPublic === "string") {
        isPublic = groupInfo?.groupIsPublic.toUpperCase(); // Convert string to uppercase
        // console.log("here", groupInfo?.groupIsPublic);
      } else if (typeof groupInfo?.groupIsPublic === "boolean") {
        isPublic = groupInfo?.groupIsPublic === true ? "PUBLIC" : "PRIVATE"; // Convert boolean to YES/NO
        // console.log("here", groupInfo?.groupIsPublic);
      } else {
        // console.log("here", groupInfo?.groupIsPublic);
      }

      setIsPrivate(isPublic);

      if (groupInfo.groupImage) {
        const icon = groupInfo?.groupImage instanceof File ? URL.createObjectURL(groupInfo.groupImage) : `${groupInfo?.groupImage}` || defaultImage;

        setImage(icon); // Set the image URL
      }
    }
  }, [groupInfo]);

  return (
    <div style={{ paddingTop: 100, height: "100vh" }}>
      <Box>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <PeopleAltOutlinedIcon style={{ fontSize: 20 }} />
          <Typography style={{ fontSize: 14 }}>&nbsp; グループを作る</Typography>
        </Box>
        <Box
          style={{
            backgroundColor: colors.farm_gray15,
            padding: 16,
          }}
        >
          <Typography
            style={{
              borderBottomColor: colors.farm_green150,
              borderBottomWidth: 2,
              borderBottomStyle: "solid",
              fontSize: 14,
            }}
          >
            グループ名（必須）
          </Typography>
          <TextField
            fullWidth
            hiddenLabel
            id="outlined-basic"
            error={errors.groupName}
            helperText={errors.groupName ? ERROR_MESSAGE : " "}
            variant="outlined"
            // disabled={groupInfo?.id}
            value={groupName}
            onChange={(e: any) => {
              setGroupName(e?.target?.value);
              if (e?.target?.value.length > 3) {
                setErrors({ ...errors, groupName: false });
              }
            }}
            InputProps={{
              style: {
                height: 40, // Set your desired height here
                padding: "4px 8px", // Adjust padding to fit your design
              },
            }}
            style={{ marginTop: 6 }} // Keep margin top if needed
          />

          <Box style={{ marginTop: 40 }}>
            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                fontSize: 14,
              }}
            >
              グループアイコン（必須）
            </Typography>
            {errors.groupImage && <Typography sx={{ color: colors.farm_red600 }}>{ERROR_MESSAGE}</Typography>}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 10,
              }}
            >
              <img
                alt=""
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 50,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: colors.farm_green500,
                }}
                src={image || defaultImage}
              />
            </Box>
            <div>
              {isIOS(user?.client_id) ? (
                <>
                  <ButtonOutlinedCentered
                    title={"画像を選ぶ"}
                    onClick={handleButtonClick}
                    //  disabled={groupInfo?.id}
                    pLR={6}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the file input
                  />
                </>
              ) : (
                <ButtonOutlinedCentered
                  title={"画像を選ぶ"}
                  onClick={handleImageUploadAndroid}
                  // disabled={groupInfo?.id}
                  pLR={6}
                />
              )}
            </div>

            <Typography
              style={{
                borderBottomColor: colors.farm_green150,
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
              }}
            >
              &nbsp;グループ公開設定（必須）。
            </Typography>
            <RadioGroup aria-labelledby="payment-radio-buttons-group-label" value={isPrivate} onChange={handleIsPrivateChange} name="radio-buttons-group">
              <FormControlLabel
                value="PUBLIC"
                disabled={groupInfo?.id}
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">公開にする</Typography>

                    <Typography variant="body2" color="textSecondary">
                      めぶくコミュニティの全員が閲覧可能なグループです。
                    </Typography>
                    <br />
                  </div>
                }
              />
              <FormControlLabel
                value="PRIVATE"
                disabled={groupInfo?.id}
                control={<Radio sx={{ alignSelf: "flex-start" }} />} // Aligns the radio button to the top
                label={
                  <div>
                    <Typography variant="body1">非公開にする</Typography>

                    <Typography variant="body2" color="textSecondary">
                      招待されたユーザーのみ閲覧可能なグループです。
                    </Typography>
                    <br />
                  </div>
                }
              />
            </RadioGroup>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleNextStep();
                }}
                style={{
                  ...styles.introSliderBtn,
                  marginTop: 40,
                  width: 220,
                }}
                size={"large"}
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
              >
                <span style={{ paddingRight: 30, paddingLeft: 40 }}>つぎへ</span>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default CreateGroupStep1;
