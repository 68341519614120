import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginNavigator,
  resetMessage,
} from "../../redux/Reducers/userAuthReducer";
import { useAppDispatch } from "../../redux/store";
import setAuthorizationToken from "../../services/axios-util/set-authorization-token";
import "./NavigatorLogin.css";
import imageCompression from "browser-image-compression";
import LoadingModal from "../../components/LoadingModal";

import { Alert, Snackbar } from "@mui/material";
const NavigatorLogin = () => {
  const navigate = useNavigate();
  const [snake, setSnake] = useState("");

  const { loading, user, message } = useSelector(
    (state: any) => state.userAuth
  );

  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    name: "Navigator",
    nickname: "Navigator",
    email: "",
    password: "",
    image: "",
  });

  useEffect(() => {
    if (message) {
      setSnake(message);
      setTimeout(() => {
        dispatch(resetMessage());
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Define compression options
        const options = {
          maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
          maxWidthOrHeight: 100, // (default: undefined)
          useWebWorker: true, // (default: true)
        };

        // Compress the file
        const compressedFile = await imageCompression(file, options);

        // Convert compressed file to base64
        const base64: any = await fileToBase64(compressedFile);
        const cleanBase64 = removeBase64Prefix(base64);

        setFormData({
          ...formData,
          image: cleanBase64,
        });

        // console.log(base64);
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    }
  };
  const removeBase64Prefix = (base64: string) => {
    return base64.replace(/^data:image\/[a-z]+;base64,/, "");
  };

  const fileToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  // const handleImageChange = (e: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (reader.result && typeof reader.result === "string") {
  //         setFormData({
  //           ...formData,
  //           image: file,
  //           imageBase64: reader.result,
  //         });
  //         console.log(reader.result);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // console.log(formData);
    // Add your submit functionality here

    dispatch(loginNavigator(formData));
  };

  useEffect(() => {
    if (user.auth_token) {
      setAuthorizationToken(user.auth_token);

      navigate("/home");
    } else {
      setAuthorizationToken(user.auth_token);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="signup-container">
      <LoadingModal
        visible={loading}
        handleClose={() => {
          // console.log("");
        }}
      />
      <div className="signup-image">
        <img
          style={{ height: 300 }}
          src={require("../../assets/images/logo2x.png")}
          alt="Navigator"
        />
      </div>
      <div className="signup-form">
        <h2>Mebuku Navigator user login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              maxLength={12}
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="nickname">Nick Name</label>
            <input
              maxLength={12}
              type="text"
              id="nickname"
              name="nickname"
              value={formData.nickname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">Upload Image</label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="button" style={{ width: 200 }} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        open={snake !== ""}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnake("")}
      >
        <Alert
          onClose={() => setSnake("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snake}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NavigatorLogin;
