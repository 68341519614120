import React, { ReactElement, useEffect } from "react";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import TextButton from "../../components/GreenCity/TextButton";
import Separator from "../../components/Separator";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import appServices from "../../services/app-services/appServices";
import EventDetailsList from "../../components/lists/EventDetailsList";
import LoadingModal from "../../components/LoadingModal";
import { useSelector } from "react-redux";
import { T } from "react-translator-component";

const AddIcon = () => {
  return (
    <div style={styles.addIcon}>
      <AddRoundedIcon sx={{ width: 20, height: 20 }} />
    </div>
  );
};
function GroupEventSettingPage(): ReactElement {
  const navigate = useNavigate();
  const groupInfo = useSelector((state: any) => state.gcGroup);

  const [events, setEvents] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [groupInformation] = React.useState(groupInfo);
  const [privatePagination, setPrivatePostsPagination] = React.useState<any>(
    {}
  );

  const getEvents = async () => {
    setLoading(true);
    const response = await appServices.getGroupEventsPagination(
      groupInformation.id,
      1,
      "desc"
    );
    const filteredPosts = response.posts.filter(
      (post: { status: string }) => post.status !== "Cancelled"
    );
    setEvents(filteredPosts);
    setPrivatePostsPagination(response.pagination);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMore = async () => {
    if (privatePagination?.next_page > 0) {
      try {
        setLoading(true);
        const response = await appServices.getGroupEventsPagination(
          groupInformation.id,
          privatePagination?.next_page,
          "desc"
        );
        const filteredPosts = response.posts.filter(
          (post: { status: string }) => post.status !== "Cancelled"
        );
        const newItems = [...events, ...filteredPosts];
        setEvents(newItems);
        setPrivatePostsPagination(response.pagination);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ paddingTop: 100 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} handleClose={() => {}} />

      <Box component="div" style={styles.topButtonContainer}>
        <div>
          <Typography style={styles.everyonePostStyle}>イベント投稿</Typography>
        </div>
        {(groupInfo?.role === "leader" || groupInfo?.role === "sub-leader") && (
          <TextButton
            onClick={() =>
              navigate("/createnewevent", {
                state: {
                  groupInfo: groupInfo,
                },
              })
            }
            Icon={AddIcon}
            title="投稿する"
          />
        )}
      </Box>
      <Separator sx={{ marginTop: "10px" }} color="gray" />
      {events?.map((event: any) => (
        <Box key={event.id}>
          <EventDetailsList
            items={[event]}
            page={""}
            order={""}
            handleReport={function (post_id: number, status: any): void {
              throw new Error("Function not implemented.");
            }}
            onLike={function (post_id: number): void {
              throw new Error("Function not implemented.");
            }}
            onUnLike={function (post_id: number): void {
              throw new Error("Function not implemented.");
            }}
            likeLoading={false}
          />
        </Box>
      ))}
      <Stack
        sx={{
          width: "100%",
          color: "grey.500",
          justifyContent: "center",
        }}
        spacing={2}
      >
        <Button onClick={handleMore} size="small">
          {T("Load_More")}
        </Button>
      </Stack>
    </div>
  );
}

const styles = {
  container: {
    paddingTop: 90,
  },
  addIcon: {
    border: "2px solid",
    borderRadius: 5,
    borderColor: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: "0 10px",
  },

  everyonePostStyle: {
    fontSize: 16,
  },
};

export default GroupEventSettingPage;
