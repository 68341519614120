import { useEffect, useState } from "react";
import "moment/locale/ja"; // Import Japanese locale
// import { Stack, Button } from "@mui/material";
import { colors } from "../common/colors";
// import { T } from "react-translator-component";
import Chart from "../components/Chart";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { getDashboard } from "../redux/Reducers/dashboardReducer";
// import LoadingModal from "../components/LoadingModal";
import UserActionTop from "../components/Phase2/UserActionTop";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import { Stack, Typography } from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import IconButton from "@mui/material/IconButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import moment from "moment";
// import {
//   Bar,
//   ComposedChart,
//   ResponsiveContainer,
//   Tooltip,
//   XAxis,
//   YAxis,
// } from "recharts";

export default function DashboardPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { postTypeOne } = useSelector((state: any) => state.dashboard);
  // const [loadingOpinion, setLoadingOpinion] = useState(false);
  const [topOpinion, setTopOpinion] = useState<any>();
  // const [loadingEVP, setLoadingEVP] = useState(false);
  const [topEVP, setTopEVP] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  // const [selectBar, setSelectedBar] = useState("community");
  // const [selectOpinionBar, setSelectedOpinionBar] = useState("community");
  const [postsInterval, setPostsInterval] = useState<any>();
  const [postsCount, setPostsCount] = useState<any>([]);
  const [suggestions, setSuggestionsCount] = useState<any>({});

  // const handleSelected = (bar: string) => {
  //   setSelectedBar(bar);
  // };
  // const handleSelectedOpinion = (bar: string) => {
  //   setSelectedOpinionBar(bar);
  // };

  useEffect(() => {
    // console.log(postsInterval);
    // console.log("suggestions", suggestions);
  }, [postsInterval, postTypeOne, suggestions]);

  useEffect(() => {
    dispatch(getDashboard());
    apiCall();
    // eslint-disable-next-line
  }, []);

  const apiCall = async () => {
    try {
      setLoading(true);
      const suggestionsCount = await appServices.getSuggestionsInterval();
      // console.log("suggestionsCount", suggestionsCount);
      setSuggestionsCount(suggestionsCount);
      const topOp = await appServices.getTopOpinionPosts(1);
      // setLoadingOpinion(true);
      setTopOpinion(topOp.posts);

      const topEV = await appServices.getTopEveryOnesPosts(1);
      // setLoadingEVP(false);
      setTopEVP(topEV.posts);
      const pp = await appServices.getDashboard2();
      // console.log(pp);
      setPostsInterval(pp);
      const pc = await appServices.getDashboard3();
      // console.log(pc);
      setPostsCount(pc);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setLoadingEVP(false);
      // setLoadingOpinion(false);
    }
  };

  return (
    <div style={{ padding: 10, marginTop: 80 }}>
      <div style={{ marginLeft: 8 }} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"center"}
        spacing={2}
      >
        <LeaderboardIcon />
        <Typography style={{}}>ダッシュボード</Typography>
      </Stack>
      <div
        style={{
          width: "95%",
          height: 3,
          backgroundColor: "black",
          margin: 4,
        }}
      ></div>
      <p>
        ここはユーザーの投稿などをグラフ化し、視覚的にどのくらい使われているのかが見えるページです。
      </p>
      <LoadingModal visible={loading} handleClose={() => {}} />

      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p>
          <IconButton
            aria-label="fingerprint"
            style={{ backgroundColor: "#FFD8EE" }}
          >
            <PanToolOutlinedIcon style={{ color: "#000000" }} />
          </IconButton>
          「意見ひろば」のダッシュボード
        </p>

        <div style={{ width: "100%", backgroundColor: "#FFD8EE" }}>
          {suggestions?.monthly?.length > 0 && (
            <Chart
              data={suggestions.monthly
                .slice(0, 6)
                .map((item: any) => ({
                  jp_name: moment(item.interval_start)
                    .locale("ja")
                    .format("MMMM"),
                  count: item.suggestion_count || 0,
                }))
                .reverse()} // Reverse the order of the data
              color={"#FB9ACD"}
            />
          )}
        </div>
        <br />
        <br />
        <div style={{ width: "100%", backgroundColor: "#FFD8EE" }}>
          <Chart
            data={[
              {
                jp_name: "投稿数",
                count:
                  postsCount[0]?.post_types["1"]?.posts +
                    postsCount[1]?.post_types["1"]?.posts +
                    postsCount[2]?.post_types["1"]?.posts || 0,
              },
              {
                jp_name: "いいね数",
                count:
                  postsCount[0]?.post_types["1"]?.post_likes +
                    postsCount[1]?.post_types["1"]?.post_likes +
                    postsCount[2]?.post_types["1"]?.post_likes || 0,
              },
              {
                jp_name: "コメント数",
                count:
                  postsCount[0]?.post_types["1"]?.comments +
                    postsCount[1]?.post_types["1"]?.comments +
                    postsCount[2]?.post_types["1"]?.comments || 0,
              },
              {
                jp_name: "投票数",
                count:
                  postsCount[0]?.post_types["1"]?.votes +
                    postsCount[1]?.post_types["1"]?.votes +
                    postsCount[2]?.post_types["1"]?.votes || 0,
              },
            ]}
            color={colors.farm_yellow800}
            colors={["#B7E68E", "#FC98BE", "#87BBF2", "#FFD483"]}
          />
          {/* ) : (
         
        )} */}
          <p>＊投稿は寄せられた意見を元にめぶくナビゲーターが行っています。</p>
        </div>
        <UserActionTop topPosts={topOpinion} bgColor={"#FFD8EE"} />
      </div>
      <br />
      <br />
      <br />
      <p>
        <IconButton
          aria-label="fingerprint"
          style={{ backgroundColor: "#B7E68E" }}
        >
          <DriveFileRenameOutlineOutlinedIcon style={{ color: "#000000" }} />
        </IconButton>
        「みんなの投稿」のダッシュボード
      </p>
      <div style={{ backgroundColor: "#E8DF99" }}>
        <Chart
          data={[
            {
              jp_name: postsCount[0]?.name,
              count: postsCount[0]?.post_types["0"]?.posts || 0,
            },
            {
              jp_name: postsCount[1]?.name,
              count: postsCount[1]?.post_types["0"]?.posts || 0,
            },
            {
              jp_name: postsCount[2]?.name,
              count: postsCount[2]?.post_types["0"]?.posts || 0,
            },
          ]}
          color={"#B7E68E"}
        />
      </div>
      <br />
      <br />
      <div style={{ backgroundColor: "#E8DF99" }}>
        <Chart
          data={[
            {
              jp_name: "投稿数",
              count:
                postsCount[0]?.post_types["0"]?.posts +
                  postsCount[1]?.post_types["0"]?.posts +
                  postsCount[2]?.post_types["0"]?.posts || 0,
            },
            {
              jp_name: "いいね数",
              count: postsInterval?.monthly?.post_likes_count,
            },
            {
              jp_name: "コメント数",
              count: postsInterval?.monthly?.comments_count,
            },
            {
              jp_name: "投票数",
              count: postsInterval?.monthly?.votes_count,
            },
          ]}
          color={colors.farm_yellow800}
          colors={["#B7E68E", "#FC98BE", "#87BBF2", "#FFD483"]}
        />
      </div>
      <br />
      <br />

      <UserActionTop topPosts={topEVP} bgColor={"#E8DF99"} />

      <br />
      <br />
    </div>
  );
}
