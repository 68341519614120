import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, InputAdornment, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import appServices from "../../services/app-services/appServices";
import { colors } from "../../common/colors";
import { getRole } from "../../util/utils";

interface AddChatUserListProps {
  open: boolean;
  onClose: () => void;
  options: any[];
  onSelect: (options: string) => void;
  chatId: any;
  chatType: string;
  authToken: any;
  handleDeleteUser: (userId: number) => void;
  isCreator: boolean;
  groupInfo: any;
  currentUserId?: string;
  user?: any;
  currentChatUsers?: any;
}

const AddChatUserList: React.FC<AddChatUserListProps> = ({
  open,
  onClose,
  options,
  onSelect,
  chatId,
  authToken,
  handleDeleteUser,
  isCreator,
  groupInfo,
  currentUserId,
  currentChatUsers,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [addLoading, setAddLoading] = useState(false);
  useEffect(() => {
    // console.log("options: ", options);
    const filtered =
      options?.filter(
        (option) =>
          option?.user?.nickname?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || option?.user?.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) // Fallback to name if nickname is not present
      ) || [];
    setFilteredOptions(filtered);
  }, [searchTerm, options, currentChatUsers]);

  const handleOptionSelect = async (user: any) => {
    if (addLoading) return;
    setAddLoading(true);
    try {
      await appServices.addUserToChat(chatId, user.id, authToken);
      setAddLoading(false);
      onSelect(user);
    } catch (error) {
      console.error("Failed to add user to chat:", error);
    }
  };

  const showOptions = (option: any) => {
    if (currentUserId === option?.user?.id && option?.isInChat) {
      return (
        <RemoveCircleOutlineIcon
          sx={iconStyle(colors.farm_red600)}
          onClick={() => {
            handleDeleteUser(option);
            setSearchTerm("");
            onClose();
          }}
        />
      );
    } else if (isCreator) {
      if (option?.isInChat) {
        return (
          <RemoveCircleOutlineIcon
            sx={iconStyle(colors.farm_red600)}
            onClick={() => {
              handleDeleteUser(option);
              setSearchTerm("");
              onClose();
            }}
          />
        );
      } else {
        return <AddCircleOutlineIcon sx={iconStyle(colors.farm_blue200)} onClick={() => handleOptionSelect(option?.user)} />;
      }
    }
  };

  return (
    <Modal
      sx={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "auto", position: "fixed", top: 0, left: 0 }}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{ backdrop: { style: modalBackdropStyle } }}
    >
      <Box sx={containerStyle}>
        <Box sx={headerStyle}>
          <Box
            onClick={() => {
              onClose();
              setSearchTerm("");
            }}
            sx={backButtonStyle}
          >
            <Typography>戻る</Typography>
            <KeyboardArrowUpIcon />
          </Box>
          <Typography>ユーザー検索</Typography>
        </Box>
        <Box sx={searchBoxStyle}>
          <Typography sx={instructionStyle}>記号を入力してユーザーを検索できます</Typography>
          <TextField
            sx={textFieldStyle}
            id="outlined-basic"
            label=" "
            variant="outlined"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{<CircularProgress size={20} />}</InputAdornment>,
            }}
          />
        </Box>
        {/* chatType != "group" && */}
        {groupInfo && groupInfo.owner && (
          <Box>
            <Typography sx={sectionTitleStyle}>役割を持っているメンバー</Typography>
            <Box sx={optionStyle} key={groupInfo.owner.id}>
              <Box sx={optionContentStyle}>
                <Typography>{groupInfo.owner.nickname || groupInfo.owner.name}</Typography>
              </Box>
              <Typography style={{ color: "GrayText", padding: "0 10px", marginTop: "-5px", marginBottom: "10px" }}>{"リーダー"}</Typography>
            </Box>
          </Box>
        )}

        {
          // eslint-disable-next-line
          filteredOptions.map((option) => {
            if (option?.role?.toLocaleLowerCase() !== "member") {
              return (
                <Box sx={optionStyle} key={option.id}>
                  <Box sx={optionContentStyle}>
                    <Typography style={{ flex: 1 }}>{option?.user?.nickname || option?.user?.name}</Typography>
                    {showOptions(option)}
                  </Box>
                  <Typography style={{ color: "GrayText", padding: "0 10px", marginTop: "-10px", marginBottom: "10px" }}>{getRole(option?.role)}</Typography>
                </Box>
              );
            }
          })
        }
        <Typography sx={sectionTitleStyle}>グループメンバー</Typography>
        <Box sx={listContainerStyle}>
          {filteredOptions.length > 0 ? (
            // eslint-disable-next-line
            filteredOptions.map((option) => {
              if (option?.role?.toLocaleLowerCase() === "member") {
                return (
                  <Box sx={optionStyle} key={option.id}>
                    <Box sx={optionContentStyle}>
                      <Typography style={{ flex: 1 }}>{option?.user?.nickname || option?.user?.name}</Typography>
                      {showOptions(option)}
                    </Box>
                    <Typography style={{ color: "GrayText", padding: "0 10px", marginTop: "-10px", marginBottom: "10px" }}>{getRole(option?.role)}</Typography>
                  </Box>
                );
              }
            })
          ) : (
            <Typography sx={notFoundStyle}>ユーザーが見つかりませんでした</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const modalBackdropStyle = { backgroundColor: "white" };
const containerStyle = {
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px 0px",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  maxHeight: "100vh",
  overflowY: "auto",
};
const headerStyle = { textAlign: "center", marginBottom: "10px" };
const backButtonStyle = { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" };
const searchBoxStyle = { padding: "10px", width: "100%", marginTop: "10px" };
const instructionStyle = { mb: 1 };
const textFieldStyle = { width: "100%" };
const sectionTitleStyle = { textAlign: "left", marginLeft: "15px", marginTop: "20px" };
const listContainerStyle = { marginBottom: "20px" };
const optionStyle = { width: "100%", borderBottom: "1px solid", borderColor: colors.farm_gray100 };
const optionContentStyle = { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 10px 0px 10px" };
const iconStyle = (color: string) => ({ color, fontSize: "34px", cursor: "pointer" });
const notFoundStyle = { paddingTop: "20px" };

export default AddChatUserList;
