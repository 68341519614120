import { Avatar, Box, Button, Typography } from "@mui/material";
import { colors } from "../../../common/colors";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import LoadingModal from "../../../components/LoadingModal";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";

const PaymentReview = () => {
  const navigate = useNavigate();
  const groupInfo = useLocation().state.groupInfo;
  const payment = useLocation().state.payment;
  const shopInfo = useLocation().state.shopInfo;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    gcServices
      .sendPayment(groupInfo.id, shopInfo?.external_id, payment)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        console.error("error", error);
        setMessage(error.response.data.message.join(", "));
      });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={styles.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={styles.rowMargin}>
          <Avatar src={groupInfo.groupImage} sx={{ ...styles.avatar, width: "30px" }} />
          <Typography>{groupInfo.groupName}</Typography>
        </Box>
      </Box>
      <Box sx={styles.container}>
        <Box sx={{ transform: success ? "rotate(0deg)" : "rotate(180deg)", marginTop: "20px" }}>
          <Typography sx={styles.title}>{shopInfo.name}</Typography>
          <Typography sx={{ ...styles.paymentInput, borderBottom: "2px solid " + colors.farm_black }}>¥{payment}</Typography>
        </Box>
        <Typography sx={{ ...styles.text, whiteSpace: "pre-line" }}>
          {success ? "お支払いが完了しました" : "お店の方に画面を見せてください。\n了承が取れましたらお支払いください。"}
        </Typography>
        {success ? (
          <Box sx={styles.center}>
            <img alt="success" src={require("../../../assets/images/success-payment.png")} style={{ width: "160px", height: "113px", margin: "20px" }} />
            <Button
              color="secondary"
              sx={{ ...styles.button, border: "2px solid " + colors.farm_blue200, background: "white" }}
              size={"large"}
              variant={"outlined"}
              onClick={() => navigate("/grouppaymenthistory", { replace: true })}
              endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
            >
              完了する
            </Button>
          </Box>
        ) : (
          <Box sx={styles.center}>
            <Button
              color="secondary"
              sx={styles.button}
              disabled={!payment}
              size={"large"}
              variant={!payment ? "outlined" : "contained"}
              onClick={onSubmit}
              endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, right: 0, margin: "10px" }} icon={faChevronRight} />}
            >
              支払う
            </Button>
            <Button
              color="secondary"
              sx={{ ...styles.button, border: "2px solid " + colors.farm_blue200, background: "white" }}
              disabled={!payment}
              size={"large"}
              variant={"outlined"}
              onClick={() => navigate(-1)}
              endIcon={<FontAwesomeIcon style={{ position: "absolute", top: 0, left: 0, margin: "10px" }} icon={faChevronLeft} />}
            >
              金額を変更する
            </Button>
          </Box>
        )}
      </Box>
      <LoadingModal visible={loading} />
      <MessageModal open={!!message} onClose={() => setMessage("")} message={message} />
    </Box>
  );
};

const styles = {
  headerBox: {
    padding: "1px 16px",
    paddingTop: "90px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  avatar: {
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
    objectFit: "cover",
    overFlow: "hidden",
    backgroundColor: "#E8E8E8",
  },

  container: {
    padding: "0px 20px",
    backgroundColor: colors.farm_gray50,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 20,
    fontFamily: "mplus-1c-regular",
    fontWeight: "600",
    marginTop: "10px",
  },
  text: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "mplus-1c-regular",
    marginTop: "30px",
  },
  paymentInput: {
    color: colors.farm_blue200, // Change text color
    fontSize: "26px", // Change font size
    fontWeight: "600",
  },
  rowMargin: {
    display: "flex",
    alignItems: "center",
    margin: "10px",
  },
  button: {
    width: "80%",
    alignSelf: "center",
    display: "flex",
    borderRadius: 20,
    marginTop: "20px",
  },
  textInput: {
    width: "100%",
    backgroundColor: "white",
    marginTop: "10px",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
export default PaymentReview;
