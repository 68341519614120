import { Avatar, Box, CircularProgress, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import { Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chat from "../../../components/GreenCity/Chat";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import appServices from "../../../services/app-services/appServices";
import { useEffect, useRef, useState } from "react";
import { Message } from "../../../util/types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddChatUserList from "../../../components/Models/AddChatUserList";
import UserDeleteModal from "../../../components/Models/GreenCity/UserDeleteModal";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";

const UserChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { chatId, chatUsers, groupMembers, groupInfo, isCreator, chat_type } = location.state || {};
  const { user } = useSelector((state: any) => state.userAuth);
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [Options, setOptions] = useState<any[]>([]);
  const [currentChatUsers, setCurrentChatUsers] = useState(chatUsers || []);
  const [currentMembers, setCurrentMembers] = useState<any[]>(groupMembers || []);
  const [pendingMessage, setPendingMessage] = useState<string | null>(null);
  const [lastMessageId, setLastMessageId] = useState<number | null>(null);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<any>(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [messageRestart, setMessage] = useState("");

  const message = "このユーザーをチャットから削除してもよろしいですか ?";
  const leaveMessage = "このチャットから退出しますか？一度退出するとチャットの履歴は確認できなくなります。";

  // eslint-disable-next-line
  function filterMembers(currentChatUsers: any) {
    // console.log("current chat memebers: ", currentChatUsers);
    const membersWithStatus = [];
    for (let i = 0; i < currentMembers.length; i++) {
      let member = currentMembers[i];
      // eslint-disable-next-line
      membersWithStatus.push({
        ...member,
        isInChat: currentChatUsers?.some((chatUser: any) => chatUser.id === member.user.id),
      });
    }
    return membersWithStatus;
  }

  function handleNewMembers() {
    if (groupInfo && chat_type === "group") {
      setApiLoading(true);
      gcServices
        .getMemberList(groupInfo?.id)
        .then((res) => {
          setCurrentMembers(res?.member_group_users);
        })
        .finally(() => {
          setApiLoading(false);
        });
    } else {
      setCurrentMembers(
        chatUsers?.map((chatUser: any) => ({
          user: chatUser,
        }))
      );
    }
  }
  // eslint-disable-next-line
  const handleOptionsModal = () => {
    if (!openOption) {
      setOptions(filterMembers(currentChatUsers));
    }
    setOpenOption(!openOption);
  };

  const handleSelect = async (selectedUser: any) => {
    const updatedChatUsers = [
      ...currentChatUsers,
      {
        name: selectedUser?.name,
        nickname: selectedUser?.nickname,
        id: selectedUser?.id,
        image: selectedUser?.image,
        can_message: true,
      },
    ];

    setCurrentMembers([...currentMembers, { user: selectedUser }]);
    setCurrentChatUsers(updatedChatUsers);
    setOptions(filterMembers(updatedChatUsers));
  };
  const checkScrollArrows = () => {
    const container = document.getElementById("members-container");
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
    }
  };

  useEffect(() => {
    checkScrollArrows();
    window.addEventListener("resize", checkScrollArrows);
    return () => window.removeEventListener("resize", checkScrollArrows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOptionsModal]);

  const handleScroll = (direction: "left" | "right") => {
    const container = document.getElementById("members-container");
    if (container) {
      const scrollAmount = 100;
      const newPosition =
        direction === "left" ? Math.max(0, scrollPosition - scrollAmount) : Math.min(container.scrollWidth - container.clientWidth, scrollPosition + scrollAmount);

      container.scrollTo({
        left: newPosition,
        behavior: "smooth",
      });
      setScrollPosition(newPosition);

      setTimeout(checkScrollArrows, 300);
    }
  };

  const getChatMessages = () => {
    appServices.getChatMessages(chatId).then((res) => {
      setAllMessages(res.data.chat.messages);
      if (res.data.chat.messages.length > 0) {
        setLastMessageId(res.data.chat.messages[res.data.chat.messages.length - 1].id);
      }
    });
  };

  // const getNewMessages = (checkforLastMessageId: boolean = true) => {
  //   if (checkforLastMessageId && !lastMessageId) return;
  //   console.log("new messages: ", lastMessageId, allMessages);
  //   appServices.getLatestMessages(chatId, lastMessageId || 0, user.auth_token).then((res) => {
  //     if (res.data.messages.length > 0) {
  //       setAllMessages((prev) => [...prev, ...res.data.messages]);
  //       setLastMessageId(Math.max(...res.data.messages.map((m: any) => m.id)));
  //     }
  //     setIsLoading(false);
  //     setPendingMessage(null);
  //   });
  // };
  // eslint-disable-next-line
  const getNewMessages = (checkforLastMessageId: boolean = true) => {
    if (checkforLastMessageId && !lastMessageId && !chatId) return;
    if (!chatId) return;
    appServices
      .getLatestMessages(chatId, lastMessageId || 0, user.auth_token)
      .then((res) => {
        console.log("new messages: ", lastMessageId, res.data);
        if (res.data.success === false) {
          // console.log('here',res.data)
          setMessage("チャット情報が取得できませんでした。");
          // navigate(-1);
          return;
        }

        if (res.data.messages.length > 0) {
          // Update the messages state by filtering out duplicates
          setAllMessages((prevMessages) => {
            // Get the last 50 messages from the current list
            const last50Messages = prevMessages.slice(-50);
            // Filter out any new message that is already present among the last 50
            const uniqueNewMessages = res.data.messages.filter(
              (newMsg: Message) => newMsg.chat_id === chatId && !last50Messages.some((prevMsg: Message) => prevMsg.id === newMsg.id)
            );
            // console.log("new message before set", [...prevMessages, ...uniqueNewMessages]);
            uniqueNewMessages.sort((a: any, b: any) => a.id - b.id);
            return [...prevMessages, ...uniqueNewMessages];
          });
          // Update the last message ID using all received messages
          setLastMessageId(Math.max(...res.data.messages.map((m: any) => m.id)));
        }
        setIsLoading(false);
        setPendingMessage(null);
      })
      .catch((err) => {
        navigate(-1);
      });
  };

  //inital useEffect
  useEffect(() => {
    getChatMessages();
    handleNewMembers();
    // setOptions(filterMembers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Store interval ID in a ref

    // Clear previous interval if it exists
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set new interval
    intervalRef.current = setInterval(() => getNewMessages(true), 10000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line
  }, [lastMessageId]);

  const handleSendMessage = (message: string) => {
    setPendingMessage(message);
    setIsLoading(true);
    appServices
      .sendMessage(message, user.auth_token, chatId)
      .then((res) => {
        getNewMessages(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setPendingMessage(null);
      });
  };

  const handleDeleteUser = () => {
    setShowSuccessPopup(true);
    appServices.deleteUserChat(chatId, confirmDeleteUser?.user.id, user.auth_token).then((res) => {
      if (confirmDeleteUser?.user.id === user.id) {
        navigate(-1);
        return;
      }
      setTimeout(() => {
        setShowSuccessPopup(false);
        setCurrentChatUsers(currentChatUsers.filter((user: any) => user.id !== confirmDeleteUser?.id));
        if (chat_type !== "group") {
          setCurrentMembers(currentMembers.filter((member: any) => member.user.id !== confirmDeleteUser?.id));
        }
        setConfirmDeleteUser(null);
      }, 3000);
    });

    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 3000);
  };
  const appHome = () => {
    try {
      const messageData = {
        url: "mebukug102016://mebukuapp-host/home",
        condition: true,
      };
      window.parent.postMessage({ type: "mypage", data: messageData }, "*");
    } catch (error) {
      console.error(error);
    }
    setMessage("");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100dvh" }}>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <MessageModal
          buttonText="再読込"
          showButton
          onContinue={() => {
            appHome();
          }}
          message={messageRestart}
          open={messageRestart.length > 0}
          onClose={() => {
            appHome();
          }}
        />
        <Box sx={style.groupContainer}>
          <Box sx={style.scrollContainer}>
            {showLeftArrow && <ArrowBackIosIcon sx={style.scrollIcon} onClick={() => handleScroll("left")} />}
            <Box id="members-container" sx={style.membersWrapper} onScroll={checkScrollArrows}>
              <Box sx={style.memberContainer}>
                {currentChatUsers?.map((member: any) => (
                  <Box key={member.id} sx={style.eventPostingBox}>
                    <Avatar src={member.image && "data:image/jpeg;base64," + member.image} sx={{ width: 30, height: 30 }} />
                    <Typography sx={style.memberTitle}>{member?.nickname}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            {showRightArrow && <ArrowForwardIosIcon sx={style.scrollIcon} onClick={() => handleScroll("right")} />}
          </Box>
          {apiLoading ? <CircularProgress size={30} /> : <AddCircleOutlineIcon sx={style.addIcon} onClick={handleOptionsModal} />}
          <AddChatUserList
            isCreator={isCreator}
            open={openOption}
            onClose={handleOptionsModal}
            options={Options}
            onSelect={handleSelect}
            chatId={chatId}
            chatType={chat_type}
            groupInfo={groupInfo}
            authToken={user.auth_token}
            handleDeleteUser={(user) => setConfirmDeleteUser(user)}
            currentUserId={user.id}
            user={currentChatUsers.map((member: any) => member.user)}
            currentChatUsers={currentChatUsers}
          />
        </Box>
      </Box>
      <Chat currentUser={user} messages={allMessages} handleSendMessage={handleSendMessage} pendingMessage={pendingMessage} />
      <UserDeleteModal
        showButton={true}
        open={confirmDeleteUser !== null}
        message={confirmDeleteUser === user.id ? leaveMessage : message}
        onClose={() => setConfirmDeleteUser(null)}
        onContinue={handleDeleteUser}
        yesText={"退出する"}
        noText={"閉じる"}
      />
      <Modal open={showSuccessPopup}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "85%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            padding: "20px",
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          {confirmDeleteUser === user.id ? (
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              退出しました。
            </Typography>
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              削除されました
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default UserChat;

const style = {
  headerBox: {
    padding: "10px 16px",
    paddingTop: "100px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  groupContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
  },
  memberContainer: {
    display: "flex",
    gap: "10px",
    width: "max-content",
    marginTop: "5px",
  },
  eventPostingBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    paddingTop: "3px",
    paddingLeft: "5px",
  },
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px",
    color: "white",
  },
  memberTitle: {
    fontWeight: "700",
    // marginTop: "5px",
  },
  addIcon: {
    color: "#4792E0",
    fontSize: "35px",
    cursor: "pointer",
  },
  scrollContainer: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    // gap: "8px",
  },
  membersWrapper: {
    width: "100%",
    overflow: "hidden",
  },
  scrollIcon: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "16px",
    flexShrink: 0,
    "&:hover": {
      opacity: 0.7,
    },
  },
};
