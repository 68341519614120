import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Slide, InputAdornment, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import { colors } from "../../../common/colors";
import { getRole } from "../../../util/utils";

interface AddChatUserListProps {
  open: boolean;
  onClose: () => void;

  onSelect: (options: any) => void;
}

const FindUserModal: React.FC<AddChatUserListProps> = ({
  open,
  onClose,
  onSelect,
  // groupInfo,
  // currentUserId,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchUsersOnline();
    // eslint-disable-next-line
  }, [searchTerm]);

  function searchUsersOnline() {
    if (searchTerm.trim() === "") {
      setFilteredOptions([]);
      return;
    }
    setLoading(true);
    gcServices.searchUserNickName(searchTerm).then((res) => {
      // setFilteredOptions(res.users);
      const usersArray = res.users.map((item: any) => ({
        user: item,
        // isInChat: currentChatUsers?.some((u: any) => u?.id === item.id),
      }));
      setFilteredOptions(usersArray);
      setLoading(false);
    });
  }

  const handleOptionSelect = async (user: any) => {
    try {
      // console.log(user);
      onSelect(user);
      onClose();
    } catch (error) {
      console.error("Failed to add user to chat:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description" slotProps={{ backdrop: { style: modalBackdropStyle } }}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box sx={containerStyle}>
          <Box sx={headerStyle}>
            <Box onClick={onClose} sx={backButtonStyle}>
              <Typography>戻る</Typography>
              <KeyboardArrowDownIcon />
            </Box>
            <Typography>ユーザー検索</Typography>
          </Box>
          <Box sx={searchBoxStyle}>
            <Typography sx={instructionStyle}>記号を入力してユーザーを検索できます</Typography>
            <TextField
              sx={textFieldStyle}
              id="outlined-basic"
              label=" "
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{loading && <CircularProgress size={20} />}</InputAdornment>,
              }}
            />
          </Box>

          <Box sx={listContainerStyle}>
            {filteredOptions?.map((option, index) => {
              return (
                <Box sx={optionStyle} key={index}>
                  <Box sx={optionContentStyle}>
                    <Typography style={{ flex: 1 }}>{option?.user?.nickname}</Typography>

                    <>
                      <AddCircleOutlineIcon
                        sx={iconStyle(colors.farm_blue200)}
                        onClick={() => {
                          handleOptionSelect(option);
                        }}
                      />
                    </>
                  </Box>
                  <Typography style={{ color: "GrayText", padding: "0 10px", marginTop: "-10px", marginBottom: "10px" }}>{getRole(option?.role)}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const modalBackdropStyle = { backgroundColor: "transparent" };
const containerStyle = {
  position: "absolute" as "absolute",
  top: 0,
  left: 0,
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 0",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: "20px",
};
const headerStyle = { textAlign: "center", marginBottom: "10px" };
const backButtonStyle = { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" };
const searchBoxStyle = { padding: "10px 0", width: "100%", marginTop: "10px" };
const instructionStyle = { mb: 1 };
const textFieldStyle = { width: "100%" };
const listContainerStyle = { marginBottom: "20px" };
const optionStyle = { width: "100%", borderBottom: "1px solid", borderColor: colors.farm_gray100 };
const optionContentStyle = { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 10px 0px 10px" };
const iconStyle = (color: string) => ({ color, fontSize: "34px", cursor: "pointer" });

export default FindUserModal;
