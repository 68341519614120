import { Avatar, Box, MenuItem, Select, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../common/colors";
import ButtonOutlinedCentered from "../../../components/ButtonOutlined";
import GCBackButton from "../../../components/GreenCity/GCBackButton";
import ImageViewer from "../../../components/GreenCity/ImageViewer";
import LoadingModal from "../../../components/LoadingModal";
import MessageModal from "../../../components/Models/GreenCity/MessageModal";
import gcServices from "../../../services/app-services/greenCity/gcServicesR6";
import style from "./styles";
import ConfirmationModalV2 from "../../../components/Models/ConfirmationModalV2";
import dayjs from "dayjs";
dayjs.locale("ja");
const ACCEPT_MESSAGE = "立て替え精算の申請金額を送金します。";
const REJECT_MESSAGE = "この支払いを拒否してもよろしいですか";
function ShowGroupReimbursements(): ReactElement {
  const [reimbursements, setReimbursements] = useState<any[]>([]);
  const groupInfo = useSelector((state: any) => state.gcGroup);
  const [images, setImages] = useState<any>([]);
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.userAuth);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const years = Array.from({ length: 50 }, (_, i) => 2024 + i);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const [showConfirmation, setShowConfirmation] = useState("");
  const [selectedId, setSelectedId] = useState("");
  // Fetch reimbursements
  useEffect(() => {
    if (user?.id) {
      getPaymentReimbursements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaymentReimbursements = async () => {
    try {
      setLoading(true);
      const res = await gcServices.getPaymentGroupReimbursements(groupInfo.id, selectedMonth, selectedYear);
      // console.log(res);
      setLoading(false);
      setReimbursements(res.reimbursements || []); // Ensure fallback to an empty array
    } catch (error) {
      setLoading(false);
      console.error("Error fetching reimbursements:", error);
    }
  };

  const approveReject = async (id: any, status: string) => {
    try {
      setShowConfirmation("");
      setLoading(true);
      let msg = status === "approve" ? "立て替え精算の承認が完了しました" : "払い戻しを拒否";
      const res = await gcServices.approveRejectReimbursements(id, status);
      if (res.success) {
        setLoading(false);
        setMessage(msg);
        // setTimeout(() => {
        //   setMessage("");
        //   navigate(-1);
        // }, 3099);
      } else {
        setLoading(false);

        setMessage(res?.message);
      }
    } catch (error: any) {
      setLoading(false);
      setMessage(error?.response?.data?.message?.[0] || "Error " + error?.message);
    }
  };

  const handleImage = (image: any, index: any) => {
    // console.log(index, image);
    if (index === 4) setImages([{ path: image }]);
  };

  const getImageName=(path:string)=>{
    const parts = path?.split(/_(.+)/);
    const imageName = parts && parts[1] ? decodeURIComponent(parts[1]) : path;
    return imageName;
  }

  // Table component
  const ReimbursementTable = ({ data, id, status }: { data: any[]; id: any; status: string }) => (
    <>
      <div style={{ marginBottom: 10, marginTop: 10, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <>
          <table style={{ width: "98%", tableLayout: "fixed", marginBottom: 4, marginTop: 4 }}>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td
                    style={{
                      backgroundColor: colors.farm_gray50,
                      border: `1px solid ${colors.farm_gray15}`,
                      width: "33%",
                    }}
                  >
                    {row.col1}
                  </td>
                  {/* Apply background color to the first column */}
                  {index === 0 ? (
                    <td
                      style={{
                        border: `1px solid ${colors.farm_gray15}`,
                        width: "67%",
                        overflow: "hidden", // Prevent content overflow
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 12,
                      }}
                    >
                      <Box sx={style.rowMargin}>
                        <Avatar src={"data:image/jpeg;base64," + JSON.parse(row?.col2).image} sx={{ ...style.avatar, width: "30px" }} />
                        <Typography>{JSON.parse(row.col2).nickname}</Typography>
                      </Box>
                    </td>
                  ) : (
                    <td
                      onClick={() => {
                        handleImage(row.col2, index);
                      }}
                      style={{
                        border: `1px solid ${colors.farm_gray15}`,
                        width: "67%",
                        fontSize: 12, // Keep font size
                        whiteSpace: "normal", // Allow text to wrap to the next line
                        wordBreak: "break-word", // Break long words if needed

                        color: index === 4 ? "#3939a6" : "black",
                      }}
                    >
                      <>{index === 4 ? <span>{getImageName(row.col2)}</span> : <span>{row.col2}</span>}</>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>

        {status === "pending" && (
          <>
            <ButtonOutlinedCentered
              onClick={() => {
                setShowConfirmation("approve");
                setSelectedId(id);
              }}
              title="精算する"
            />
            <br />
            <ButtonOutlinedCentered
              onClick={() => {
                // setShowConfirmation("reject");
                // setSelectedId(id);
                navigate("/rejectreimbursement", { state: { item: reimbursements.find((item: any) => item.id === id) } });
              }}
              title="却下する"
            />
          </>
        )}
      </div>
      <hr />
    </>
  );

  return (
    <div style={{ paddingTop: 100, margin: 4 }}>
      <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      <LoadingModal visible={loading} />
      <MessageModal
      showButton={message=== "立て替え精算の承認が完了しました"}
      buttonText='とじる'
        onContinue={() => {navigate(-1)}}
        message={message}
        open={message.length > 0}
        onClose={() => {
          setMessage("");
        }}
      />

      <div style={{ margin: 14 }}>
        <Box sx={style.rowMargin}>
          <Avatar src={groupInfo.groupImage} sx={{ ...style.avatar, width: "30px" }} />
          <Typography>{groupInfo?.groupName}</Typography>
        </Box>
        <Typography sx={style.titleSeprator}>立て替え申請履歴</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} displayEmpty sx={{ width: "33%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedYear} 年
            </MenuItem>
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </Select>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} displayEmpty sx={{ width: "30%", backgroundColor: "white", height: "40px" }}>
            <MenuItem value="" disabled>
              {selectedMonth} 月
            </MenuItem>
            {months.map((mn) => (
              <MenuItem key={mn} value={mn}>
                {mn}
              </MenuItem>
            ))}
          </Select>

          <ButtonOutlinedCentered onClick={getPaymentReimbursements} title="表示する" />
        </Box>
        {reimbursements.map((item, index) => (
          <ReimbursementTable
            key={index} // Added a key prop
            id={item.id}
            status={item.status}
            data={[
              { col1: "請求先", col2: JSON.stringify(item?.user) },
              { col1: "お支払日", col2: dayjs(item?.payment_date).format("YYYY年M月D日(ddd)") },
              { col1: "お支払先", col2: item?.payment_address },
              { col1: "お支払い金額", col2: item?.amount },
              { col1: "お支払い方法", col2: item?.image },
              { col1: "メモ", col2: item?.details },
              { col1: "申請日", col2: dayjs(item.created_at.split("T")[0]).format("YYYY年M月D日(ddd)") },
              { col1: "申請ID", col2: item.id },
              { col1: "立て替え完了日", col2: item.status === "Approved" ? dayjs(item.updated_at.split("T")[0]).format("YYYY年M月D日(ddd)") : "" },
              { col1: "ステータス", col2: item?.status === "Approved" ? "精算済み" : item?.status === "pending" ? "精算待ち" : "却下" },
            ]}
          />
        ))}
      </div>
      <ImageViewer images={images} isModalOpen={images.length > 0} onClose={() => setImages([])} />
      <ConfirmationModalV2
        open={showConfirmation !== ""}
        onClose={() => setShowConfirmation("")}
        message={showConfirmation === "approve" ? ACCEPT_MESSAGE : REJECT_MESSAGE}
        onContinue={() => approveReject(selectedId, showConfirmation)}
      />
    </div>
  );
}

export default ShowGroupReimbursements;
