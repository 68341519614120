// import { Breadcrumbs, ListItemButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// import VoteIcon from "../assets/images/svg/vote_black.svg";
// import { T } from "react-translator-component";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import { getTopPosts, removeTopPost } from "../redux/Reducers/postReducer";

import SearchBar from "../components/Phase2/Searchbar";
import VerticalListP2 from "../components/lists/VerticalListP2";
import appServices from "../services/app-services/appServices";
import RankingHeader from "../components/Phase2/RankingHeader";
import authService from "../services/app-services/auth-service";
import { loginWithToken } from "../redux/Reducers/userAuthReducer";
import PersonalInfoPopup from "../components/Models/PersonalInfoPopup";
import InfoMessage from "../components/InfoMessage";
import MessageModal from "../components/Models/GreenCity/MessageModal";
import { clearGcSearchText } from "../redux/Reducers/gcSearchReducer";

export default function VotingSeeMorePage() {
  const dispatch = useAppDispatch();
  // const posts = useLocation().state;
  const { isLoadingTopPosts, topPosts } = useSelector((state: any) => state.posts);
  const { user } = useSelector((state: any) => state.userAuth);
  const [infoPopUp, setInfoPopUp] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    apiCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(user);

    if ((user?.name === ""||user?.name === null || user?.dob===null) && user?.id_kind === "1") {
      setMessage("めぶくコミュニティはより安全な環境を提供する為、個人情報のご提供が必要となります。「つぎへ」ボタンで画面が切り替わりますので画面にそってお進みください。");
      setInfoPopUp(true);
    } else {
      if (user?.id_kind === "2") {
        // setMessage('この機能は仮IDでは使用できません。めぶくIDにアップグレードしますか？');
        // setInfoPopUp(true);
      }

      // console.log("in it");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // const onNext = () => {
  //   if (!user?.name) {
  //     console.log("in it");
  //     // getPersonalBI();
  //   } else {
  //     console.log("in it");
  //   }
  // };

  const apiCalls = async () => {
    try {
      dispatch(getTopPosts(1));
    } catch (error) {}
  };

  const getPersonalBI = () => {
    if (user?.id_kind === "2") {
    } else {
      setTimeout(() => {
        try {
          window.parent.postMessage("3PI", "*");
        } catch (error) {
          console.error("3PI", error);
        }
      }, 100);
    }

    // setInfoPopUp(false);
  };

  // console.log(topPosts);
  // window.parent.GetPersonalBasicInfo("B0000009-APP001", "めぶくコミュニティ");
  // eslint-disable-next-line

  const updateUser = async (body: any) => {
    try {
      if(user?.id)
      await authService.updateAppUser(user?.id,body);
      dispatch(loginWithToken(user?.auth_token));
    } catch (error) {}
  };

  useEffect(() => {
    function handleMessage(event: any) {
      try {
        setInfoPopUp(false);
      } catch (error) {}

      try {
        // Log and handle the event to check the source
        if (event.source === window.parent) {
          // Set message for debugging/logging purposes

          // Check if the incoming data is already an object or needs parsing
          // try {
          //   alert(event.data)
          // } catch (error) {
            
          // }
         
          let data = typeof event.data === "string" ? JSON.parse(event.data) : event.data;

          // Check if the message is from the correct source and contains valid data
          if (data?.name !== "") {
            // Prepare the body with user data
            const result = data;
            let body = {
              name: result?.name,
              dob: result?.birthday,
              address: result?.address,
            };

            // Update user information
            try {
              if (body?.name) {
                // Check if body contains a valid name

                updateUser(body);
              }
            } catch (error) {
              console.error("Error updating user name:", error);
            }
          } else {
            setInfoPopUp(true);
          }
        } else {
          // setMessage("Not from parent.");
        }
      } catch (error) {
        // Catch and log errors
        // setMessage("Error occurred while processing the response.");
        // setMessage2("Catch block: " + JSON.stringify(error));
      }
    }

    // Add event listener to receive messages from iframe
    window.addEventListener("message", handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    dispatch(clearGcSearchText());
  })


  const [message1,setMessage1]=useState('');
  const handleReport = async (post_id: number, status: any) => {
    try {
      // setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await appServices.acceptRejectPost(post_id, status);
      // console.log(response, "responseeeeeee");
      await dispatch(getTopPosts(1));
      setMessage1('報告しました。')
      // setMessage('報告の問題');
      setTimeout(() => {
        dispatch(removeTopPost(post_id));
      }, 1000);
    } catch (error) {
      setMessage1('報告の問題');
      // setLoading(false);
    }
  };
  const [likeLoading, setLikeLoading] = useState(false);
  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        dispatch(getTopPosts(1));

        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
    }

    // console.log('like')
  };
  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const Posts = useMemo(() => {
    return (
      <VerticalListP2 handleReport={handleReport} page={"1"} order={"DSC"} items={topPosts} showRanking onLike={handleThumbUp} onUnLike={handleThumbUp} likeLoading={likeLoading} />
    );
    // eslint-disable-next-line
  }, [topPosts]);

  return (
    <div style={{ marginTop: 80, overflow: "scroll" }}>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <ListItemButton onClick={() => navigate(-1)} dense>
          <Typography color="text.primary">{T("Home")}</Typography>
        </ListItemButton>

        <Typography color="text.primary">{T("see_more")}</Typography>
      </Breadcrumbs> */}

     { user?.id&&<div onClick={() => navigate("/search")} style={{ margin: "20px" }}>
        <SearchBar searchTextProp="-" reSearch={false} onResults={() => {}} />
      </div>}
      <div
        style={{
          // alignItems: "center",
          // justifyContent: "center",
          paddingTop: 16,
          display: "flex",
        }}
      >
        <LoadingModal visible={isLoadingTopPosts} handleClose={() => {}} />
        {/* <img height={40} src={VoteIcon} alt="" /> */}

        <RankingHeader />
      </div>

      <PersonalInfoPopup
        open={infoPopUp}
        message={message}
        nextText={" つぎへ"}
        onClose={() => {
          setInfoPopUp(false);
        }}
        onNext={() => {
          getPersonalBI();
        }}
      />

      {Posts}
      <InfoMessage show={true} message="some message" />
      <MessageModal open={message1!==""} message={message1} onClose={()=>setMessage1("")}/>
    </div>
  );
}
