import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the filter state structure
interface FilterState {
  selectedCategory?: { value: number; name: string };
  selectedGroup?: { value: string; name: string };
  selectedSort?: { value: string; name: string };
  selectedSession?: { value: string; name: string };
  facilitatorSort?: { value: string; name: string };
}

// Initial state for filters
const initialState: FilterState = {
  selectedCategory: { value: 0, name: "Category" },
  selectedGroup: { value: "", name: "Group" },
  selectedSort: { value: "", name: "Sort" },
  selectedSession: { value: "", name: "Session" },
  facilitatorSort: { value: "", name: "Sort" },
};

// Create Redux slice
const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    // Action to update filters
    setFilters: (state, action: PayloadAction<FilterState>) => {
      state.selectedCategory = action.payload.selectedCategory;
      state.selectedGroup = action.payload.selectedGroup;
      state.selectedSort = action.payload.selectedSort;
    },

    setFacilitatorFilters: (state, action: PayloadAction<FilterState>) => {
      state.selectedSession = action.payload.selectedSession;
      state.facilitatorSort = action.payload.facilitatorSort;
    },

    // Action to reset filters
    resetFacilitatorFilters: (state) => {
      state.selectedSession = { value: "", name: "Session" };
      state.facilitatorSort = { value: "", name: "Sort" };
    },
    resetCategoriesFilters: (state) => {
      state.selectedCategory = { value: 0, name: "Category" };
      state.selectedGroup = { value: "", name: "Group" };
      state.selectedSort = { value: "", name: "Sort" };
    },
  },
});

// Export actions
export const { setFilters, resetCategoriesFilters, resetFacilitatorFilters, setFacilitatorFilters } = filtersSlice.actions;

// Export reducer
export default filtersSlice.reducer;
